import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./Dashboard.css";
import axios from "axios";
import config from "./config";
import {
  AddTeamMemberCircleSvg,
  AddTeamMemberIconSvg,
  InviteButtonIconSvg,
  OuterEllipseSvg,
  InnerEllipseSvg,
  DetailsCloseButtonSvg,
  DetailsIconBackgroundSvg,
  DetailsIconWorkspaceSvg,
  DetailsIconNetworkSvg,
  DetailsIconDeviceSvg,
} from "./images/TeamSVG.js";
import {
  RemoveServiceButtonSvg,
  ServiceDetailsButtonIconSvg,
  ServiceDetailsButtonArrowSvg,
  MonitorButtonArrowSvg,
  NoIssueStatusIconSvg,
  IncidentStatusIconSvg,
  OutageStatusIconSvg,
} from "./images/ServicesSVG";
import {
  Gauge100,
  Gauge095,
  Gauge090,
  Gauge085,
  Gauge080,
  Gauge075,
  Gauge070,
  Gauge065,
  Gauge060,
  Gauge055,
  Gauge050,
  Gauge045,
  Gauge040,
  Gauge035,
  Gauge030,
  Gauge025,
  Gauge020,
  Gauge015,
  Gauge010,
  Gauge005,
} from "./images/Gauges";
import {
  CarouselButtonLeftSVG,
  CarouselButtonRightSVG,
  AddServiceCircleSvg,
  AddServiceIconSvg,
  InviteIconSvg,
  InviteIconAiplaneSvg,
  AddServicesIconSvg,
  AddMembersIconSvg,
  OnboardingModalCloseButtonSvg,
} from "./images/DashboardSVG";

const Dashboard = ({
  loggedInEmail,
  activationCode,
  signal,
  logoutClick,
  team,
  isAdmin,
  userID,
  teamServices,
  userHasSeenOnboarding,
  setUserHasSeenOnboarding,
  userAccountInfo,
}) => {
  const [visibleItemsCount, setVisibleItemsCount] = useState(6);
  const [visibleStartIndexTeam, setVisibleStartIndexTeam] = useState(0);
  const [visibleStartIndexServices, setVisibleStartIndexServices] = useState(0);
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [shareEmail, setShareEmail] = useState("");
  const [isOnboardingModalOpen, setIsOnboardingModalOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const modalContentRef = useRef(null);
  const [displayedText, setDisplayedText] = useState(activationCode);

  const navigate = useNavigate();

  const API_URL = config.API_URL;

  const handleCloseModal = () => {
    setIsOnboardingModalOpen(false);
    // Optionally, update the user's onboarding status in your backend/database here
  };

  useEffect(() => {
    console.log("UserHasSeenOnboarding: ", userHasSeenOnboarding);
    // Automatically open the modal based on the userHasSeenOnboarding prop
    if (!userHasSeenOnboarding) {
      setIsOnboardingModalOpen(true);
      handleDownload();
    }
  }, [userHasSeenOnboarding]);

  const handleShareInputChange = (e) => {
    setShareEmail(e.target.value);
  };

  function handleDownload() {
    // Helper function to detect OS
    const getOS = () => {
      const userAgent = window.navigator.userAgent;
      const platform = window.navigator.platform;
      const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
      const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  
      if (macosPlatforms.indexOf(platform) !== -1) {
        return 'Mac';
      } else if (windowsPlatforms.indexOf(platform) !== -1) {
        return 'Windows';
      } else if (/Android/.test(userAgent)) {
        return 'Android';
      } else if (!os && /Linux/.test(platform)) {
        return 'Linux';
      }
  
      return 'Unknown';
    };
  
    const os = getOS();
  
    // Download URLs
    const windowsDownloadUrl = 'https://signaltuner.com/update/SignalTuner.exe';
    const macDownloadUrl = 'https://signaltuner.com/update/macos/SignalTuner.dmg';
  
    // Redirect to appropriate download based on OS
    if (os === 'Windows') {
      window.location.href = windowsDownloadUrl;
    } else if (os === 'Mac') {
      window.location.href = macDownloadUrl;
    } else {
      // Optionally, redirect to a generic download page or display an error for unsupported OSes
      alert('Your operating system is not supported for direct download. Please visit our website for more information.');
    }
  }

  const handleShareButtonClick = async () => {
    if (!shareEmail || !shareEmail.includes("@")) {
      alert("The email is not valid. Please enter a valid email address.");
      return; // Stop the function if the email is invalid
    }

    try {
      const response = await fetch(`${API_URL}api/User/SendRecommendation`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ Email: shareEmail }), // Match the EmailRequest class on the backend
      });

      if (response.ok) {
        const result = await response.json();
        setShareEmail("");
        alert("Recommendation email sent successfully!");
      } else {
        alert("Failed to send the recommendation email.");
      }
    } catch (error) {
      console.error("Error sending recommendation email:", error);
      alert("An error occurred while sending the recommendation email.");
    }
  };

  const handleTeamMoreDetailsClick = (memberId) => {
    navigate(`/team/${memberId}`);
  };

  const handleMySignalMoreDetailsClick = () => {
    navigate("/signal");
  };

  const handleServiceDetailsClick = (service, serviceName, serviceDisplayName) => {
    // Assuming description needs to be constructed or another field to be used
    const description = `Details for ${serviceDisplayName}`; // Example description
    const url = `/services?serviceName=${encodeURIComponent(serviceName)}&serviceDisplayName=${encodeURIComponent(serviceDisplayName)}&description=${encodeURIComponent(description)}&serviceId=${encodeURIComponent(
      service.ServiceId
    )}`;
    console.log(service);
    navigate(url, { replace: true });
  };

  const handleAddServicesButtonClick = () => {
    navigate("/services");
  };

  const handleAddMembersButtonClick = () => {
    navigate("/team", { state: { openInviteModal: true } });
  };

  const handlePreviousTeam = () => {
    const lastIndex = team.length - 1;

    if (visibleStartIndexTeam === 0) {
      setVisibleStartIndexTeam(lastIndex); // Loop to the last service
    } else {
      setVisibleStartIndexTeam((prevIndex) => prevIndex - 1);
    }
  };

  const handleNextTeam = () => {
    const lastIndex = team.length - 1;

    if (visibleStartIndexTeam === lastIndex) {
      setVisibleStartIndexTeam(0); // Loop to the first service
    } else {
      setVisibleStartIndexTeam((prevIndex) => prevIndex + 1);
    }
  };

  const renderStatusIcon = (status) => {
    switch (status) {
      case "Operational":
        return (
          <div className="status-icon" style={{ background: "#3BB537", width: "18px", height: "18px" }}>
            <NoIssueStatusIconSvg />
          </div>
        );
      case "Active incident":
        return (
          <div className="status-icon" style={{ background: "#F9AE00", width: "18px", height: "18px" }}>
            <IncidentStatusIconSvg />
          </div>
        );
      case "Outage":
        return (
          <div className="status-icon" style={{ background: "#EC2F3E", width: "18px", height: "18px" }}>
            <OutageStatusIconSvg />
          </div>
        );
      default:
        return null;
    }
  };

  const getStatusStyle = (status) => {
    switch (status) {
      case "Operational":
        return { color: "#3BB537" };
      case "Active incident":
        return { color: "#F9AE00" };
      case "Outage":
        return { color: "#EC2F3E" };
      default:
        return {};
    }
  };

  const resizeHandler = () => {
    const viewportWidth = window.innerWidth;
    let visibleItems;

    if (viewportWidth < 600) {
      // Example breakpoint
      visibleItems = 2;
    } else if (viewportWidth < 900) {
      // Another breakpoint
      visibleItems = 4;
    } else {
      visibleItems = 6;
    }

    // Update state to change the number of visible items
    setVisibleItemsCount(visibleItems);
  };

  const gaugeComponents = {
    100: Gauge100,
    95: Gauge095,
    90: Gauge090,
    85: Gauge085,
    80: Gauge080,
    75: Gauge075,
    70: Gauge070,
    65: Gauge065,
    60: Gauge060,
    55: Gauge055,
    50: Gauge050,
    45: Gauge045,
    40: Gauge040,
    35: Gauge035,
    30: Gauge030,
    25: Gauge025,
    20: Gauge020,
    15: Gauge015,
    10: Gauge010,
    5: Gauge005,
  };

  const roundUpToNearestFive = (num) => {
    return Math.ceil(num / 5) * 5;
  };

  const getGaugeComponent = (score) => {
    const roundedScore = roundUpToNearestFive(score);
    const GaugeComponent = gaugeComponents[roundedScore];

    if (!GaugeComponent) {
      return null;
    }

    return <GaugeComponent className="gauge-svg" />;
  };

  useEffect(() => {
    window.addEventListener("resize", resizeHandler);

    // Initial resize call
    resizeHandler();

    return () => window.removeEventListener("resize", resizeHandler);
  }, []);

  useEffect(() => {
    const fetchTeamServiceSelections = async () => {
      try {
        setLoading(true);
        const userID = localStorage.getItem("userID"); // Assuming userID is stored in localStorage
        const token = localStorage.getItem("token"); // Assuming token is stored in localStorage

        const response = await axios.get(`${API_URL}api/User/GetServicesAndSelections/${userID}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setServices(teamServices);
        setError("");
      } catch (err) {
        setError("Failed to fetch service selections");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchTeamServiceSelections();
  }, [teamServices]);

  // Return scroll bar while progreesing through onboarding modal
  useEffect(() => {
    if (modalContentRef.current) {
      modalContentRef.current.scrollTop = 0;
    }
  }, [currentStep]);

  const selectedServices = services.filter((service) => service.IsSelected);

  const getServiceAtIndex = (index) => {
    // Safeguard in case selectedServices is empty or shorter than expected
    if (selectedServices.length === 0) return null;
    return selectedServices[index % selectedServices.length];
  };

  const handlePreviousService = () => {
    const lastIndex = selectedServices.length - 1;

    if (visibleStartIndexServices === 0) {
      setVisibleStartIndexServices(lastIndex); // Loop to the last service
    } else {
      setVisibleStartIndexServices((prevIndex) => prevIndex - 1);
    }
  };

  const handleNextService = () => {
    const lastIndex = selectedServices.length - 1;

    if (visibleStartIndexServices === lastIndex) {
      setVisibleStartIndexServices(0); // Loop to the first service
    } else {
      setVisibleStartIndexServices((prevIndex) => prevIndex + 1);
    }
  };

  if (!signal) {
    console.log("Could not retrieve signal data.");
    return <div className="dashboard-loading-text">Loading signal data...</div>;
  }

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(activationCode)
      .then(() => {
        // Update the displayed text to "Copied!"
        setDisplayedText("Copied!");

        // Set a timeout to revert the displayed text back to the activation code after 3 seconds
        setTimeout(() => {
          setDisplayedText(activationCode);
        }, 3000);

        console.log("Activation code copied to clipboard!");
      })
      .catch((err) => {
        // Handle any errors
        console.error("Failed to copy the text: ", err);
      });
  };

  const handleOnboardingComplete = () => {
    setUserHasSeenOnboarding();
    handleCloseModal();
  };

  const handleNavigate = () => {
    // Navigate to the Settings page and indicate the tab to open
    navigate("/settings?tab=Account");
  };

  function getStatusColor(status) {
    switch (status) {
      case "Excellent":
        return "#3BB537"; // Green
      case "Fair":
        return "#F9AE00"; // Yellow
      case "Poor":
        return "#FC6F20"; // Orange
      case "Critical":
        return "#EC2F3E"; // Red
      default:
        return "#6b96c1"; // Default color (Light blue)
    }
  }

  return (
    <div>
      {/* Onboarding Modal */}
      {isOnboardingModalOpen && (
        <div className="dashboard-onboarding-modal-overlay">
          <div className="dashboard-onboarding-modal-content" ref={modalContentRef}>
            <div onClick={handleCloseModal} style={{ cursor: "pointer" }}>
              <OnboardingModalCloseButtonSvg className="service-details-close-button-svg" />
            </div>
            <h2 className="dashboard-onboarding-modal-welcome-headline">Welcome to SignalTuner!</h2>
            <p className="dashboard-onboarding-modal-subhead">Let's get started...</p>
            {/* Onboarding modal conent start */}
            {currentStep === 1 && (
              <div className="dashboard-onboarding-modal-columns">
                <div className="dashboard-onboarding-modal-left-column">
                  <p className="dashboard-onboarding-modal-step-one">Step 1: Measure your signal</p>
                  <p className="dashboard-onboarding-instructions">
                    The SignalTuner desktop installer was downloaded by your browser when you created your account.
                  </p>
                  <p className="dashboard-onboarding-instructions">
                    Run the installer and enter your activation code to sync your device's signal data with signaltuner.com
                  </p>
                  <p className="dashboard-onboarding-instructions">
                    Activation code:{" "}
                    <span className="dashboard-onboarding-instructions-activation-code" onClick={copyToClipboard}>
                      {displayedText}{" "}
                    </span>
                  </p>
                  <p className="dashboard-onboarding-instructions">Your Signal Score information is displayed on the 'My Signal' page.</p>
                </div>
                <div className="dashboard-onboarding-modal-right-column">
                  <img
                    className="dashboard-onboarding-desktop-client-image"
                    src="https://www.signaltuner.com/images/onboarding-dashboard-desktop-client-image.png"
                    alt="Desktop Client Setup"
                  />
                </div>
              </div>
            )}

            {currentStep === 2 && (
              <>
                <div className="dashboard-onboarding-modal-columns">
                  <div className="dashboard-onboarding-modal-left-column">
                    <p className="dashboard-onboarding-modal-step-two">Step 2: Build your team</p>

                    <p className="dashboard-onboarding-instructions">On the 'Team' page, team members can see each other Signal Scores. </p>
                    <p className="dashboard-onboarding-instructions">Connection issues are detected and diagnosed. </p>
                    <p className="dashboard-onboarding-instructions">Team administratiors are notified of detected issues within critical timeframes. </p>
                  </div>
                  <div className="dashboard-onboarding-modal-right-column">
                    <img className="dashboard-onboarding-team-image" src="https://www.signaltuner.com/images/onboarding_team.png" />
                  </div>
                </div>
              </>
            )}

            {currentStep === 3 && (
              <>
                <div className="dashboard-onboarding-modal-columns">
                  <div className="dashboard-onboarding-modal-left-column">
                    <p className="dashboard-onboarding-modal-step-three">Step 3: Monitor your services</p>
                    <p className="dashboard-onboarding-instructions">On the 'Services' page, you can select the tools your team relies on everyday. </p>
                    <p className="dashboard-onboarding-instructions">
                      All team members are alerted when incidents or outages are detected, and notified when they are resolved.{" "}
                    </p>
                  </div>
                  <div className="dashboard-onboarding-modal-right-column">
                    <img className="dashboard-onboarding-services-image" src="https://www.signaltuner.com/images/onboarding_services.png" />
                  </div>
                </div>
              </>
            )}

            {currentStep === 4 && (
              <>
                <div className="dashboard-onboarding-modal-columns">
                  <div className="dashboard-onboarding-modal-left-column">
                    <p className="dashboard-onboarding-modal-step-three">Step 4: Configure your alerts</p>
                    <p className="dashboard-onboarding-instructions">
                      On the 'Settings' page, you can set up alert thresholds and critical timeframes for your team.{" "}
                    </p>
                  </div>
                  <div className="dashboard-onboarding-modal-right-column">
                    <img
                      className="dashboard-onboarding-team-alert-settings-image"
                      src="https://www.signaltuner.com/images/onboarding_team_alert_settings.png"
                    />
                  </div>
                </div>
              </>
            )}

            {currentStep === 5 && (
              <>
                <div className="dashboard-onboarding-modal-columns">
                  <div className="dashboard-onboarding-modal-left-column">
                    <p className="dashboard-onboarding-modal-step-three">That's all!</p>
                    <p className="dashboard-onboarding-instructions">Your ready to connect with your team better than ever!</p>
                    <p className="dashboard-onboarding-instructions">Click "Finish" to conclude this tutorial.</p>
                  </div>
                  <div className="dashboard-onboarding-modal-right-column">
                    <img
                      className="dashboard-onboarding-team-alert-settings-image"
                      src="https://www.signaltuner.com/images/SignalTUNER%20Logo%20Horizontal-01.png"
                    />
                  </div>
                </div>
              </>
            )}

            <div className="dashboard-onboarding-modal-navigation">
              {currentStep > 1 && (
                <button className="dashboard-onboarding-modal-prev-button" onClick={() => setCurrentStep(currentStep - 1)}>
                  Previous
                </button>
              )}
              {currentStep < 5 ? (
                <button className="dashboard-onboarding-modal-next-button" onClick={() => setCurrentStep(currentStep + 1)}>
                  Next
                </button>
              ) : (
                <button className="dashboard-onboarding-modal-finish-button" onClick={handleOnboardingComplete}>
                  Finish
                </button>
              )}
            </div>
          </div>
        </div>
      )}
      {/* Onboarding modal end */}

      <div className="dashboard-team-label-container">
        <h2 className="dashboard-my-signal-label">My Signal</h2>
        {team && team.length > 0 ? <h2 className="dashboard-team-name">{team[0].TeamName}</h2> : <h2 className="dashboard-team-name">Loading team data...</h2>}
        <button className="dashboard-add-team-members-button" onClick={handleAddMembersButtonClick}>
          <AddMembersIconSvg />
          {isAdmin ? "Add members" : "View team"}
        </button>
      </div>
      <div className="dashboard-team-carousel-container">
        <button className="dashboard-team-carousel-button left" onClick={handlePreviousTeam}>
          <CarouselButtonLeftSVG className="carousel-arrow" />
        </button>
        <div className="dashboard-team-carousel">
          {team &&
            (() => {
              const userIdFromStorage = localStorage.getItem("userID");
              const numericUserIdFromStorage = parseInt(userIdFromStorage, 10);
              const filteredTeam = team.filter((member) => member.UserId !== numericUserIdFromStorage);
              const totalCards = Math.max(filteredTeam.length + 1, 5);

              // Create an array of 5 elements, filling with team members first, then 'Add' cards
              return Array.from({ length: totalCards }).map((_, index) => {
                // Special card for the first position
                if (index === 0) {
                  // Assuming you have a 'signal' object and 'userFirstName', 'userLastName' in local storage
                  const signalScore = signal.SignalScore;
                  const userFirstName = localStorage.getItem("userFirstName");
                  const userLastName = localStorage.getItem("userLastName");

                  return (
                    <div key={`special-${index}`} className="dashboard-team-member-card" onClick={() => handleMySignalMoreDetailsClick()}>
                      <div className="dashboard-svg-container">
                        <OuterEllipseSvg />
                        <InnerEllipseSvg />
                        {getGaugeComponent(signalScore)}
                      </div>
                      <div className="dashboard-member-score">
                        <span className="dashboard-score-container">
                          <span className="dashboard-signal-score">{signalScore}</span>
                          <span className="dashboard-percentage-symbol">%</span>
                        </span>
                      </div>

                      <div className="dashboard-overall-status" style={{ color: getStatusColor(signal.SignalOverallStatus) }}>
                        {signal.SignalOverallStatus}
                      </div>

                      <div className="dashboard-member-name">
                        {userFirstName} {userLastName}
                      </div>
                    </div>
                  );
                } else if (index <= filteredTeam.length) {
                  const teamIndex = (index - 1 + visibleStartIndexTeam) % filteredTeam.length;
                  const member = filteredTeam[teamIndex];

                  return (
                    <div key={member.UserId} className="dashboard-team-member-card" onClick={() => handleTeamMoreDetailsClick(member.UserId)}>
                      <div className="dashboard-svg-container">
                        <OuterEllipseSvg />
                        <InnerEllipseSvg />
                        {getGaugeComponent(member.SignalScore)}
                      </div>
                      <div className="dashboard-member-score">
                        <span className="dashboard-score-container">
                          <span className="dashboard-signal-score">{member.SignalScore}</span>
                          <span className="dashboard-percentage-symbol">%</span>
                        </span>
                      </div>

                      <div className="dashboard-overall-status" style={{ color: getStatusColor(member.SignalOverallStatus) }}>
                        {member.SignalOverallStatus}
                      </div>

                      <div className="dashboard-member-name">
                        {member.UserFirstName} {member.UserLastName}
                      </div>


                    </div>
                  );
                } else {
                  // Return "add team member" card for remaining slots
                  return (
                    <div key={`add-${index}`} className="dashboard-add-team-member-card">
                      <div
                        className="dashboard-add-team-member-svg-container"
                        style={{
                          position: "relative",
                          marginTop: isAdmin ? "20px" : "80px",
                        }}
                      >
                        <AddTeamMemberCircleSvg />
                        <AddTeamMemberIconSvg />
                      </div>

                      {isAdmin && (
                        <div>
                          <div className="dashboard-add-team-member-text">Add a team member</div>
                          <button className="dashboard-invite-button" onClick={handleAddMembersButtonClick}>
                            <InviteButtonIconSvg />
                            Invite
                          </button>
                        </div>
                      )}
                    </div>
                  );
                }
              });
            })()}
        </div>

        <button className="dashboard-team-carousel-button right" onClick={handleNextTeam}>
          <CarouselButtonRightSVG className="carousel-arrow" />
        </button>
      </div>

      <div className="dashboard-services-label-container">
        <h2 className="dashboard-services-label">My Services</h2>
        <button className="dashboard-add-services-button" onClick={handleAddServicesButtonClick}>
          <AddServicesIconSvg />
          {isAdmin ? "Add services" : "View services"}
        </button>
      </div>
      <div className="dashboard-services-carousel-container">
        <button className="dashboard-service-carousel-button left" onClick={handlePreviousService}>
          <CarouselButtonLeftSVG className="carousel-arrow" />
        </button>
        <div className="dashboard-services-carousel">
          {Array.from({ length: 5 }).map((_, index) => {
            const serviceIndex = (visibleStartIndexServices + index) % selectedServices.length;
            const service = getServiceAtIndex(serviceIndex);

            // Check if it's a valid service or if we need to add a placeholder
            if (service && index < selectedServices.length) {
              return (
                <div className="dashboard-service-card" key={index} onClick={() => handleServiceDetailsClick(service, service.ServiceName, service.ServiceDisplayName)}>
                  <div className="dashboard-service-container">
                    <img
                      className="dashboard-service-icon"
                      src={`https://www.signaltuner.com/images/serviceicons/${service.ServiceName}.png`}
                      alt={`${service.ServiceName} Icon`}
                    />
                    <div className="dashboard-service-name">{service.ServiceDisplayName}</div>
                    <div className="dashboard-status-details-container">
                      <div className="dashboard-status-content">
                        {renderStatusIcon(service.ServiceCurrentStatus)}
                        <div className="dashboard-service-status" style={getStatusStyle(service.ServiceCurrentStatus)}>
                          {service.ServiceCurrentStatus}
                        </div>
                        {/* <button
                          className="dashboard-details-button"
                          onClick={() => handleServiceDetailsClick(service, service.ServiceName, service.ServiceDisplayName)}
                        >
                          <ServiceDetailsButtonIconSvg />
                          <ServiceDetailsButtonArrowSvg />
                        </button> */}
                      </div>
                    </div>
                  </div>
                </div>
              );
            } else {
              // Return "add service" card for remaining slots
              return (
                <div key={index} className="dashboard-add-service-card">
                  <div
                    className="dashboard-add-service-svg-container"
                    style={{
                      position: "relative",
                      marginTop: isAdmin ? "20px" : "80px",
                    }}
                  >
                    <AddServiceCircleSvg />
                    <AddServiceIconSvg />
                  </div>
                  {isAdmin && (
                    <div>
                      {" "}
                      <div className="dashboard-add-team-member-text">Add a service</div>
                      <button className="dashboard-invite-button" onClick={handleAddServicesButtonClick}>
                        <InviteButtonIconSvg />
                        Add
                      </button>
                    </div>
                  )}
                </div>
              );
            }
          })}
        </div>
        <button className="dashboard-service-carousel-button right" onClick={handleNextService}>
          <CarouselButtonRightSVG className="carousel-arrow" />
        </button>
      </div>

      {isAdmin && (
        <div>
          {" "}
          <div className="dashboard-monitors-label-container">
            <h2 className="dashboard-monitors-label">Monitors</h2>
          </div>
          <div className="dashboard-account-usage-monitors-container" onClick={handleNavigate}>
            <div className="dashboard-account-usage-monitors-total-container">
              <h3 className="dashboard-account-usage-monitors-used-label">Totals:</h3>
              {userAccountInfo && <h3 className="dashboard-account-usage-monitors-used-value">{userAccountInfo.UserMonitorsUsed}</h3>}
              <h3 className="dashboard-account-usage-monitors-used-label">Used</h3>
              <h3 className="dashboard-account-usage-monitors-vinculum">/</h3>
              {userAccountInfo && (
                <h3 className="dashboard-account-usage-monitors-total-value">{userAccountInfo.UserMonitorsTotal - userAccountInfo.UserMonitorsUsed}</h3>
              )}
              <h3 className="dashboard-account-usage-monitors-total-label">Available</h3>
            </div>

            <div className="dashboard-account-usage-monitors-team-container">
              <AddMembersIconSvg color="#32567a" />
              <h3 className="dashboard-account-usage-team-label">Team members:</h3>
              {userAccountInfo && <h3 className="dashboard-account-usage-team-used-value">{team?.length}</h3>}
            </div>

            <div className="dashboard-account-usage-monitors-services-container">
              <AddServicesIconSvg color="#32567a" />
              <h3 className="dashboard-account-usage-services-label">Services:</h3>
              {userAccountInfo && <h3 className="dashboard-account-usage-services-used-value">{userAccountInfo.TeamServicesCount}</h3>}
            </div>
          </div>
        </div>
      )}

      <div className="dashboard-invite-div">
        <div className="dashboard-invite-content">
          <div className="dashboard-invite-icons-div">
            <InviteIconAiplaneSvg className="dashboard-invite-airplane-icon-svg" />
            <InviteIconSvg className="dashboard-invite-icon-svg" />
          </div>
          <div className="dashboard-invite-text-input-container">
            <p className="dashboard-invite-text">Know someone that needs SignalTuner?</p>
            <div className="input-button-container">
              <input type="text" className="dashboard-share-input" placeholder="Enter an email address" value={shareEmail} onChange={handleShareInputChange} />

              <button className="dashboard-share-button" onClick={handleShareButtonClick}>
                Share
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
