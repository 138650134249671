import React from "react";

export const predefinedMembers = [
  { UserFirstName: "Tony", UserLastName: "Stark", UserEmail: "ironman@example.com" },
  { UserFirstName: "Bruce", UserLastName: "Banner", UserEmail: "hulk@example.com" },
  { UserFirstName: "Natasha", UserLastName: "Romanoff", UserEmail: "blackwidow@example.com" },
  { UserFirstName: "Thor", UserLastName: "Odinson", UserEmail: "thor@example.com" },
  { UserFirstName: "Clint", UserLastName: "Barton", UserEmail: "hawkeye@example.com" },
  { UserFirstName: "Steve", UserLastName: "Rogers", UserEmail: "captainamerica@example.com" },
  { UserFirstName: "Sam", UserLastName: "Wilson", UserEmail: "falcon@example.com" },
  { UserFirstName: "Steven", UserLastName: "Strange", UserEmail: "drstrange@example.com" },
  { UserFirstName: "Peter", UserLastName: "Parker", UserEmail: "spiderman@example.com" },
];

export const mockSignalDataSets = [
  {
    SignalWifiStrength: 5,
    SignalWifiBand: "5 GHz",
    SignalCurrentNetwork: "Example Network",
    SignalWifiStatus: "Excellent",
    SignalDownloadSpeed: 50.5,
    SignalUploadSpeed: 10.6,
    SignalPing: 8,
    SignalPacketLoss: 0.9,
    SignalBandwidthStatus: "Excellent",
    SignalMemory: 30,
    SignalCPU: 20,
    SignalProcessor: "Intel(R) Core(TM) i7-9750H CPU @ 2.60GHz   2.59 GHz",
    SignalCores: 6,
    SignalSystemStatus: "Excellent",
    SignalVpnDetected: "Not Connected",
    SignalAdapter: "Example Adapter",
    SignalNetworkStatus: "Not Connected",
    SignalRecommendation1: `Switch your router from 5 GHz to its 2.4 GHz network for a more consistent Wi-Fi signal.

Follow these steps to make the switch:

1. Locate your router and check the 2.4 GHz network name (SSID) on it.

2. On your device (computer or smartphone), open the Wi-Fi settings.

3. Select the 2.4 GHz network from the available Wi-Fi networks.

4. Enter the Wi-Fi password if prompted (usually found on the router).

5. Wait for the connection to establish, and you're now connected to the 2.4 GHz network for a more consistent Wi-Fi signal.`,
    SignalRecommendation2: `Your download speed is low. Disconnect other devices on your network to free up bandwidth and improve download speeds.

Follow these steps to identify and disconnect devices:

1. Access your router's web interface:
   - Open a web browser (e.g., Chrome, Firefox).
   - Enter your router's IP address in the browser's address bar. Common router IPs include 192.168.1.1 or 192.168.0.1. You can usually find this information on a sticker on your router or in the router's manual. Press Enter.

2. Log in to your router:
   - You'll be prompted to enter a username and password. This information can also be found on the router's sticker or in the manual. If you haven't changed these credentials before, try common defaults like "admin" for both the username and password. If you've changed them and can't remember, contact your router manufacturer for assistance.

3. Access the device list:
   - Once logged in, look for a section called "Device List," "Connected Devices," or something similar in your router's dashboard or settings. The exact wording may vary depending on your router's brand and model.

4. Identify and disconnect devices:
   - In the device list, you'll see a list of all the devices connected to your network. Typically, each device will be identified by its name or MAC address.
   - Identify the devices that you want to disconnect to free up bandwidth. Be careful not to disconnect any essential devices like your computer or smartphone.
   - Select the device you want to disconnect, and there should be an option to "Disconnect" or "Remove" it from the network. Click or tap on that option.

5. Confirm and repeat if necessary:
   - Confirm the disconnection if prompted. The selected device should be disconnected from your network.
   - If you have multiple devices to disconnect, repeat the process for each one.

By following these steps, you can find and disconnect devices from your network, which can help free up bandwidth and improve download speeds for your essential devices.`,
    SignalRecommendation3: `Your system is low on memory. Close programs you aren't using to free up RAM.

Follow these steps to close unused programs:

1. Press "Ctrl + Alt + Delete" on your keyboard simultaneously. This will open the Task Manager.

2. In the Task Manager window, you'll see a list of running applications and processes. Look for the programs that are using a significant amount of memory and are not essential for your current tasks.

3. Click on the program you want to close to select it.

4. After selecting the program, click the "End Task" or "End Process" button at the bottom right corner of the Task Manager window. This will terminate the selected program.

5. Repeat steps 3 and 4 for any other programs you wish to close to free up RAM.

6. Once you've closed the unnecessary programs, monitor your system's performance to see if the available RAM has increased.

By following these steps, you can close programs that are using up valuable system memory and help improve your computer's performance.`,
    SignalScore: 100,
    SignalOverallStatus: "Excellent",
  },
  {
    SignalWifiStrength: 5,
    SignalWifiBand: "5 GHz",
    SignalCurrentNetwork: "Example Network",
    SignalWifiStatus: "Excellent",
    SignalDownloadSpeed: 50.5,
    SignalUploadSpeed: 10.6,
    SignalPing: 10,
    SignalPacketLoss: 0.9,
    SignalBandwidthStatus: "Excellent",
    SignalMemory: 30,
    SignalCPU: 20,
    SignalProcessor: "Intel(R) Core(TM) i7-9750H CPU @ 2.60GHz   2.59 GHz",
    SignalCores: 6,
    SignalSystemStatus: "Excellent",
    SignalVpnDetected: "Not Connected",
    SignalAdapter: "Example Adapter",
    SignalNetworkStatus: "Not Connected",
    SignalRecommendation1: `Switch your router from 5 GHz to its 2.4 GHz network for a more consistent Wi-Fi signal.

Follow these steps to make the switch:

1. Locate your router and check the 2.4 GHz network name (SSID) on it.

2. On your device (computer or smartphone), open the Wi-Fi settings.

3. Select the 2.4 GHz network from the available Wi-Fi networks.

4. Enter the Wi-Fi password if prompted (usually found on the router).

5. Wait for the connection to establish, and you're now connected to the 2.4 GHz network for a more consistent Wi-Fi signal.`,
    SignalRecommendation2: `Your download speed is low. Disconnect other devices on your network to free up bandwidth and improve download speeds.

Follow these steps to identify and disconnect devices:

1. Access your router's web interface:
   - Open a web browser (e.g., Chrome, Firefox).
   - Enter your router's IP address in the browser's address bar. Common router IPs include 192.168.1.1 or 192.168.0.1. You can usually find this information on a sticker on your router or in the router's manual. Press Enter.

2. Log in to your router:
   - You'll be prompted to enter a username and password. This information can also be found on the router's sticker or in the manual. If you haven't changed these credentials before, try common defaults like "admin" for both the username and password. If you've changed them and can't remember, contact your router manufacturer for assistance.

3. Access the device list:
   - Once logged in, look for a section called "Device List," "Connected Devices," or something similar in your router's dashboard or settings. The exact wording may vary depending on your router's brand and model.

4. Identify and disconnect devices:
   - In the device list, you'll see a list of all the devices connected to your network. Typically, each device will be identified by its name or MAC address.
   - Identify the devices that you want to disconnect to free up bandwidth. Be careful not to disconnect any essential devices like your computer or smartphone.
   - Select the device you want to disconnect, and there should be an option to "Disconnect" or "Remove" it from the network. Click or tap on that option.

5. Confirm and repeat if necessary:
   - Confirm the disconnection if prompted. The selected device should be disconnected from your network.
   - If you have multiple devices to disconnect, repeat the process for each one.

By following these steps, you can find and disconnect devices from your network, which can help free up bandwidth and improve download speeds for your essential devices.`,
    SignalRecommendation3: `Your system is low on memory. Close programs you aren't using to free up RAM.

Follow these steps to close unused programs:

1. Press "Ctrl + Alt + Delete" on your keyboard simultaneously. This will open the Task Manager.

2. In the Task Manager window, you'll see a list of running applications and processes. Look for the programs that are using a significant amount of memory and are not essential for your current tasks.

3. Click on the program you want to close to select it.

4. After selecting the program, click the "End Task" or "End Process" button at the bottom right corner of the Task Manager window. This will terminate the selected program.

5. Repeat steps 3 and 4 for any other programs you wish to close to free up RAM.

6. Once you've closed the unnecessary programs, monitor your system's performance to see if the available RAM has increased.

By following these steps, you can close programs that are using up valuable system memory and help improve your computer's performance.`,
    SignalScore: 95,
    SignalOverallStatus: "Excellent",
  },
  {
    SignalWifiStrength: 5,
    SignalWifiBand: "5 GHz",
    SignalCurrentNetwork: "Example Network",
    SignalWifiStatus: "Excellent",
    SignalDownloadSpeed: 50.5,
    SignalUploadSpeed: 10.6,
    SignalPing: 10,
    SignalPacketLoss: 0.9,
    SignalBandwidthStatus: "Excellent",
    SignalMemory: 30,
    SignalCPU: 20,
    SignalProcessor: "Intel(R) Core(TM) i7-9750H CPU @ 2.60GHz   2.59 GHz",
    SignalCores: 6,
    SignalSystemStatus: "Excellent",
    SignalVpnDetected: "Not Connected",
    SignalAdapter: "Example Adapter",
    SignalNetworkStatus: "Not Connected",
    SignalRecommendation1: `Switch your router from 5 GHz to its 2.4 GHz network for a more consistent Wi-Fi signal.

Follow these steps to make the switch:

1. Locate your router and check the 2.4 GHz network name (SSID) on it.

2. On your device (computer or smartphone), open the Wi-Fi settings.

3. Select the 2.4 GHz network from the available Wi-Fi networks.

4. Enter the Wi-Fi password if prompted (usually found on the router).

5. Wait for the connection to establish, and you're now connected to the 2.4 GHz network for a more consistent Wi-Fi signal.`,
    SignalRecommendation2: `Your download speed is low. Disconnect other devices on your network to free up bandwidth and improve download speeds.

Follow these steps to identify and disconnect devices:

1. Access your router's web interface:
   - Open a web browser (e.g., Chrome, Firefox).
   - Enter your router's IP address in the browser's address bar. Common router IPs include 192.168.1.1 or 192.168.0.1. You can usually find this information on a sticker on your router or in the router's manual. Press Enter.

2. Log in to your router:
   - You'll be prompted to enter a username and password. This information can also be found on the router's sticker or in the manual. If you haven't changed these credentials before, try common defaults like "admin" for both the username and password. If you've changed them and can't remember, contact your router manufacturer for assistance.

3. Access the device list:
   - Once logged in, look for a section called "Device List," "Connected Devices," or something similar in your router's dashboard or settings. The exact wording may vary depending on your router's brand and model.

4. Identify and disconnect devices:
   - In the device list, you'll see a list of all the devices connected to your network. Typically, each device will be identified by its name or MAC address.
   - Identify the devices that you want to disconnect to free up bandwidth. Be careful not to disconnect any essential devices like your computer or smartphone.
   - Select the device you want to disconnect, and there should be an option to "Disconnect" or "Remove" it from the network. Click or tap on that option.

5. Confirm and repeat if necessary:
   - Confirm the disconnection if prompted. The selected device should be disconnected from your network.
   - If you have multiple devices to disconnect, repeat the process for each one.

By following these steps, you can find and disconnect devices from your network, which can help free up bandwidth and improve download speeds for your essential devices.`,
    SignalRecommendation3: `Your system is low on memory. Close programs you aren't using to free up RAM.

Follow these steps to close unused programs:

1. Press "Ctrl + Alt + Delete" on your keyboard simultaneously. This will open the Task Manager.

2. In the Task Manager window, you'll see a list of running applications and processes. Look for the programs that are using a significant amount of memory and are not essential for your current tasks.

3. Click on the program you want to close to select it.

4. After selecting the program, click the "End Task" or "End Process" button at the bottom right corner of the Task Manager window. This will terminate the selected program.

5. Repeat steps 3 and 4 for any other programs you wish to close to free up RAM.

6. Once you've closed the unnecessary programs, monitor your system's performance to see if the available RAM has increased.

By following these steps, you can close programs that are using up valuable system memory and help improve your computer's performance.`,
    SignalScore: 90,
    SignalOverallStatus: "Excellent",
  },
  {
    SignalWifiStrength: 5,
    SignalWifiBand: "5 GHz",
    SignalCurrentNetwork: "Example Network",
    SignalWifiStatus: "Fair",
    SignalDownloadSpeed: 50.5,
    SignalUploadSpeed: 10.6,
    SignalPing: 10,
    SignalPacketLoss: 0.9,
    SignalBandwidthStatus: "Fair",
    SignalMemory: 30,
    SignalCPU: 20,
    SignalProcessor: "Intel(R) Core(TM) i7-9750H CPU @ 2.60GHz   2.59 GHz",
    SignalCores: 6,
    SignalSystemStatus: "Fair",
    SignalVpnDetected: "Not Connected",
    SignalAdapter: "Example Adapter",
    SignalNetworkStatus: "Not Connected",
    SignalRecommendation1: `Switch your router from 5 GHz to its 2.4 GHz network for a more consistent Wi-Fi signal.

Follow these steps to make the switch:

1. Locate your router and check the 2.4 GHz network name (SSID) on it.

2. On your device (computer or smartphone), open the Wi-Fi settings.

3. Select the 2.4 GHz network from the available Wi-Fi networks.

4. Enter the Wi-Fi password if prompted (usually found on the router).

5. Wait for the connection to establish, and you're now connected to the 2.4 GHz network for a more consistent Wi-Fi signal.`,
    SignalRecommendation2: `Your download speed is low. Disconnect other devices on your network to free up bandwidth and improve download speeds.

Follow these steps to identify and disconnect devices:

1. Access your router's web interface:
   - Open a web browser (e.g., Chrome, Firefox).
   - Enter your router's IP address in the browser's address bar. Common router IPs include 192.168.1.1 or 192.168.0.1. You can usually find this information on a sticker on your router or in the router's manual. Press Enter.

2. Log in to your router:
   - You'll be prompted to enter a username and password. This information can also be found on the router's sticker or in the manual. If you haven't changed these credentials before, try common defaults like "admin" for both the username and password. If you've changed them and can't remember, contact your router manufacturer for assistance.

3. Access the device list:
   - Once logged in, look for a section called "Device List," "Connected Devices," or something similar in your router's dashboard or settings. The exact wording may vary depending on your router's brand and model.

4. Identify and disconnect devices:
   - In the device list, you'll see a list of all the devices connected to your network. Typically, each device will be identified by its name or MAC address.
   - Identify the devices that you want to disconnect to free up bandwidth. Be careful not to disconnect any essential devices like your computer or smartphone.
   - Select the device you want to disconnect, and there should be an option to "Disconnect" or "Remove" it from the network. Click or tap on that option.

5. Confirm and repeat if necessary:
   - Confirm the disconnection if prompted. The selected device should be disconnected from your network.
   - If you have multiple devices to disconnect, repeat the process for each one.

By following these steps, you can find and disconnect devices from your network, which can help free up bandwidth and improve download speeds for your essential devices.`,
    SignalRecommendation3: `Your system is low on memory. Close programs you aren't using to free up RAM.

Follow these steps to close unused programs:

1. Press "Ctrl + Alt + Delete" on your keyboard simultaneously. This will open the Task Manager.

2. In the Task Manager window, you'll see a list of running applications and processes. Look for the programs that are using a significant amount of memory and are not essential for your current tasks.

3. Click on the program you want to close to select it.

4. After selecting the program, click the "End Task" or "End Process" button at the bottom right corner of the Task Manager window. This will terminate the selected program.

5. Repeat steps 3 and 4 for any other programs you wish to close to free up RAM.

6. Once you've closed the unnecessary programs, monitor your system's performance to see if the available RAM has increased.

By following these steps, you can close programs that are using up valuable system memory and help improve your computer's performance.`,
    SignalScore: 85,
    SignalOverallStatus: "Fair",
  },
  {
    SignalWifiStrength: 5,
    SignalWifiBand: "5 GHz",
    SignalCurrentNetwork: "Example Network",
    SignalWifiStatus: "Fair",
    SignalDownloadSpeed: 50.5,
    SignalUploadSpeed: 10.6,
    SignalPing: 10,
    SignalPacketLoss: 0.9,
    SignalBandwidthStatus: "Fair",
    SignalMemory: 30,
    SignalCPU: 20,
    SignalProcessor: "Intel(R) Core(TM) i7-9750H CPU @ 2.60GHz   2.59 GHz",
    SignalCores: 6,
    SignalSystemStatus: "Fair",
    SignalVpnDetected: "Not Connected",
    SignalAdapter: "Example Adapter",
    SignalNetworkStatus: "Not Connected",
    SignalRecommendation1: `Switch your router from 5 GHz to its 2.4 GHz network for a more consistent Wi-Fi signal.

Follow these steps to make the switch:

1. Locate your router and check the 2.4 GHz network name (SSID) on it.

2. On your device (computer or smartphone), open the Wi-Fi settings.

3. Select the 2.4 GHz network from the available Wi-Fi networks.

4. Enter the Wi-Fi password if prompted (usually found on the router).

5. Wait for the connection to establish, and you're now connected to the 2.4 GHz network for a more consistent Wi-Fi signal.`,
    SignalRecommendation2: `Your download speed is low. Disconnect other devices on your network to free up bandwidth and improve download speeds.

Follow these steps to identify and disconnect devices:

1. Access your router's web interface:
   - Open a web browser (e.g., Chrome, Firefox).
   - Enter your router's IP address in the browser's address bar. Common router IPs include 192.168.1.1 or 192.168.0.1. You can usually find this information on a sticker on your router or in the router's manual. Press Enter.

2. Log in to your router:
   - You'll be prompted to enter a username and password. This information can also be found on the router's sticker or in the manual. If you haven't changed these credentials before, try common defaults like "admin" for both the username and password. If you've changed them and can't remember, contact your router manufacturer for assistance.

3. Access the device list:
   - Once logged in, look for a section called "Device List," "Connected Devices," or something similar in your router's dashboard or settings. The exact wording may vary depending on your router's brand and model.

4. Identify and disconnect devices:
   - In the device list, you'll see a list of all the devices connected to your network. Typically, each device will be identified by its name or MAC address.
   - Identify the devices that you want to disconnect to free up bandwidth. Be careful not to disconnect any essential devices like your computer or smartphone.
   - Select the device you want to disconnect, and there should be an option to "Disconnect" or "Remove" it from the network. Click or tap on that option.

5. Confirm and repeat if necessary:
   - Confirm the disconnection if prompted. The selected device should be disconnected from your network.
   - If you have multiple devices to disconnect, repeat the process for each one.

By following these steps, you can find and disconnect devices from your network, which can help free up bandwidth and improve download speeds for your essential devices.`,
    SignalRecommendation3: `Your system is low on memory. Close programs you aren't using to free up RAM.

Follow these steps to close unused programs:

1. Press "Ctrl + Alt + Delete" on your keyboard simultaneously. This will open the Task Manager.

2. In the Task Manager window, you'll see a list of running applications and processes. Look for the programs that are using a significant amount of memory and are not essential for your current tasks.

3. Click on the program you want to close to select it.

4. After selecting the program, click the "End Task" or "End Process" button at the bottom right corner of the Task Manager window. This will terminate the selected program.

5. Repeat steps 3 and 4 for any other programs you wish to close to free up RAM.

6. Once you've closed the unnecessary programs, monitor your system's performance to see if the available RAM has increased.

By following these steps, you can close programs that are using up valuable system memory and help improve your computer's performance.`,
    SignalScore: 80,
    SignalOverallStatus: "Fair",
  },
  {
    SignalWifiStrength: 5,
    SignalWifiBand: "5 GHz",
    SignalCurrentNetwork: "Example Network",
    SignalWifiStatus: "Poor",
    SignalDownloadSpeed: 50.5,
    SignalUploadSpeed: 10.6,
    SignalPing: 10,
    SignalPacketLoss: 0.9,
    SignalBandwidthStatus: "Poor",
    SignalMemory: 30,
    SignalCPU: 20,
    SignalProcessor: "Intel(R) Core(TM) i7-9750H CPU @ 2.60GHz   2.59 GHz",
    SignalCores: 6,
    SignalSystemStatus: "Poor",
    SignalVpnDetected: "Not Connected",
    SignalAdapter: "Example Adapter",
    SignalNetworkStatus: "Connected",
    SignalRecommendation1: `Switch your router from 5 GHz to its 2.4 GHz network for a more consistent Wi-Fi signal.

Follow these steps to make the switch:

1. Locate your router and check the 2.4 GHz network name (SSID) on it.

2. On your device (computer or smartphone), open the Wi-Fi settings.

3. Select the 2.4 GHz network from the available Wi-Fi networks.

4. Enter the Wi-Fi password if prompted (usually found on the router).

5. Wait for the connection to establish, and you're now connected to the 2.4 GHz network for a more consistent Wi-Fi signal.`,
    SignalRecommendation2: `Your download speed is low. Disconnect other devices on your network to free up bandwidth and improve download speeds.

Follow these steps to identify and disconnect devices:

1. Access your router's web interface:
   - Open a web browser (e.g., Chrome, Firefox).
   - Enter your router's IP address in the browser's address bar. Common router IPs include 192.168.1.1 or 192.168.0.1. You can usually find this information on a sticker on your router or in the router's manual. Press Enter.

2. Log in to your router:
   - You'll be prompted to enter a username and password. This information can also be found on the router's sticker or in the manual. If you haven't changed these credentials before, try common defaults like "admin" for both the username and password. If you've changed them and can't remember, contact your router manufacturer for assistance.

3. Access the device list:
   - Once logged in, look for a section called "Device List," "Connected Devices," or something similar in your router's dashboard or settings. The exact wording may vary depending on your router's brand and model.

4. Identify and disconnect devices:
   - In the device list, you'll see a list of all the devices connected to your network. Typically, each device will be identified by its name or MAC address.
   - Identify the devices that you want to disconnect to free up bandwidth. Be careful not to disconnect any essential devices like your computer or smartphone.
   - Select the device you want to disconnect, and there should be an option to "Disconnect" or "Remove" it from the network. Click or tap on that option.

5. Confirm and repeat if necessary:
   - Confirm the disconnection if prompted. The selected device should be disconnected from your network.
   - If you have multiple devices to disconnect, repeat the process for each one.

By following these steps, you can find and disconnect devices from your network, which can help free up bandwidth and improve download speeds for your essential devices.`,
    SignalRecommendation3: `Your system is low on memory. Close programs you aren't using to free up RAM.

Follow these steps to close unused programs:

1. Press "Ctrl + Alt + Delete" on your keyboard simultaneously. This will open the Task Manager.

2. In the Task Manager window, you'll see a list of running applications and processes. Look for the programs that are using a significant amount of memory and are not essential for your current tasks.

3. Click on the program you want to close to select it.

4. After selecting the program, click the "End Task" or "End Process" button at the bottom right corner of the Task Manager window. This will terminate the selected program.

5. Repeat steps 3 and 4 for any other programs you wish to close to free up RAM.

6. Once you've closed the unnecessary programs, monitor your system's performance to see if the available RAM has increased.

By following these steps, you can close programs that are using up valuable system memory and help improve your computer's performance.`,
    SignalScore: 75,
    SignalOverallStatus: "Poor",
  },
  {
    SignalWifiStrength: 5,
    SignalWifiBand: "5 GHz",
    SignalCurrentNetwork: "Example Network",
    SignalWifiStatus: "Poor",
    SignalDownloadSpeed: 50.5,
    SignalUploadSpeed: 10.6,
    SignalPing: 10,
    SignalPacketLoss: 0.9,
    SignalBandwidthStatus: "Poor",
    SignalMemory: 30,
    SignalCPU: 20,
    SignalProcessor: "Intel(R) Core(TM) i7-9750H CPU @ 2.60GHz   2.59 GHz",
    SignalCores: 6,
    SignalSystemStatus: "Poor",
    SignalVpnDetected: "Not Connected",
    SignalAdapter: "Example Adapter",
    SignalNetworkStatus: "Connected",
    SignalRecommendation1: `Switch your router from 5 GHz to its 2.4 GHz network for a more consistent Wi-Fi signal.

Follow these steps to make the switch:

1. Locate your router and check the 2.4 GHz network name (SSID) on it.

2. On your device (computer or smartphone), open the Wi-Fi settings.

3. Select the 2.4 GHz network from the available Wi-Fi networks.

4. Enter the Wi-Fi password if prompted (usually found on the router).

5. Wait for the connection to establish, and you're now connected to the 2.4 GHz network for a more consistent Wi-Fi signal.`,
    SignalRecommendation2: `Your download speed is low. Disconnect other devices on your network to free up bandwidth and improve download speeds.

Follow these steps to identify and disconnect devices:

1. Access your router's web interface:
   - Open a web browser (e.g., Chrome, Firefox).
   - Enter your router's IP address in the browser's address bar. Common router IPs include 192.168.1.1 or 192.168.0.1. You can usually find this information on a sticker on your router or in the router's manual. Press Enter.

2. Log in to your router:
   - You'll be prompted to enter a username and password. This information can also be found on the router's sticker or in the manual. If you haven't changed these credentials before, try common defaults like "admin" for both the username and password. If you've changed them and can't remember, contact your router manufacturer for assistance.

3. Access the device list:
   - Once logged in, look for a section called "Device List," "Connected Devices," or something similar in your router's dashboard or settings. The exact wording may vary depending on your router's brand and model.

4. Identify and disconnect devices:
   - In the device list, you'll see a list of all the devices connected to your network. Typically, each device will be identified by its name or MAC address.
   - Identify the devices that you want to disconnect to free up bandwidth. Be careful not to disconnect any essential devices like your computer or smartphone.
   - Select the device you want to disconnect, and there should be an option to "Disconnect" or "Remove" it from the network. Click or tap on that option.

5. Confirm and repeat if necessary:
   - Confirm the disconnection if prompted. The selected device should be disconnected from your network.
   - If you have multiple devices to disconnect, repeat the process for each one.

By following these steps, you can find and disconnect devices from your network, which can help free up bandwidth and improve download speeds for your essential devices.`,
    SignalRecommendation3: `Your system is low on memory. Close programs you aren't using to free up RAM.

Follow these steps to close unused programs:

1. Press "Ctrl + Alt + Delete" on your keyboard simultaneously. This will open the Task Manager.

2. In the Task Manager window, you'll see a list of running applications and processes. Look for the programs that are using a significant amount of memory and are not essential for your current tasks.

3. Click on the program you want to close to select it.

4. After selecting the program, click the "End Task" or "End Process" button at the bottom right corner of the Task Manager window. This will terminate the selected program.

5. Repeat steps 3 and 4 for any other programs you wish to close to free up RAM.

6. Once you've closed the unnecessary programs, monitor your system's performance to see if the available RAM has increased.

By following these steps, you can close programs that are using up valuable system memory and help improve your computer's performance.`,
    SignalScore: 70,
    SignalOverallStatus: "Poor",
  },
  {
    SignalWifiStrength: 5,
    SignalWifiBand: "5 GHz",
    SignalCurrentNetwork: "Example Network",
    SignalWifiStatus: "Critical",
    SignalDownloadSpeed: 50.5,
    SignalUploadSpeed: 10.6,
    SignalPing: 10,
    SignalPacketLoss: 0.9,
    SignalBandwidthStatus: "Critical",
    SignalMemory: 30,
    SignalCPU: 20,
    SignalProcessor: "Intel(R) Core(TM) i7-9750H CPU @ 2.60GHz   2.59 GHz",
    SignalCores: 6,
    SignalSystemStatus: "Critical",
    SignalVpnDetected: "Not Connected",
    SignalAdapter: "Example Adapter",
    SignalNetworkStatus: "Connected",
    SignalRecommendation1: `Switch your router from 5 GHz to its 2.4 GHz network for a more consistent Wi-Fi signal.

Follow these steps to make the switch:

1. Locate your router and check the 2.4 GHz network name (SSID) on it.

2. On your device (computer or smartphone), open the Wi-Fi settings.

3. Select the 2.4 GHz network from the available Wi-Fi networks.

4. Enter the Wi-Fi password if prompted (usually found on the router).

5. Wait for the connection to establish, and you're now connected to the 2.4 GHz network for a more consistent Wi-Fi signal.`,
    SignalRecommendation2: `Your download speed is low. Disconnect other devices on your network to free up bandwidth and improve download speeds.

Follow these steps to identify and disconnect devices:

1. Access your router's web interface:
   - Open a web browser (e.g., Chrome, Firefox).
   - Enter your router's IP address in the browser's address bar. Common router IPs include 192.168.1.1 or 192.168.0.1. You can usually find this information on a sticker on your router or in the router's manual. Press Enter.

2. Log in to your router:
   - You'll be prompted to enter a username and password. This information can also be found on the router's sticker or in the manual. If you haven't changed these credentials before, try common defaults like "admin" for both the username and password. If you've changed them and can't remember, contact your router manufacturer for assistance.

3. Access the device list:
   - Once logged in, look for a section called "Device List," "Connected Devices," or something similar in your router's dashboard or settings. The exact wording may vary depending on your router's brand and model.

4. Identify and disconnect devices:
   - In the device list, you'll see a list of all the devices connected to your network. Typically, each device will be identified by its name or MAC address.
   - Identify the devices that you want to disconnect to free up bandwidth. Be careful not to disconnect any essential devices like your computer or smartphone.
   - Select the device you want to disconnect, and there should be an option to "Disconnect" or "Remove" it from the network. Click or tap on that option.

5. Confirm and repeat if necessary:
   - Confirm the disconnection if prompted. The selected device should be disconnected from your network.
   - If you have multiple devices to disconnect, repeat the process for each one.

By following these steps, you can find and disconnect devices from your network, which can help free up bandwidth and improve download speeds for your essential devices.`,
    SignalRecommendation3: `Your system is low on memory. Close programs you aren't using to free up RAM.

Follow these steps to close unused programs:

1. Press "Ctrl + Alt + Delete" on your keyboard simultaneously. This will open the Task Manager.

2. In the Task Manager window, you'll see a list of running applications and processes. Look for the programs that are using a significant amount of memory and are not essential for your current tasks.

3. Click on the program you want to close to select it.

4. After selecting the program, click the "End Task" or "End Process" button at the bottom right corner of the Task Manager window. This will terminate the selected program.

5. Repeat steps 3 and 4 for any other programs you wish to close to free up RAM.

6. Once you've closed the unnecessary programs, monitor your system's performance to see if the available RAM has increased.

By following these steps, you can close programs that are using up valuable system memory and help improve your computer's performance.`,
    SignalScore: 65,
    SignalOverallStatus: "Critical",
  },
  {
    SignalWifiStrength: 5,
    SignalWifiBand: "5 GHz",
    SignalCurrentNetwork: "Example Network",
    SignalWifiStatus: "Critical",
    SignalDownloadSpeed: 50.5,
    SignalUploadSpeed: 10.6,
    SignalPing: 10,
    SignalPacketLoss: 0.9,
    SignalBandwidthStatus: "Critical",
    SignalMemory: 30,
    SignalCPU: 20,
    SignalProcessor: "Intel(R) Core(TM) i7-9750H CPU @ 2.60GHz   2.59 GHz",
    SignalCores: 6,
    SignalSystemStatus: "Critical",
    SignalVpnDetected: "Not Connected",
    SignalAdapter: "Example Adapter",
    SignalNetworkStatus: "Connected",
    SignalRecommendation1: `Switch your router from 5 GHz to its 2.4 GHz network for a more consistent Wi-Fi signal.

Follow these steps to make the switch:

1. Locate your router and check the 2.4 GHz network name (SSID) on it.

2. On your device (computer or smartphone), open the Wi-Fi settings.

3. Select the 2.4 GHz network from the available Wi-Fi networks.

4. Enter the Wi-Fi password if prompted (usually found on the router).

5. Wait for the connection to establish, and you're now connected to the 2.4 GHz network for a more consistent Wi-Fi signal.`,
    SignalRecommendation2: `Your download speed is low. Disconnect other devices on your network to free up bandwidth and improve download speeds.

Follow these steps to identify and disconnect devices:

1. Access your router's web interface:
   - Open a web browser (e.g., Chrome, Firefox).
   - Enter your router's IP address in the browser's address bar. Common router IPs include 192.168.1.1 or 192.168.0.1. You can usually find this information on a sticker on your router or in the router's manual. Press Enter.

2. Log in to your router:
   - You'll be prompted to enter a username and password. This information can also be found on the router's sticker or in the manual. If you haven't changed these credentials before, try common defaults like "admin" for both the username and password. If you've changed them and can't remember, contact your router manufacturer for assistance.

3. Access the device list:
   - Once logged in, look for a section called "Device List," "Connected Devices," or something similar in your router's dashboard or settings. The exact wording may vary depending on your router's brand and model.

4. Identify and disconnect devices:
   - In the device list, you'll see a list of all the devices connected to your network. Typically, each device will be identified by its name or MAC address.
   - Identify the devices that you want to disconnect to free up bandwidth. Be careful not to disconnect any essential devices like your computer or smartphone.
   - Select the device you want to disconnect, and there should be an option to "Disconnect" or "Remove" it from the network. Click or tap on that option.

5. Confirm and repeat if necessary:
   - Confirm the disconnection if prompted. The selected device should be disconnected from your network.
   - If you have multiple devices to disconnect, repeat the process for each one.

By following these steps, you can find and disconnect devices from your network, which can help free up bandwidth and improve download speeds for your essential devices.`,
    SignalRecommendation3: `Your system is low on memory. Close programs you aren't using to free up RAM.

Follow these steps to close unused programs:

1. Press "Ctrl + Alt + Delete" on your keyboard simultaneously. This will open the Task Manager.

2. In the Task Manager window, you'll see a list of running applications and processes. Look for the programs that are using a significant amount of memory and are not essential for your current tasks.

3. Click on the program you want to close to select it.

4. After selecting the program, click the "End Task" or "End Process" button at the bottom right corner of the Task Manager window. This will terminate the selected program.

5. Repeat steps 3 and 4 for any other programs you wish to close to free up RAM.

6. Once you've closed the unnecessary programs, monitor your system's performance to see if the available RAM has increased.

By following these steps, you can close programs that are using up valuable system memory and help improve your computer's performance.`,
    SignalScore: 60,
    SignalOverallStatus: "Critical",
  },
  {
    SignalWifiStrength: 5,
    SignalWifiBand: "5 GHz",
    SignalCurrentNetwork: "Example Network",
    SignalWifiStatus: "Critical",
    SignalDownloadSpeed: 50.5,
    SignalUploadSpeed: 10.6,
    SignalPing: 10,
    SignalPacketLoss: 0.9,
    SignalBandwidthStatus: "Critical",
    SignalMemory: 30,
    SignalCPU: 20,
    SignalProcessor: "Intel(R) Core(TM) i7-9750H CPU @ 2.60GHz   2.59 GHz",
    SignalCores: 6,
    SignalSystemStatus: "Poor",
    SignalVpnDetected: "Not Connected",
    SignalAdapter: "Example Adapter",
    SignalNetworkStatus: "Connected",
    SignalRecommendation1: `Switch your router from 5 GHz to its 2.4 GHz network for a more consistent Wi-Fi signal.

Follow these steps to make the switch:

1. Locate your router and check the 2.4 GHz network name (SSID) on it.

2. On your device (computer or smartphone), open the Wi-Fi settings.

3. Select the 2.4 GHz network from the available Wi-Fi networks.

4. Enter the Wi-Fi password if prompted (usually found on the router).

5. Wait for the connection to establish, and you're now connected to the 2.4 GHz network for a more consistent Wi-Fi signal.`,
    SignalRecommendation2: `Your download speed is low. Disconnect other devices on your network to free up bandwidth and improve download speeds.

Follow these steps to identify and disconnect devices:

1. Access your router's web interface:
   - Open a web browser (e.g., Chrome, Firefox).
   - Enter your router's IP address in the browser's address bar. Common router IPs include 192.168.1.1 or 192.168.0.1. You can usually find this information on a sticker on your router or in the router's manual. Press Enter.

2. Log in to your router:
   - You'll be prompted to enter a username and password. This information can also be found on the router's sticker or in the manual. If you haven't changed these credentials before, try common defaults like "admin" for both the username and password. If you've changed them and can't remember, contact your router manufacturer for assistance.

3. Access the device list:
   - Once logged in, look for a section called "Device List," "Connected Devices," or something similar in your router's dashboard or settings. The exact wording may vary depending on your router's brand and model.

4. Identify and disconnect devices:
   - In the device list, you'll see a list of all the devices connected to your network. Typically, each device will be identified by its name or MAC address.
   - Identify the devices that you want to disconnect to free up bandwidth. Be careful not to disconnect any essential devices like your computer or smartphone.
   - Select the device you want to disconnect, and there should be an option to "Disconnect" or "Remove" it from the network. Click or tap on that option.

5. Confirm and repeat if necessary:
   - Confirm the disconnection if prompted. The selected device should be disconnected from your network.
   - If you have multiple devices to disconnect, repeat the process for each one.

By following these steps, you can find and disconnect devices from your network, which can help free up bandwidth and improve download speeds for your essential devices.`,
    SignalRecommendation3: `Your system is low on memory. Close programs you aren't using to free up RAM.

Follow these steps to close unused programs:

1. Press "Ctrl + Alt + Delete" on your keyboard simultaneously. This will open the Task Manager.

2. In the Task Manager window, you'll see a list of running applications and processes. Look for the programs that are using a significant amount of memory and are not essential for your current tasks.

3. Click on the program you want to close to select it.

4. After selecting the program, click the "End Task" or "End Process" button at the bottom right corner of the Task Manager window. This will terminate the selected program.

5. Repeat steps 3 and 4 for any other programs you wish to close to free up RAM.

6. Once you've closed the unnecessary programs, monitor your system's performance to see if the available RAM has increased.

By following these steps, you can close programs that are using up valuable system memory and help improve your computer's performance.`,
    SignalScore: 55,
    SignalOverallStatus: "Critical",
  },
  {
    SignalWifiStrength: 5,
    SignalWifiBand: "5 GHz",
    SignalCurrentNetwork: "Example Network",
    SignalWifiStatus: "Critical",
    SignalDownloadSpeed: 50.5,
    SignalUploadSpeed: 10.6,
    SignalPing: 10,
    SignalPacketLoss: 0.9,
    SignalBandwidthStatus: "Critical",
    SignalMemory: 30,
    SignalCPU: 20,
    SignalProcessor: "Intel(R) Core(TM) i7-9750H CPU @ 2.60GHz   2.59 GHz",
    SignalCores: 6,
    SignalSystemStatus: "Critical",
    SignalVpnDetected: "Not Connected",
    SignalAdapter: "Example Adapter",
    SignalNetworkStatus: "Connected",
    SignalRecommendation1: `Switch your router from 5 GHz to its 2.4 GHz network for a more consistent Wi-Fi signal.

Follow these steps to make the switch:

1. Locate your router and check the 2.4 GHz network name (SSID) on it.

2. On your device (computer or smartphone), open the Wi-Fi settings.

3. Select the 2.4 GHz network from the available Wi-Fi networks.

4. Enter the Wi-Fi password if prompted (usually found on the router).

5. Wait for the connection to establish, and you're now connected to the 2.4 GHz network for a more consistent Wi-Fi signal.`,
    SignalRecommendation2: `Your download speed is low. Disconnect other devices on your network to free up bandwidth and improve download speeds.

Follow these steps to identify and disconnect devices:

1. Access your router's web interface:
   - Open a web browser (e.g., Chrome, Firefox).
   - Enter your router's IP address in the browser's address bar. Common router IPs include 192.168.1.1 or 192.168.0.1. You can usually find this information on a sticker on your router or in the router's manual. Press Enter.

2. Log in to your router:
   - You'll be prompted to enter a username and password. This information can also be found on the router's sticker or in the manual. If you haven't changed these credentials before, try common defaults like "admin" for both the username and password. If you've changed them and can't remember, contact your router manufacturer for assistance.

3. Access the device list:
   - Once logged in, look for a section called "Device List," "Connected Devices," or something similar in your router's dashboard or settings. The exact wording may vary depending on your router's brand and model.

4. Identify and disconnect devices:
   - In the device list, you'll see a list of all the devices connected to your network. Typically, each device will be identified by its name or MAC address.
   - Identify the devices that you want to disconnect to free up bandwidth. Be careful not to disconnect any essential devices like your computer or smartphone.
   - Select the device you want to disconnect, and there should be an option to "Disconnect" or "Remove" it from the network. Click or tap on that option.

5. Confirm and repeat if necessary:
   - Confirm the disconnection if prompted. The selected device should be disconnected from your network.
   - If you have multiple devices to disconnect, repeat the process for each one.

By following these steps, you can find and disconnect devices from your network, which can help free up bandwidth and improve download speeds for your essential devices.`,
    SignalRecommendation3: `Your system is low on memory. Close programs you aren't using to free up RAM.

Follow these steps to close unused programs:

1. Press "Ctrl + Alt + Delete" on your keyboard simultaneously. This will open the Task Manager.

2. In the Task Manager window, you'll see a list of running applications and processes. Look for the programs that are using a significant amount of memory and are not essential for your current tasks.

3. Click on the program you want to close to select it.

4. After selecting the program, click the "End Task" or "End Process" button at the bottom right corner of the Task Manager window. This will terminate the selected program.

5. Repeat steps 3 and 4 for any other programs you wish to close to free up RAM.

6. Once you've closed the unnecessary programs, monitor your system's performance to see if the available RAM has increased.

By following these steps, you can close programs that are using up valuable system memory and help improve your computer's performance.`,
    SignalScore: 50,
    SignalOverallStatus: "Critical",
  },
];
