import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import config from "./config";
import "./Invite.css";

const Invite = ({refreshUserAccountInfo, refreshTeam}) => {
  const [invitations, setInvitations] = useState([]);
  const navigate = useNavigate(); // Initialize the navigate function

  const API_URL = config.API_URL;

  useEffect(() => {
    fetchInvitations();
  }, []);

  const fetchInvitations = async () => {
    const userEmail = localStorage.getItem("loggedInEmail");
    const response = await fetch(`${API_URL}api/User/GetInvitations/${userEmail}`);
    if (response.ok) {
      const data = await response.json();
      setInvitations(data);
    }
  };

  const handleResponse = async (invitationId, accept) => {
    // Prepare form data
    const formData = new FormData();
    formData.append("invitationId", invitationId);
    formData.append("accept", accept);

    // Send response to the server
    const response = await fetch(`${API_URL}api/User/RespondToInvitation`, {
      method: "POST",
      body: formData,
    });

    if (response.ok) {
      // Navigate to the dashboard page after handling the invitation
      refreshUserAccountInfo();
      refreshTeam();
      navigate("/dashboard");
    }
  };

  return (
    <div>
      <div className="invite">
        {invitations.length > 0 ? (
          invitations.map((invitation) => (
            <div key={invitation.InvitationId} className="invite-buttons-container">
              <p className="invite-message">
                You have been invited to join {invitation.InvitedTeamName} by {invitation.InvitingUserFirstName} {invitation.InvitingUserLastName}
              </p>
              <div className="buttons-row">
                <button className="invite-accept-button" onClick={() => handleResponse(invitation.InvitationId, true)}>
                  Accept
                </button>
                <button className="invite-decline-button" onClick={() => handleResponse(invitation.InvitationId, false)}>
                  Decline
                </button>
              </div>
            </div>
          ))
        ) : (
          <p>You have no current team invites.</p>
        )}
      </div>
    </div>
  );
};

export default Invite;
