import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./Team.css";
import {
  AddTeamMemberCircleSvg,
  AddTeamMemberIconSvg,
  InviteButtonIconSvg,
  OuterEllipseSvg,
  InnerEllipseSvg,
  DetailsCloseButtonSvg,
  DetailsIconBackgroundSvg,
  DetailsIconWorkspaceSvg,
  DetailsIconNetworkSvg,
  DetailsIconDeviceSvg,
  DetailsIconInsightsSvg,
  BulletListEllipse,
} from "./images/TeamSVG.js";

import {
  WorkspaceWifiStrengthIconSvg,
  WorkspaceNetworkFrequencyIconSvg,
  WorkspaceCurrentNetworkIconSvg,
  WorkspaceVPNIconSvg,
  NetworkDownloadIconSvg,
  NetworkUploadIconSvg,
  NetworkLatencyIconSvg,
  NetworkPacketLossIconSvg,
  DeviceCPUIconSvg,
  DeviceMemoryIconSvg,
  DeviceProcessorIconSvg,
  DeviceCoresIconSvg,
  RecommendationIconSvg,
} from "./images/SignalSVG";
import {
  CarouselButtonLeftSVG,
  CarouselButtonRightSVG,
  AddServiceCircleSvg,
  AddServiceIconSvg,
  InviteIconSvg,
  InviteIconAiplaneSvg,
  AddServicesIconSvg,
  AddMembersIconSvg,
} from "./images/DashboardSVG";

import {
  Gauge100,
  Gauge095,
  Gauge090,
  Gauge085,
  Gauge080,
  Gauge075,
  Gauge070,
  Gauge065,
  Gauge060,
  Gauge055,
  Gauge050,
  Gauge045,
  Gauge040,
  Gauge035,
  Gauge030,
  Gauge025,
  Gauge020,
  Gauge015,
  Gauge010,
  Gauge005,
} from "./images/Gauges";

const Team = ({ createTeam, team, leaveTeam, isAdmin, userID, sendInvitation, removeTeamMember, refreshUserAccountInfo, userAccountInfo }) => {
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const { memberId } = useParams();
  const [teamName, setTeamName] = useState("");
  const [showModal, setShowModal] = useState(false);
  const firstOtherMemberEmail = team?.find((member) => member.UserId !== userID)?.UserEmail || "";
  const [newAdminEmail, setNewAdminEmail] = useState(firstOtherMemberEmail);
  const [inviteEmail, setInviteEmail] = useState("");
  const [showRemoveConfirmationModal, setShowRemoveConfirmationModal] = useState(false);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [selectedMemberID, setSelectedMemberID] = useState(null);
  const [selectedMemberFirstName, setSelectedMemberFirstName] = useState("");
  const [selectedMemberLastName, setSelectedMemberLastName] = useState("");
  const [selectedMemberEmail, setSelectedMemberEmail] = useState("");
  const [selectedMemberTeamId, setSelectedMemberTeamId] = useState(null);
  const [selectedMemberTeamName, setSelectedMemberTeamName] = useState("");
  const [selectedMemberIsTeamAdmin, setSelectedMemberIsTeamAdmin] = useState(false);
  const [selectedMemberSignalScore, setSelectedMemberSignalScore] = useState(null);
  const [selectedMemberSignalWifiStrength, setSelectedMemberSignalWifiStrength] = useState(null);
  const [selectedMemberSignalCurrentNetwork, setSelectedMemberSignalCurrentNetwork] = useState("");
  const [selectedMemberSignalWifiStatus, setSelectedMemberSignalWifiStatus] = useState("");
  const [selectedMemberSignalDownloadSpeed, setSelectedMemberSignalDownloadSpeed] = useState(null);
  const [selectedMemberSignalPing, setSelectedMemberSignalPing] = useState(null);
  const [selectedMemberSignalBandwidthStatus, setSelectedMemberSignalBandwidthStatus] = useState("");
  const [selectedMemberSignalMemory, setSelectedMemberSignalMemory] = useState(null);
  const [selectedMemberSignalCpu, setSelectedMemberSignalCpu] = useState(null);
  const [selectedMemberSignalSystemStatus, setSelectedMemberSignalSystemStatus] = useState("");
  const [selectedMemberSignalVpnDetected, setSelectedMemberSignalVpnDetected] = useState("");
  const [selectedMemberSignalAdapter, setSelectedMemberSignalAdapter] = useState("");
  const [selectedMemberSignalNetworkStatus, setSelectedMemberSignalNetworkStatus] = useState("");
  const [selectedMemberSignalRecommendation1, setSelectedMemberSignalRecommendation1] = useState("");
  const [selectedMemberSignalRecommendation2, setSelectedMemberSignalRecommendation2] = useState("");
  const [selectedMemberSignalRecommendation3, setSelectedMemberSignalRecommendation3] = useState("");
  const [selectedMemberSignalOverallStatus, setSelectedMemberSignalOverallStatus] = useState("");
  const [selectedMemberSignalWifiBand, setSelectedMemberSignalWifiBand] = useState("");
  const [selectedMemberSignalUploadSpeed, setSelectedMemberSignalUploadSpeed] = useState(null);
  const [selectedMemberSignalPacketLoss, setSelectedMemberSignalPacketLoss] = useState(null);
  const [selectedMemberSignalProcessor, setSelectedMemberSignalProcessor] = useState("");
  const [selectedMemberSignalCores, setSelectedMemberSignalCores] = useState(null);
  const [selectedMemberSignalDeviceId, setSelectedMemberSignalDeviceId] = useState("");

  const [isRecommendationModalOpen, setIsRecommendationModalOpen] = useState(false);
  const [recommendationModalContent, setRecommendationModalContent] = useState({});

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    refreshUserAccountInfo();
  }, [refreshUserAccountInfo]);

  useEffect(() => {
    // Only check for the invite modal state from navigation
    if (location.state?.openInviteModal) {
      setIsInviteModalOpen(true);
      // Clear the navigation state to prevent the modal from opening on refresh or subsequent updates
      navigate("/team", { replace: true });
    }
  }, [location]);

  useEffect(() => {
    if (memberId) {
      const memberFromUrl = team.find((m) => m.UserId.toString() === memberId);
      if (memberFromUrl) {
        openDetailsModal(memberFromUrl); // Ensure this function only sets the selected member without toggling the modal state
        navigate("/team", { replace: true });
      }
    }
  }, [memberId, team]);

  useEffect(() => {
    // If selectedMember exists and team updates, update selectedMember details
    if (selectedMember && team) {
      const updatedMember = team.find((m) => m.UserId === selectedMember.UserId);
      if (updatedMember) {
        setSelectedMember(updatedMember);
        setSelectedMemberFirstName(updatedMember.UserFirstName);
        setSelectedMemberLastName(updatedMember.UserLastName);
        setSelectedMemberEmail(updatedMember.UserEmail);
        setSelectedMemberTeamId(updatedMember.TeamId);
        setSelectedMemberTeamName(updatedMember.TeamName);
        setSelectedMemberIsTeamAdmin(updatedMember.IsTeamAdmin);
        setSelectedMemberSignalScore(updatedMember.SignalScore);
        setSelectedMemberSignalWifiStrength(updatedMember.SignalWifiStrength);
        setSelectedMemberSignalCurrentNetwork(updatedMember.SignalCurrentNetwork);
        setSelectedMemberSignalWifiStatus(updatedMember.SignalWifiStatus);
        setSelectedMemberSignalDownloadSpeed(updatedMember.SignalDownloadSpeed);
        setSelectedMemberSignalPing(updatedMember.SignalPing);
        setSelectedMemberSignalBandwidthStatus(updatedMember.SignalBandwidthStatus);
        setSelectedMemberSignalMemory(updatedMember.SignalMemory);
        setSelectedMemberSignalCpu(updatedMember.SignalCPU);
        setSelectedMemberSignalSystemStatus(updatedMember.SignalSystemStatus);
        setSelectedMemberSignalVpnDetected(updatedMember.SignalVpnDetected);
        setSelectedMemberSignalAdapter(updatedMember.SignalAdapter);
        setSelectedMemberSignalNetworkStatus(updatedMember.SignalNetworkStatus);
        setSelectedMemberSignalRecommendation1(updatedMember.SignalRecommendation1);
        setSelectedMemberSignalRecommendation2(updatedMember.SignalRecommendation2);
        setSelectedMemberSignalRecommendation3(updatedMember.SignalRecommendation3);
        setSelectedMemberSignalOverallStatus(updatedMember.SignalOverallStatus);
        setSelectedMemberSignalWifiBand(updatedMember.SignalWifiBand);
        setSelectedMemberSignalUploadSpeed(updatedMember.SignalUploadSpeed);
        setSelectedMemberSignalPacketLoss(updatedMember.SignalPacketLoss);
        setSelectedMemberSignalProcessor(updatedMember.SignalProcessor);
        setSelectedMemberSignalCores(updatedMember.SignalCores);
        setSelectedMemberSignalDeviceId(updatedMember.SignalDeviceId);
      }
    }
  }, [team, selectedMember]);

  const openDetailsModal = (member) => {
    console.log(member);
    setSelectedMember(member);
    setSelectedMemberID(member.UserId);
    setSelectedMemberFirstName(member.UserFirstName);
    setSelectedMemberLastName(member.UserLastName);
    setSelectedMemberEmail(member.UserEmail);
    setSelectedMemberTeamId(member.TeamId);
    setSelectedMemberTeamName(member.TeamName);
    setSelectedMemberIsTeamAdmin(member.IsTeamAdmin);
    setSelectedMemberSignalScore(member.SignalScore);
    setSelectedMemberSignalWifiStrength(member.SignalWifiStrength);
    setSelectedMemberSignalCurrentNetwork(member.SignalCurrentNetwork);
    setSelectedMemberSignalWifiStatus(member.SignalWifiStatus);
    setSelectedMemberSignalDownloadSpeed(member.SignalDownloadSpeed);
    setSelectedMemberSignalPing(member.SignalPing);
    setSelectedMemberSignalBandwidthStatus(member.SignalBandwidthStatus);
    setSelectedMemberSignalMemory(member.SignalMemory);
    setSelectedMemberSignalCpu(member.SignalCpu);
    setSelectedMemberSignalSystemStatus(member.SignalSystemStatus);
    setSelectedMemberSignalVpnDetected(member.SignalVPNDetected);
    setSelectedMemberSignalAdapter(member.SignalAdapter);
    setSelectedMemberSignalNetworkStatus(member.SignalNetworkStatus);
    setSelectedMemberSignalRecommendation1(member.SignalRecommendation1);
    setSelectedMemberSignalRecommendation2(member.SignalRecommendation2);
    setSelectedMemberSignalRecommendation3(member.SignalRecommendation3);
    setSelectedMemberSignalOverallStatus(member.SignalOverallStatus);
    setSelectedMemberSignalWifiBand(member.SignalWifiBand);
    setSelectedMemberSignalUploadSpeed(member.SignalUploadSpeed);
    setSelectedMemberSignalPacketLoss(member.SignalPacketLoss);
    setSelectedMemberSignalProcessor(member.SignalProcessor);
    setSelectedMemberSignalCores(member.SignalCores);
    setSelectedMemberSignalDeviceId(member.SignalDeviceId);
    setIsDetailsModalOpen(true);
  };

  const gaugeComponents = {
    100: Gauge100,
    95: Gauge095,
    90: Gauge090,
    85: Gauge085,
    80: Gauge080,
    75: Gauge075,
    70: Gauge070,
    65: Gauge065,
    60: Gauge060,
    55: Gauge055,
    50: Gauge050,
    45: Gauge045,
    40: Gauge040,
    35: Gauge035,
    30: Gauge030,
    25: Gauge025,
    20: Gauge020,
    15: Gauge015,
    10: Gauge010,
    5: Gauge005,
  };

  const roundUpToNearestFive = (num) => {
    return Math.ceil(num / 5) * 5;
  };

  const getGaugeComponent = (score) => {
    const roundedScore = roundUpToNearestFive(score);
    const GaugeComponent = gaugeComponents[roundedScore];

    if (!GaugeComponent) {
      return null;
    }

    return <GaugeComponent className="gauge-svg" />;
  };

  const getColorByStatus = (status) => {
    switch (status) {
      case "Unknown":
        return "#6b96c1";
      case "Critical":
        return "#EC2F3E";
      case "Poor":
        return "#FC6F20";
      case "Fair":
        return "#F9AE00";
      case "Excellent":
        return "#3BB537";
      default:
        return "inherit"; // default color
    }
  };

  // Calculate the count of recommendations with values
  const recommendationCount = [selectedMemberSignalRecommendation1, selectedMemberSignalRecommendation2, selectedMemberSignalRecommendation3].filter(
    Boolean
  ).length;

  // Determine what to display based on the recommendation count
  let recommendationCountDisplay = "";
  if (recommendationCount === 0) {
    recommendationCountDisplay = "None";
  } else {
    recommendationCountDisplay = recommendationCount.toString();
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    createTeam(teamName);
    setTeamName("");
  };

  const handleLeaveTeamClick = () => {
    setShowModal(true);
  };

  const handleConfirmLeave = () => {
    leaveTeam(newAdminEmail);
    setShowModal(false);
  };

  const handleSendInvite = async () => {
    if (!inviteEmail) {
      alert("Please enter an email address.");
      return;
    }

    const teamId = team[0].TeamId;
    const invitingUserId = userID;

    const invitationRequest = {
      InvitingUserId: invitingUserId,
      InvitingUserFirstName: localStorage.getItem("userFirstName"),
      InvitingUserLastName: localStorage.getItem("userLastName"),
      InvitedEmail: inviteEmail,
      TeamId: teamId,
      Accepted: false,
      Declined: false,
    };

    try {
      if (userAccountInfo.UserMonitorsTotal <= userAccountInfo.UserMonitorsUsed) {
        if (userAccountInfo.IsUserCustomer && userAccountInfo.UserStripePlan && userAccountInfo.UserStripePlan !== "cancelled") {
          // If the user is a customer, open the Stripe billing page in a new tab
          window.open("https://billing.stripe.com/p/login/8wMdUQfLt1PjdpK5kk", "_blank");
        } else {
          // If the user is not a customer, navigate to the "Plans" page within the same tab
          navigate("/plans"); // Replace '/plans' with the actual path to your Plans page
        }
      } else {
        await sendInvitation(invitationRequest);
        alert("Invitation sent successfully.");
        setInviteEmail("");
      }
    } catch (error) {
      console.error("Error sending invitation:", error);
      alert("Failed to send invitation.");
    }
  };

  const toggleInviteModal = () => {
    setIsInviteModalOpen(!isInviteModalOpen);
  };

  const toggleDetailsModal = () => {
    setIsDetailsModalOpen(!isDetailsModalOpen);
  };

  const handleConfirmRemove = async () => {
    await removeTeamMember(selectedMemberID); // Use the ID of the member to remove
    setShowRemoveConfirmationModal(false);
    setIsDetailsModalOpen(false);
  };

  function getStatusColor(status) {
    switch (status) {
      case "Excellent":
        return "#3BB537"; // Green
      case "Fair":
        return "#F9AE00"; // Yellow
      case "Poor":
        return "#FC6F20"; // Orange
      case "Critical":
        return "#EC2F3E"; // Red
      default:
        return "#6b96c1"; // Default color (Light blue)
    }
  }

  const getCpuColor = (cpuValue) => {
    if (cpuValue >= 95) {
      return "#EC2F3E"; // Red
    } else if (cpuValue >= 90) {
      return "#FC6F20"; // Orange
    } else if (cpuValue >= 80) {
      return "#F9AE00"; // Yellow
    } else if (cpuValue > 0) {
      return "#3BB537"; // Green
    } else if (cpuValue === 0 || cpuValue === null) {
      return "#6b96c1"; // Light blue
    }
    return "#6b96c1"; // Default color if no valid condition is met
  };

  const cpuColor = getCpuColor(selectedMemberSignalCpu);

  const getMemoryColor = (memoryValue) => {
    if (memoryValue >= 98) {
      return "#EC2F3E"; // Red
    } else if (memoryValue >= 96) {
      return "#FC6F20"; // Orange
    } else if (memoryValue >= 93) {
      return "#F9AE00"; // Yellow
    } else if (memoryValue > 0) {
      return "#3BB537"; // Green
    } else if (memoryValue === 0 || memoryValue === null) {
      return "#6b96c1"; // Light blue
    }
    return "#6b96c1"; // Default color if no valid condition is met
  };

  const memoryColor = getMemoryColor(selectedMemberSignalMemory);

  const processorColor = selectedMemberSignalProcessor === "Offline" ? "#6b96c1" : "#3BB537";

  const coresColor = selectedMemberSignalCores === 0 ? "#6b96c1" : "#3BB537";

  const getWifiStrengthColor = (wifiStrengthValue) => {
    if (wifiStrengthValue >= 90) {
      return "#3BB537"; // Green
    } else if (wifiStrengthValue >= 80) {
      return "#F9AE00"; // Yellow
    } else if (wifiStrengthValue >= 70) {
      return "#FC6F20"; // Orange
    } else if (wifiStrengthValue > 0) {
      return "#EC2F3E"; // Red
    } else if (wifiStrengthValue === 0 || wifiStrengthValue === null) {
      return "#6b96c1"; // Light blue
    }
    return "#6b96c1"; // Default color if no valid condition is met
  };

  const wifiStrengthColor = getWifiStrengthColor(selectedMemberSignalWifiStrength);

  const getWifiBandColor = () => {
    if (selectedMemberSignalWifiBand === "5 GHz") {
      if (selectedMemberSignalWifiStrength >= 90) {
        return "#3BB537"; // Green
      } else {
        return "#F9AE00"; // Yellow
      }
    } else if (selectedMemberSignalWifiBand === "2.4 GHz") {
      return "#3BB537"; // Green
    } else {
      return "#6b96c1"; // Default color
    }
  };

  const wifiBandColor = getWifiBandColor();

  const getVpnColor = () => {
    if (selectedMemberSignalVpnDetected === "Not Connected") {
      return "#3BB537"; // Green
    } else if (selectedMemberSignalVpnDetected === "0") {
      return "#6b96c1"; // Light blue
    } else {
      return "#F9AE00"; // Yellow
    }
  };

  const vpnColor = getVpnColor();
  const vpnDisplayText = selectedMemberSignalVpnDetected === "0" ? "Unknown" : selectedMemberSignalVpnDetected;

  const getDownloadSpeedColor = (downloadSpeedValue) => {
    if (downloadSpeedValue >= 3) {
      return "#3BB537"; // Green
    } else if (downloadSpeedValue >= 1.8) {
      return "#F9AE00"; // Yellow
    } else if (downloadSpeedValue >= 0.8) {
      return "#FC6F20"; // Orange
    } else if (downloadSpeedValue > 0) {
      return "#EC2F3E"; // Red
    } else if (downloadSpeedValue === 0 || downloadSpeedValue === null) {
      return "#6b96c1"; // Light blue
    }
    return "#6b96c1"; // Default color if no valid condition is met
  };

  const downloadSpeedColor = getDownloadSpeedColor(selectedMemberSignalDownloadSpeed);

  const getUploadSpeedColor = (uploadSpeedValue) => {
    if (uploadSpeedValue >= 3.8) {
      return "#3BB537"; // Green
    } else if (uploadSpeedValue >= 2.6) {
      return "#F9AE00"; // Yellow
    } else if (uploadSpeedValue >= 1.2) {
      return "#FC6F20"; // Orange
    } else if (uploadSpeedValue > 0) {
      return "#EC2F3E"; // Red
    } else if (uploadSpeedValue === 0 || uploadSpeedValue === null) {
      return "#6b96c1"; // Light blue
    }
    return "#6b96c1"; // Default color if no valid condition is met
  };

  const uploadSpeedColor = getUploadSpeedColor(selectedMemberSignalUploadSpeed);

  const getLatencyColor = (latencyValue) => {
    if (latencyValue === 0 || latencyValue === null) {
      return "#6b96c1"; // Light blue for undefined, null, or zero
    }

    if (latencyValue > 0 && latencyValue <= 20) {
      return "#3BB537"; // Green
    } else if (latencyValue <= 50) {
      return "#F9AE00"; // Yellow
    } else if (latencyValue <= 99) {
      return "#FC6F20"; // Orange
    } else if (latencyValue > 99) {
      return "#EC2F3E"; // Red
    }

    return "#6b96c1"; // Default color, theoretically unreachable but safe to include
  };

  const latencyColor = getLatencyColor(selectedMemberSignalPing);

  const getPacketLossColor = (packetLossValue, selectedMemberSignalOverallStatus) => {
    // First check if the overall status is 'Offline'
    console.log();
    if (selectedMemberSignalOverallStatus === "Offline") {
      return "#6b96c1"; // Light blue
    }

    // Then proceed with the packet loss evaluation
    if (packetLossValue <= 1.9) {
      return "#3BB537"; // Green
    } else if (packetLossValue <= 3.9) {
      return "#F9AE00"; // Yellow
    } else if (packetLossValue <= 5.9) {
      return "#FC6F20"; // Orange
    } else if (packetLossValue > 6) {
      return "#EC2F3E"; // Red
    } else if (packetLossValue === "0" || packetLossValue === 0 || packetLossValue === null) {
      return "#6b96c1"; // Light blue
    }

    return "#6b96c1"; // Default color if no valid condition is met
  };

  const packetLossColor = getPacketLossColor(selectedMemberSignalPacketLoss, selectedMemberSignalOverallStatus);

  const truncateText = (text) => {
    if (text.length > 25) {
      return text.substring(0, 22) + "..."; // Truncate and add ellipsis
    }
    return text; // Return original if 25 characters or less
  };

  const openRecommendationModal = (content) => {
    setRecommendationModalContent(content);
    setIsRecommendationModalOpen(true);
  };

  const closeRecommendationModal = () => {
    setIsRecommendationModalOpen(false);
  };

  const getRecommendationContent = (recommendation) => {
    switch (recommendation) {
      case "wifi":
        return {
          message: "Your Wi-Fi signal is weak. Click here for steps to resolve this issue.",
          link: "https://www.signaltuner.com/how-to-fix-wifi-signal.html",
        };
      case "network":
        return {
          message: "Your network connection is experiencing issues. Click here for steps to resolve this issue.",
          link: "https://www.signaltuner.com/how-to-fix-download-speed.html",
        };
      case "device":
        return {
          message: "Your device is low on available resources. Click here for steps to resolve this issue.",
          link: "https://www.signaltuner.com/how-to-fix-low-memory-high-CPU.html",
        };
      case "vpn":
        return {
          message:
            "You are connected to a VPN, which can impact your connection performance. Consider disconnecting if the VPN is not necessary for your current activities.",
          link: null,
        };
      default:
        return null;
    }
  };

  const renderRecommendation = (recommendation) => {
    const content = getRecommendationContent(recommendation);
    if (content) {
      return (
        <div className="details-insights-item-container" onClick={() => openRecommendationModal(content)}>
          <p className="details-insights-item-text">
            <BulletListEllipse />
            {truncateText(content.message)}
          </p>
        </div>
      );
    }
    return (
      <div className="details-insights-item-container" onClick={() => openRecommendationModal({ message: recommendation, link: null })}>
        <p className="details-insights-item-text">
          <BulletListEllipse />
          {truncateText(recommendation)}
        </p>
      </div>
    );
  };

  const hasRecommendations = selectedMemberSignalRecommendation1 || selectedMemberSignalRecommendation2 || selectedMemberSignalRecommendation3;

  return (
    <div>
      <div className="team-container">
        {team && team.length > 0 ? <h2 className="team-name-headline">{team[0].TeamName}</h2> : <h2>Loading team data...</h2>}
        <div className="team-info-container">
          {team && team.length > 0 ? (
            <h4 className="team-members-count">Team Members: {team.length} </h4>
          ) : (
            <h4 className="team-members-count">Loading team data...</h4>
          )}

          {isAdmin &&
            (userAccountInfo ? (
              <h4 className="team-available-monitors"> Available monitors: {userAccountInfo.UserMonitorsTotal - userAccountInfo.UserMonitorsUsed}</h4>
            ) : (
              <h4 className="team-available-monitors">Loading team data...</h4>
            ))}
        </div>

        <div className="team-members-grid">
          {team && team.length > 0 ? (
            team
              .sort((a, b) => b.IsTeamAdmin - a.IsTeamAdmin)
              .map((member) => (
                <div key={member.UserId} className="team-member-card" onClick={() => openDetailsModal(member)}>
                  <div className="svg-container">
                    <OuterEllipseSvg />
                    <InnerEllipseSvg />
                    {getGaugeComponent(member.SignalScore)}
                  </div>
                  <div className="member-score">
                    <span className="team-score-container">
                      <span className="signal-score">{member.SignalScore}</span>
                      <span className="percentage-symbol">%</span>
                    </span>
                  </div>

                  <div className="team-member-overall-status" style={{ color: getStatusColor(member.SignalOverallStatus) }}>
                    {member.SignalOverallStatus}
                  </div>

                  <div className="member-name">
                    {member.UserFirstName} {member.UserLastName}
                  </div>
                </div>
              ))
          ) : (
            <p>No team members to display</p>
          )}

          {isAdmin && (
            <div className="add-team-member-card">
              <div className="add-team-member-svg-container" style={{ position: "relative" }}>
                <AddTeamMemberCircleSvg />
                <AddTeamMemberIconSvg />
              </div>

              <div className="add-team-member-text">Add a team member</div>
              <button className="invite-button" onClick={toggleInviteModal}>
                <InviteButtonIconSvg />
                Invite
              </button>
            </div>
          )}
        </div>
      </div>

      {isDetailsModalOpen && (
        <div className="details-modal">
          <div className="details-modal-content">
            <div onClick={toggleDetailsModal} style={{ cursor: "pointer" }}>
              <DetailsCloseButtonSvg className="details-close-button-svg" />
            </div>
            <div className="member-details-container">
              <p className="details-name">
                {selectedMemberFirstName} {selectedMemberLastName}
              </p>
              <p className="details-email">{selectedMemberEmail}</p>
            </div>
            {isAdmin && !selectedMemberIsTeamAdmin && (
              <button className="remove-from-team-button" onClick={() => setShowRemoveConfirmationModal(true)}>
                Remove
              </button>
            )}
            <div className="details-overall--status-container">
              <p className="details-overall-status-label">Overall Status:</p>
              <p className="details-overall-status-text" style={{ color: getColorByStatus(selectedMemberSignalOverallStatus) }}>
                {selectedMemberSignalOverallStatus}
              </p>
            </div>

            <div className="details-columns">
              <div className="details-column">
                <div className="device-svg-container">
                  <DetailsIconBackgroundSvg />
                  <DetailsIconDeviceSvg />
                </div>
                <div className="device-text-container">
                  <p className="device-text">Device</p>
                  <p className="device-status-text" style={{ color: getColorByStatus(selectedMemberSignalSystemStatus) }}>
                    {selectedMemberSignalSystemStatus}
                  </p>
                </div>
                <div className="details-values">
                  <div className="device-cpu-div">
                    <DeviceCPUIconSvg />
                    <p className="device-cpu-label">CPU</p>
                  </div>
                  <p className="device-cpu-value" style={{ color: cpuColor }}>
                    {selectedMemberSignalCpu}%
                  </p>
                  <div className="device-memory-div">
                    <DeviceMemoryIconSvg />
                    <p className="device-memory-label">Memory</p>
                  </div>
                  <p className="device-memory-value" style={{ color: memoryColor }}>
                    {selectedMemberSignalMemory}%
                  </p>
                  <div className="device-processor-div">
                    <DeviceProcessorIconSvg />
                    <p className="device-processor-label">Processor</p>
                  </div>
                  <p className="device-processor-value" style={{ color: processorColor }} title={selectedMemberSignalProcessor}>
                    {truncateText(selectedMemberSignalProcessor)}
                  </p>
                  <div className="device-cores-div">
                    <DeviceCoresIconSvg />
                    <p className="device-cores-label">Cores</p>
                  </div>
                  <p className="device-cores-value" style={{ color: coresColor }}>
                    {selectedMemberSignalCores}
                  </p>
                </div>
              </div>

              <div className="details-column">
                <div className="workspace-svg-container">
                  <DetailsIconBackgroundSvg />
                  <DetailsIconWorkspaceSvg />
                </div>
                <div className="workspace-text-container">
                  <p className="workspace-text">Workspace</p>
                  <p className="workspace-status-text" style={{ color: getColorByStatus(selectedMemberSignalWifiStatus) }}>
                    {selectedMemberSignalWifiStatus}
                  </p>
                </div>
                <div className="details-values">
                  <div className="workspace-wifi-strength-div">
                    <WorkspaceWifiStrengthIconSvg />
                    <p className="workspace-wifi-strength-label">Wi-Fi Strength</p>
                  </div>
                  <p className="workspace-wifi-strength-value" style={{ color: wifiStrengthColor }}>
                    {selectedMemberSignalWifiStrength}%
                  </p>
                  <div className="workspace-network-frequency-div">
                    <WorkspaceNetworkFrequencyIconSvg />
                    <p className="workspace-frequency-label">Frequency</p>
                  </div>
                  <p className="workspace-frequency-value" style={{ color: wifiBandColor }}>
                    {selectedMemberSignalWifiBand}
                  </p>
                  <div className="workspace-vpn-div">
                    <WorkspaceVPNIconSvg />
                    <p className="workspace-vpn-label">VPN</p>
                  </div>
                  <p className="workspace-vpn-value" style={{ color: vpnColor }}>
                    {vpnDisplayText}
                  </p>
                </div>
              </div>
              <div className="details-column">
                <div className="network-svg-container">
                  <DetailsIconBackgroundSvg />
                  <DetailsIconNetworkSvg />
                </div>
                <div className="network-text-container">
                  <p className="network-text">Network</p>
                  <p className="network-status-text" style={{ color: getColorByStatus(selectedMemberSignalBandwidthStatus) }}>
                    {selectedMemberSignalBandwidthStatus}
                  </p>
                </div>
                <div className="details-values">
                  <div className="network-download-div">
                    <NetworkDownloadIconSvg />
                    <p className="network-download-label">Download</p>
                  </div>
                  <p className="network-download-value" style={{ color: downloadSpeedColor }}>
                    {selectedMemberSignalDownloadSpeed} Mbps
                  </p>
                  <div className="network-upload-div">
                    <NetworkUploadIconSvg />
                    <p className="network-upload-label">Upload</p>
                  </div>
                  <p className="network-upload-value" style={{ color: uploadSpeedColor }}>
                    {selectedMemberSignalUploadSpeed} Mbps
                  </p>
                  <div className="network-latency-div">
                    <NetworkLatencyIconSvg />
                    <p className="network-latency-label">Latency</p>
                  </div>
                  <p className="network-latency-value" style={{ color: latencyColor }}>
                    {selectedMemberSignalPing} ms
                  </p>
                  <div className="network-packet-loss-div">
                    <NetworkPacketLossIconSvg />
                    <p className="network-packet-loss-label">Packet loss</p>
                  </div>
                  <p className="network-packet-loss-value" style={{ color: packetLossColor }}>
                    {selectedMemberSignalPacketLoss}%
                  </p>
                </div>
              </div>
              <div className="details-column">
                <div className="insights-svg-container">
                  <DetailsIconBackgroundSvg />
                  <DetailsIconInsightsSvg />
                </div>
                <div className="network-text-container">
                  <p className="network-text">Insights</p>
                </div>
                <div className="details-values-insights">
                {hasRecommendations ? (
              <>
                  {selectedMemberSignalRecommendation1 && renderRecommendation(selectedMemberSignalRecommendation1)}
                  {selectedMemberSignalRecommendation2 &&
                    selectedMemberSignalRecommendation2 !== "None" &&
                    renderRecommendation(selectedMemberSignalRecommendation2)}
                  {selectedMemberSignalRecommendation3 &&
                    selectedMemberSignalRecommendation3 !== "None" &&
                    renderRecommendation(selectedMemberSignalRecommendation3)}
              </>
            ) : (
              <div className="details-recommendation-none-container">
                <p className="details-recommendation-none-text">No recommendations.</p>
                <p className="details-recommendation-none-text">All systems optimal.</p>
              </div>
            )}

                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {isRecommendationModalOpen && (
        <div className="details-insights-modal-overlay">
          <div className="details-insights-modal">
            <div onClick={closeRecommendationModal} style={{ cursor: "pointer" }}>
              <DetailsCloseButtonSvg className="details-close-button-svg" />
            </div>
            <h2 className="details-insights-modal-headline">Insights</h2>
            <p className="details-insights-modal-text" style={{ whiteSpace: "pre-line" }}>
              {recommendationModalContent.link ? (
                <a href={recommendationModalContent.link} target="_blank" rel="noopener noreferrer">
                  {recommendationModalContent.message}
                </a>
              ) : (
                recommendationModalContent.message
              )}
            </p>
            <button className="details-insights-modal-close-button" onClick={closeRecommendationModal}>
              Close
            </button>
          </div>
        </div>
      )}

      {showRemoveConfirmationModal && (
        <div className="remove-confirmation-modal">
          <div className="remove-confirmation-modal-content">
            <p className="remove-confirmation-modal-text">Are you sure you want to remove {selectedMemberEmail} from your team?</p>
            <button className="remove-confirmation-modal-cancel-button" onClick={() => setShowRemoveConfirmationModal(false)}>
              Cancel
            </button>
            <button className="remove-confirmation-modal-confirm-button" onClick={handleConfirmRemove}>
              Confirm
            </button>
          </div>
        </div>
      )}

      {isAdmin && isInviteModalOpen && (
        <div className="team-invite-modal">
          <div className="team-invite-modal-content">
            <div onClick={toggleInviteModal} style={{ cursor: "pointer" }}>
              <DetailsCloseButtonSvg className="team-invite-modal-close-button" />
            </div>
            <div className="team-invite-input-container">
              <div className="team-invite-icons-div">
                <InviteIconAiplaneSvg className="team-invite-airplane-icon-svg" />
                <InviteIconSvg className="team-invite-icon-svg" />
              </div>
              <div className="team-invite-input-form">
                <p className="team-invite-modal-headline">Invite a new member to your team</p>
                <div className="team-invite-input-form-row">
                  <input
                    className="team-invite-modal-email-input"
                    type="email"
                    value={inviteEmail}
                    onChange={(e) => setInviteEmail(e.target.value)}
                    placeholder="Enter email to invite"
                  />
                  <button
                    className="team-invite-modal-invite-button"
                    onClick={() => {
                      handleSendInvite();
                      setIsInviteModalOpen(false);
                    }}
                  >
                    Send Invitation
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="team-leave-team-button-container">
        {!isAdmin && (
          <button className="team-leave-team-button" onClick={handleLeaveTeamClick}>
            Leave Team
          </button>
        )}
      </div>

      {showModal && (
        <div className="team-leave-team-confirm-modal">
          <div className="team-leave-team-confirm-modal-content">
            <p className="remove-confirmation-modal-text">Are you sure you want to leave the team?</p>
            {isAdmin && (
              <select value={newAdminEmail} onChange={(e) => setNewAdminEmail(e.target.value)}>
                {team
                  .filter((member) => member.UserId !== userID)
                  .map((member) => (
                    <option key={member.UserId} value={member.UserEmail}>
                      {member.UserName}
                    </option>
                  ))}
              </select>
            )}
            <button className="remove-confirmation-modal-confirm-button" onClick={handleConfirmLeave}>
              Leave
            </button>
            <button className="remove-confirmation-modal-cancel-button" onClick={() => setShowModal(false)}>
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Team;
