import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Navbar.css";

const Navbar = ({ logoutClick, loggedInEmail, loggedIn, showUserCreationClick, showLoginClick }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null); // Add a reference to the button
  const [isDemoMode, setIsDemoMode] = useState(localStorage.getItem("isDemoMode") === "true");

  const location = useLocation();
  const currentPage = location.pathname.split("/")[1]; 
  const pageTitles = {
    home: "",
    dashboard: "Dashboard",
    signal: "Signal",
    team: "Team",
    services: "Services",
    settings: "Settings",
    support: "Support",
    invite: "Invite",
  };

  const displayName = pageTitles[currentPage] || "";

  const toggleDropdown = (event) => {
    event.stopPropagation();
    setDropdownVisible((prevState) => !prevState);
  };

  const navigate = useNavigate();

  const handleEmailClick = () => {
    navigate("/settings?tab=Profile");
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target) // Check if the click is inside the button
      ) {
        setDropdownVisible(false);
      }
    };

    const handleStorageChange = (event) => {
      if (event.key === "isDemoMode") {
        setIsDemoMode(event.newValue === "true");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("storage", handleStorageChange);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <div>
      <div className="navbar">
        <div className="navbar-left">
          {dropdownVisible && (
            <div ref={dropdownRef} className="dropdown-menu">
              <div className="dropdown-menu-items-container">
                {!loggedIn && (
                  <Link to="/home" onClick={() => setDropdownVisible(false)}>
                    Home
                  </Link>
                )}
                {loggedIn && (
                  <Link to="/dashboard" onClick={() => setDropdownVisible(false)}>
                    Dashboard
                  </Link>
                )}
                {loggedIn && (
                  <Link to="/signal" onClick={() => setDropdownVisible(false)}>
                    Signal
                  </Link>
                )}
                {loggedIn && (
                  <Link to="/team" onClick={() => setDropdownVisible(false)}>
                    Team
                  </Link>
                )}
                {loggedIn && (
                  <Link to="/services" onClick={() => setDropdownVisible(false)}>
                    Services
                  </Link>
                )}
                {loggedIn && (
                  <Link to="/settings" onClick={() => setDropdownVisible(false)}>
                    Settings
                  </Link>
                )}
                <Link to="/support" onClick={() => setDropdownVisible(false)}>
                  Support
                </Link>
                <Link to="/downloads" className="downloads-link" onClick={() => setDropdownVisible(false)}>
                  Downloads
                </Link>
                {loggedIn && (
                  <Link to="/home" onClick={logoutClick}>
                    Sign out
                  </Link>
                )}
              </div>
            </div>
          )}
          <button
            ref={buttonRef}
            className="menu-icon-button"
            onClick={toggleDropdown}
          >
            <svg
              className="menu-icon-button-circle-svg"
              xmlns="http://www.w3.org/2000/svg"
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
            >
              <circle cx="24" cy="24" r="24" fill="white" />
            </svg>
            <svg
              className="menu-icon-button-lines-svg"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M2 5.995C2 5.445 2.446 5 2.995 5H11.005C11.2689 5 11.522 5.10483 11.7086 5.29143C11.8952 5.47803 12 5.73111 12 5.995C12 6.25889 11.8952 6.51197 11.7086 6.69857C11.522 6.88517 11.2689 6.99 11.005 6.99H2.995C2.73111 6.99 2.47803 6.88517 2.29143 6.69857C2.10483 6.51197 2 6.25889 2 5.995ZM2 12C2 11.45 2.446 11.005 2.995 11.005H21.005C21.2689 11.005 21.522 11.1098 21.7086 11.2964C21.8952 11.483 22 11.7361 22 12C22 12.2639 21.8952 12.517 21.7086 12.7036C21.522 12.8902 21.2689 12.995 21.005 12.995H2.995C2.73111 12.995 2.47803 12.8902 2.29143 12.7036C2.10483 12.517 2 12.2639 2 12ZM2.995 17.01C2.73111 17.01 2.47803 17.1148 2.29143 17.3014C2.10483 17.488 2 17.7411 2 18.005C2 18.2689 2.10483 18.522 2.29143 18.7086C2.47803 18.8952 2.73111 19 2.995 19H15.005C15.2689 19 15.522 18.8952 15.7086 18.7086C15.8952 18.522 16 18.2689 16 18.005C16 17.7411 15.8952 17.488 15.7086 17.3014C15.522 17.1148 15.2689 17.01 15.005 17.01H2.995Z"
                fill="#668DB0"
              />
            </svg>
          </button>
          <span className="page-title">{displayName}</span> {/* Display the current page title */}
        </div>
        <div className="navbar-center">
          <Link to="/">
            <img
              className="navbar-logo"
              src="https://signaltuner.com/images/SignalTUNER Logo Horizontal-01.png"
              alt="Logo"
            />
          </Link>
        </div>
        <div className="navbar-right">
          {isDemoMode && <p className="demo-mode-display">Demo Mode</p>}
          <p className="email-display" onClick={handleEmailClick}>
            {loggedInEmail}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
