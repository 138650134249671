// import logo from './logo.svg';
import axios from "axios";
import "./App.css";
import React from "react";
import { Component } from "react";
import { Link, Route, Router, Routes, useNavigate, Navigate } from "react-router-dom";
import { predefinedMembers, mockSignalDataSets } from "./MockData";
import * as signalR from "@microsoft/signalr";
import config from "./config";
import Home from "./Home";
import Dashboard from "./Dashboard";
import Signal from "./Signal";
import Team from "./Team";
import Services from "./Services";
import Settings from "./Settings";
import Support from "./Support";
import Plans from "./Plans";
import Downloads from "./Downloads";
import ResetPassword from "./ResetPassword";
import Invite from "./Invite";
import Navbar from "./Navbar";
import Demo from "./Demo";
import Popup from "./Popup";
import ProtectedRoute from "./ProtectedRoute";

const API_URL = config.API_URL;

const userId = localStorage.getItem("userID");

const connection = new signalR.HubConnectionBuilder()
  .withUrl(`${API_URL}statushub?userId=${userId}`)
  .configureLogging(signalR.LogLevel.Information)
  .withAutomaticReconnect() // Enable automatic reconnections
  .build();

// Start the connection
async function startConnection() {
  try {
    await connection.start();
    console.log("SignalR connection established.");
  } catch (err) {
    console.error("Error while starting SignalR connection: ", err);
    setTimeout(startConnection, 5000); // Retry connection every 5 seconds
  }
}

startConnection();

connection.on("ReceiveMessage", (data) => {
  console.log("Full data received:", data);
  console.log("Message content:", data.content);
  console.log("Message type:", data.type);

  if (data.type === "Reload") {
    console.log("Reloading the page as instructed by the server.");
    window.location.reload();
  } else if (data.type === "Update") {
    alert("Update: " + data.content);
  }
});

function sendMessage() {
  connection.invoke("SendMessage", "Hello World").catch(err => console.error("Error while sending message: ", err));
}

function withNavigation(WrappedComponent) {
  return function (props) {
    const navigate = useNavigate();
    return <WrappedComponent {...props} navigate={navigate} />;
  };
}

const createTeam = async (teamName) => {
  const userEmail = localStorage.getItem("loggedInEmail");

  try {
    const response = await axios.post(`${API_URL}api/User/AddTeam`, {
      UserEmail: userEmail,
      TeamName: teamName,
    });

    if (response.status === 200) {
      alert("Team created successfully.");
      window.location.reload();
    } else {
      alert("Failed to create a team.");
    }
  } catch (error) {
    console.error("Error creating team:", error);
    if (error.response && error.response.status === 400 && error.response.data === "User already has a team.") {
      alert("User already has a team.");
    } else {
      alert("An error occurred while creating the team.");
    }
  }
};

const downloadInstaller = () => {
  console.log("downloadInstaller");
  // Use navigator.userAgent for OS detection
  const userAgent = window.navigator.userAgent;
  let downloadUrl = "";

  // Regular expressions for detecting Windows and macOS
  const windowsRegex = /Windows NT/;
  const macOsRegex = /Mac OS/;

  // Check if the user's OS is Windows
  if (windowsRegex.test(userAgent)) {
    downloadUrl = "https://signaltuner.com/update/Setup.exe";
  }
  // Check if the user's OS is macOS
  else if (macOsRegex.test(userAgent)) {
    downloadUrl = "https://signaltuner.com/update/macos/SignalTuner.dmg";
  } else {
    // Handle cases where the OS is neither Windows nor macOS
    alert("Your operating system is not supported for direct download.");
    return;
  }

  // Create a temporary anchor element and trigger the download
  const tempLink = document.createElement("a");
  tempLink.href = downloadUrl;
  // The download attribute can optionally take a filename, e.g., `download="filename.ext"`
  tempLink.setAttribute("download", "");
  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
};

const sendInvitation = async (invitationRequest) => {
  try {
    const response = await fetch(`${API_URL}api/User/SendInvitation`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Include other headers like authorization if needed
      },
      body: JSON.stringify(invitationRequest),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Failed to send invitation.");
    }

    return await response.json();
  } catch (error) {
    console.error("Error sending invitation:", error);
    throw error;
  }
};

const sendSupportEmail = async (supportEmailRequest) => {
  try {
    const response = await fetch(`${API_URL}api/User/SendSupportEmail`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Include other headers like authorization if needed
      },
      body: JSON.stringify(supportEmailRequest),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Failed to send support email.");
    }

    return await response.json();
  } catch (error) {
    console.error("Error sending support email:", error);
    throw error;
  }
};

class App extends Component {
  constructor(props) {
    super(props);
    this.leaveTeam = this.leaveTeam.bind(this);
    const isDemoModeFromStorage = localStorage.getItem("isDemoMode") === "true";
    this.state = {
      users: [],
      signal: null,
      team: null,
      teamServices: [],
      userAccountInfo: null,
      loggedIn: false,
      loggedInEmail: "",
      loading: true,
      showLoginForm: false,
      showUserCreationForm: false,
      showForgotPasswordForm: false,
      userID: "",
      userTeamName: "",
      isAdmin: false,
      userHasSeenOnboarding: true,
      isDemoMode: isDemoModeFromStorage,
    };
    this.setUserHasSeenOnboarding = this.setUserHasSeenOnboarding.bind(this);
    this.refreshSignalInterval = null;
    this.toggleDemoMode = this.toggleDemoMode.bind(this);

    this.mockUserDataIndex = parseInt(localStorage.getItem("userMockDataIndex"), 10) || 0; // For refreshSignal()
    this.mockTeamDataIndex = parseInt(localStorage.getItem("teamMockDataIndex"), 10) || 0; // For refreshTeam()
  }

  componentDidMount() {
    // Check for stored user authentication and set the initial state
    this.refreshUserStateFromStorage();
    this.refreshTeam();
    this.refreshUserAccountInfo();
    const token = localStorage.getItem("token");
    const email = localStorage.getItem("loggedInEmail");
    const activationCode = localStorage.getItem("activationCode");

    if (token) {
      this.setState({ loggedIn: true, loggedInEmail: email, activationCode: activationCode, loading: false });
      this.refreshTeamServiceSelections();
      this.refreshTeam();
    } else {
      this.setState({ loading: false });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.loggedIn !== this.state.loggedIn) {
      if (this.state.loggedIn) {
        this.startRefreshSignalProcess();
        this.refreshTeam();
        this.refreshTeamServiceSelections();
      } else {
        this.stopRefreshSignalProcess();
      }
    }
  }

  componentWillUnmount() {
    // Clear the interval when the component is unmounted to prevent memory leaks
    this.stopRefreshSignalProcess();
  }

  isLoggedIn() {
    const token = localStorage.getItem("token");
    return !!token;
  }

  showLoginClick() {
    this.setState({
      showLoginForm: true,
      showUserCreationForm: false,
      showForgotPasswordForm: false,
    });
  }

  showUserCreationClick() {
    this.setState(
      {
        showLoginForm: false,
        showUserCreationForm: true,
        showForgotPasswordForm: false,
      },
      () => window.scrollTo(0, 0)
    ); // Callback to ensure scrolling happens after state update
  }

  showForgotPasswordClick() {
    this.setState({
      showLoginForm: false,
      showUserCreationForm: false,
      showForgotPasswordForm: true,
    });
  }

  hideHomePageFormsClick() {
    this.setState({
      showLoginForm: false,
      showUserCreationForm: false,
      showForgotPasswordForm: false,
    });
  }

  setUserHasSeenOnboarding = async () => {
    const userId = this.state.userID; // Assuming userID is stored in state after login

    if (!userId) {
      console.error("User ID is not available.");
      return;
    }

    const formData = new FormData();
    formData.append("userId", userId);

    try {
      const response = await fetch(API_URL + "api/User/SetUserHasSeenOnboarding", {
        method: "PUT",
        body: formData,
      });
      const result = await response.json();

      if (result.status === "success") {
        this.setState({ userHasSeenOnboarding: true });
        console.log("Onboarding status updated successfully.");
      } else {
        console.error("Failed to update onboarding status: " + result.message);
      }
    } catch (error) {
      console.error("Error updating onboarding status: ", error);
    }
  };

  toggleDemoMode = () => {
    this.setState(
      (prevState) => ({
        isDemoMode: !prevState.isDemoMode,
      }),
      () => {
        console.log("Demo mode is now:", this.state.isDemoMode);
        localStorage.setItem("isDemoMode", this.state.isDemoMode);
        localStorage.setItem("teamMockDataIndex", "0");
        localStorage.setItem("userMockDataIndex", "0");
      }
    );
  };

  async refreshUsers() {
    fetch(API_URL + "api/User/GetUsers")
      .then((response) => response.json())
      .then((data) => {
        this.setState({ users: data });
      });
  }

  // async updateUserInfo() {}

  startRefreshSignalProcess() {
    if (this.isLoggedIn()) {
      if (this.refreshSignalInterval === null) {
        this.refreshSignal();
        this.refreshTeam();

        // Set up a timer to fetch signal data every 10 seconds if the user is logged in
        this.refreshSignalInterval = setInterval(
          () => {
            this.refreshSignal();
            this.refreshTeam();
          },
          this.isDemoMode ? 60000 : 5000
        );
      }
    }
  }

  stopRefreshSignalProcess() {
    // Clear the interval if it is running
    if (this.refreshSignalInterval !== null) {
      clearInterval(this.refreshSignalInterval);
      this.refreshSignalInterval = null;
    }
  }

  async refreshSignal() {
    if (!this.isLoggedIn()) {
      console.log("User not logged in.");
      return;
    }

    const activationCode = localStorage.getItem("activationCode");
    const token = localStorage.getItem("token");

    if (!activationCode) {
      console.log("Activation code not found in local storage");
      return;
    }

    if (!token) {
      console.log("Token not found in local storage");
      return;
    }

    // Check if in demo mode
    const isDemoMode = localStorage.getItem("isDemoMode") === "true";
    if (isDemoMode) {
      // Use the current index to get the mock data
      const mockData = mockSignalDataSets[this.mockUserDataIndex];

      // Update the state with the mock data
      this.setState({ signal: mockData });

      // Increment the index for the next call
      this.mockUserDataIndex = (this.mockUserDataIndex + 1) % mockSignalDataSets.length;
      localStorage.setItem("userMockDataIndex", this.mockUserDataIndex.toString());

      return;
    }

    const url = `${API_URL}api/User/GetSignal/${activationCode}`;

    fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`, // Add the Authorization header with the token
      },
    })
      .then((response) => {
        if (response.status === 401) {
          // Token is expired or invalid
          throw new Error("Unauthorized");
        }
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        this.setState({ signal: data });
      })
      .catch((error) => {
        if (error.message === "Unauthorized") {
          // Handle token expiration or invalid token
          this.handleTokenExpiration();
        } else {
          console.error("Error fetching signal data:", error);
        }
      });
  }

  async refreshTeam() {
    if (!this.isLoggedIn()) {
      console.log("User not logged in.");
      return;
    }

    const isDemoMode = localStorage.getItem("isDemoMode") === "true";

    if (isDemoMode) {
      const userFirstName = localStorage.getItem("userFirstName");
      const userLastName = localStorage.getItem("userLastName");
      const userEmail = localStorage.getItem("loggedInEmail");
      const userID = parseInt(localStorage.getItem("userID"), 10);
      const userTeamID = parseInt(localStorage.getItem("userTeamID"), 10);

      const teamMembers = [
        {
          UserId: userID,
          UserFirstName: userFirstName,
          UserLastName: userLastName,
          UserEmail: userEmail,
          TeamId: userTeamID,
          TeamName: "Demonstration Team",
          IsTeamAdmin: true,
          ...mockSignalDataSets[this.mockTeamDataIndex % mockSignalDataSets.length],
        },
        // Predefined members
        ...predefinedMembers.map((member, index) => ({
          ...member,
          UserId: index + 2,
          TeamId: userTeamID,
          TeamName: "Demonstration Team",
          IsTeamAdmin: false,
          ...mockSignalDataSets[(this.mockTeamDataIndex + index + 1) % mockSignalDataSets.length],
        })),
      ];

      this.setState({ team: teamMembers });

      // Increment the index and save it for persistence
      this.mockTeamDataIndex = (this.mockTeamDataIndex + 1) % mockSignalDataSets.length;
      localStorage.setItem("teamMockDataIndex", this.mockTeamDataIndex.toString());

      return;
    }

    // Retrieve userID from local storage instead of activationCode
    const userID = localStorage.getItem("userID");
    const token = localStorage.getItem("token");

    if (!userID) {
      console.log("User ID not found in local storage");
      return;
    }

    if (!token) {
      console.log("Token not found in local storage");
      return;
    }

    // Update the URL to use userID
    const url = `${API_URL}api/User/GetTeam/${userID}`;

    fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`, // Add the Authorization header with the token
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        this.setState({ team: data });
      })
      .catch((error) => {
        console.error("Error fetching team data:", error);
      });
  }

  refreshTeamServiceSelections = async () => {
    if (!this.isLoggedIn()) {
      console.log("User not logged in.");
      return;
    }

    const userID = localStorage.getItem("userID");
    const token = localStorage.getItem("token");

    if (!userID) {
      console.log("User ID not found in local storage");
      return;
    }

    if (!token) {
      console.log("Token not found in local storage");
      return;
    }

    const url = `${API_URL}api/User/GetServicesAndSelections/${userID}`;

    fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`, // Add the Authorization header with the token
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        this.setState({ teamServices: data });
      })
      .catch((error) => {
        console.error("Error fetching team service selections:", error);
      });
  };

  removeServiceFromTeam = async (serviceId) => {
    if (!this.isLoggedIn()) {
      console.log("User not logged in.");
      return;
    }

    const userID = localStorage.getItem("userID");
    const token = localStorage.getItem("token");
    const teamId = localStorage.getItem("userTeamID"); // Retrieve the team ID associated with the user

    if (!userID || !token) {
      console.log("User ID or token not found in local storage");
      return;
    }

    try {
      await fetch(`${API_URL}api/User/DeleteTeamServiceSelection`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          UserId: userID,
          TeamId: teamId,
          ServiceId: serviceId,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then(() => {
          console.log(`Service with ID ${serviceId} removed from team.`);
          this.refreshTeamServiceSelections();
        });
    } catch (error) {
      console.error("Error removing service from team:", error);
    }
  };

  async leaveTeam(newAdminEmail) {
    const userEmail = localStorage.getItem("loggedInEmail");

    try {
      const queryParams = new URLSearchParams({ userEmail, newAdminEmail }).toString();
      const response = await fetch(`${API_URL}api/User/LeaveTeam?${queryParams}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        console.log("Successfully left the team.");
        this.refreshUserAccountInfo(userId);
        this.refreshTeam();
      } else {
        console.log("Failed to leave the team.");
      }
    } catch (error) {
      console.error("Error leaving team:", error);
      alert("An error occurred while leaving the team.");
    }
  }

  async removeTeamMember(memberIdToRemove) {
    const adminUserEmail = localStorage.getItem("loggedInEmail");
    const queryParams = new URLSearchParams({ adminUserEmail, memberIdToRemove }).toString();

    try {
      const response = await fetch(`${API_URL}api/User/RemoveTeamMember?${queryParams}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        console.log("Successfully removed the team member.");
      } else {
        console.log("Failed to remove the team member.");
      }
    } catch (error) {
      console.error("Error removing team member:", error);
      alert("An error occurred while removing the team member.");
    }
  }

  async addUserClick() {
    var email = document.getElementById("email").value;
    var password = document.getElementById("password").value;
    var confirmPassword = document.getElementById("confirmPassword").value;
    var firstName = document.getElementById("firstName").value;
    var lastName = document.getElementById("lastName").value;
    var phone = document.getElementById("phone").value;
    var smsAlerts = document.getElementById("smsAlertsCheckbox").checked;
  
    // Check if email valid
    if (!email.includes("@")) {
      alert("Email is not valid!");
      return;
    }
  
    // Check if passwords match
    if (password !== confirmPassword) {
      alert("Passwords do not match!");
      return;
    }
  
    // Check password conditions
    if (password.length < 8) {
      alert("Password must be at least 8 characters long!");
      return;
    }
  
    if (!/[A-Z]/.test(password)) {
      alert("Password must contain at least one uppercase letter!");
      return;
    }
  
    if (!/[a-z]/.test(password)) {
      alert("Password must contain at least one lowercase letter!");
      return;
    }
  
    if (!/[0-9]/.test(password)) {
      alert("Password must contain at least one number!");
      return;
    }
  
    if (!/[~`!@#$%^&*()\-_+={[}\]|:;"'<,>.?/]/.test(password)) {
      alert("Password must contain at least one special symbol (e.g., ~`!@#$%^&*()_-+={[}]|:;\"'<,>.?/)!");
      return;
    }
  
    const data = new FormData();
    data.append("email", email);
    data.append("password", password);
    data.append("firstName", firstName);
    data.append("lastName", lastName);
    data.append("phone", phone);
    data.append("smsAlerts", smsAlerts);
  
    return new Promise((resolve, reject) => {
      fetch(API_URL + "api/User/AddUser", {
        method: "POST",
        body: data,
      })
        .then((res) => res.json())
        .then((result) => {
          console.log("API Response:", result); // Log the API response
          if (result.status === "error" && result.message === "Email already exists") {
            alert("Email already exists. Please use a different email.");
            resolve("error");
          } else if (result.status === "success") {
            alert("Added new user successfully!");
            resolve("success");
          } else {
            alert("An unknown error occurred.");
            resolve("error");
          }
        })
        .catch((error) => {
          console.error("Error adding user:", error);
          alert("An error occurred while creating the account.", error);
          resolve("error");
        });
    });
  }
  

  async loginClick(email = null, password = null) {
    if (!email || !password) {
      // Try to get login details from the login form
      const loginEmailElement = document.getElementById("loginEmail");
      const loginPasswordElement = document.getElementById("loginPassword");
  
      if (loginEmailElement && loginPasswordElement) {
        email = loginEmailElement.value;
        password = loginPasswordElement.value;
      } else {
        // Fallback to account creation form if login form is not available
        const creationEmailElement = document.getElementById("email");
        const creationPasswordElement = document.getElementById("password");
  
        if (creationEmailElement && creationPasswordElement) {
          email = creationEmailElement.value;
          password = creationPasswordElement.value;
        } else {
          console.error("Email and password elements not found");
          return; // Exit the function if elements are not found
        }
      }
    }
  
    try {
      const loginResponse = await fetch(API_URL + "api/User/Login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });
  
      if (loginResponse.ok) {
        if (window.innerWidth < 1200) {
          alert("SignalTuner for mobile is under construction. Please login to your SignalTuner account on your desktop device.\n\nsupport@signaltuner.com");
          return;
        }
  
        const data = await loginResponse.json();
        localStorage.setItem("token", data.Token);
        localStorage.setItem("loggedInEmail", email);
        localStorage.setItem("activationCode", data.ActivationCode);
        localStorage.setItem("userID", data.UserID);
        localStorage.setItem("userTeamID", data.UserTeamID);
        localStorage.setItem("isAdmin", data.IsTeamAdmin);
        localStorage.setItem("userFirstName", data.UserFirstName);
        localStorage.setItem("userLastName", data.UserLastName);
        this.setState({
          loggedIn: true,
          loggedInEmail: email,
          activationCode: data.ActivationCode,
          isAdmin: data.IsTeamAdmin,
          userID: data.UserID,
          userHasSeenOnboarding: data.UserHasSeenOnboarding,
        });
        this.refreshUserAccountInfo();
  
        // Check for pending invitations
        const invitationResponse = await fetch(API_URL + "api/User/GetInvitations/" + email);
        if (invitationResponse.ok) {
          const invitations = await invitationResponse.json();
          if (invitations.length > 0) {
            // User has pending invitations, navigate to the invite page
            this.props.navigate("/invite");
          } else {
            // No pending invitations, navigate to the dashboard
            this.startRefreshSignalProcess();
            this.props.navigate("/dashboard");
          }
        } else {
          console.error("Failed to fetch invitations");
          // Handle error or default to dashboard navigation
          this.startRefreshSignalProcess();
          this.props.navigate("/dashboard");
        }
      } else {
        alert("Login failed!");
      }
    } catch (error) {
      console.error("Login error:", error);
      alert("Login failed due to an error.");
    }
  }
  
  
  

  refreshUserAccountInfo = async () => {
    if (!this.isLoggedIn()) {
      console.log("User not logged in.");
      return;
    }
  
    const userID = localStorage.getItem("userID");
    const token = localStorage.getItem("token");
  
    if (!userID) {
      console.log("User ID not found in local storage");
      return;
    }
  
    if (!token) {
      console.log("Token not found in local storage");
      return;
    }
  
    const url = `${API_URL}api/User/GetAccountInfo/${userID}`;
  
    try {
      const response = await fetch(url, {
        headers: {
          'Authorization': `Bearer ${token}`, // Add the Authorization header with the token
          'Content-Type': 'application/json'
        },
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const data = await response.json();
      // Set user information in local storage
      localStorage.setItem("isAdmin", data.IsUserTeamAdmin);
      localStorage.setItem("userMonitorsTotal", data.UserMonitorsTotal);
      localStorage.setItem("userStripeCustomer", data.IsUserCustomer);
      localStorage.setItem("userStripePlan", data.UserStripePlan);
      localStorage.setItem("teamMemberCount", data.TeamMemberCount);
      localStorage.setItem("teamServicesCount", data.TeamServicesCount);
      localStorage.setItem("userMonitorsUsed", data.UserMonitorsUsed);
  
      // Update state
      this.setState({
        userAccountInfo: data,
        isAdmin: data.IsUserTeamAdmin,
        userMonitorsTotal: data.UserMonitorsTotal,
        userStripeCustomer: data.IsUserCustomer,
        userStripePlan: data.UserStripePlan,
        teamMemberCount: data.TeamMemberCount,
        teamServicesCount: data.TeamServicesCount,
        userMonitorsUsed: data.UserMonitorsUsed
      }, () => {
        console.log("User account info updated: ", this.state);
      });
  
    } catch (error) {
      console.error("Error fetching user account info:", error);
    }
  };

  refreshUserStateFromStorage = () => {
    this.setState({
      loggedIn: localStorage.getItem("token") ? true : false,
      loggedInEmail: localStorage.getItem("loggedInEmail") || "",
      userFirstName: localStorage.getItem("userFirstName") || "",
      userLastName: localStorage.getItem("userLastName") || "",
      activationCode: localStorage.getItem("activationCode") || "",
      isAdmin: localStorage.getItem("isAdmin") === "true",
      userID: localStorage.getItem("userID") || "",
      // ... other states
    });
  };

  handleTokenExpiration() {
    console.log("Token expired. Logging out.");
    this.logoutClick();
  }

  logoutClick() {
    localStorage.removeItem("loggedInEmail");
    localStorage.removeItem("activationCode");
    localStorage.removeItem("isAdmin");
    localStorage.removeItem("token");
    localStorage.removeItem("userTeamID");
    localStorage.removeItem("userID");
    localStorage.removeItem("teamMockDataIndex");
    localStorage.removeItem("userMockDataIndex");
    localStorage.removeItem("isDemoMode");

    this.setState({ loggedIn: false, loggedInEmail: "" });

    this.stopRefreshSignalProcess();

    this.props.navigate("/home");
  }

  async requestPasswordReset() {
    var email = document.getElementById("resetEmail").value;

    const response = await fetch(API_URL + "api/User/RequestPasswordReset", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: `email=${encodeURIComponent(email)}`,
    });

    if (response.ok) {
      alert("Password reset email sent successfully!");
      this.showLoginClick();
    } else {
      alert("Failed to send password reset email!");
      console.log(response);
    }
  }

  handleDownload() {
    // Helper function to detect OS
    const getOS = () => {
      const userAgent = window.navigator.userAgent;
      const platform = window.navigator.platform;
      const macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"];
      const windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"];
      const iosPlatforms = ["iPhone", "iPad", "iPod"];
  
      if (macosPlatforms.indexOf(platform) !== -1) {
        return "Mac";
      } else if (windowsPlatforms.indexOf(platform) !== -1) {
        return "Windows";
      } else if (/Android/.test(userAgent)) {
        return "Android";
      } else if (iosPlatforms.indexOf(platform) !== -1 || 
                 (platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
        return "iOS";
      } else if (/Linux/.test(platform)) {
        return "Linux";
      }
  
      return "Unknown";
    };
  
    const os = getOS();
  
    // Download URLs
    const windowsDownloadUrl = "https://signaltuner.com/update/SignalTuner.exe";
    const macDownloadUrl = "https://signaltuner.com/update/macos/SignalTuner.dmg";
  
    // Redirect to appropriate download based on OS or show alert for unsupported OSes
    if (os === "Windows") {
      window.location.href = windowsDownloadUrl;
    } else if (os === "Mac") {
      window.location.href = macDownloadUrl;
    } else {
      alert("The SignalTuner client is only available for Windows and macOS. Please complete setup on your desktop device.");
    }
  }
  

  render() {
    const { loggedIn, loading } = this.state;

    if (loading) {
      return <div>Loading...</div>;
    }

    return (
      <div className="App">
        <div>
          <Navbar
            logoutClick={this.logoutClick}
            loggedIn={this.state.loggedIn}
            loggedInEmail={this.state.loggedInEmail}
            showLoginForm={this.state.showLoginForm}
            showLoginClick={() => this.showLoginClick()}
            showUserCreationForm={this.state.showUserCreationForm}
            showUserCreationClick={() => this.showUserCreationClick()}
            showForgotPasswordForm={this.state.showForgotPasswordForm}
            isDemoMode={this.state.isDemoMode}
          />
        </div>

        <div style={{ paddingTop: "70px" }}>
          <Routes>
            <Route
              path="/demo"
              element={
                <ProtectedRoute isLoggedIn={this.isLoggedIn()}>
                  <Demo isDemoMode={this.state.isDemoMode} toggleDemoMode={this.toggleDemoMode} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute isLoggedIn={this.isLoggedIn()}>
                  <Dashboard
                    signal={this.state.signal}
                    team={this.state.team}
                    teamServices={this.state.teamServices}
                    userID={this.state.userID}
                    isAdmin={this.state.isAdmin}
                    loggedInEmail={this.state.loggedInEmail}
                    activationCode={this.state.activationCode}
                    userHasSeenOnboarding={this.state.userHasSeenOnboarding}
                    logoutClick={() => this.logoutClick()}
                    setUserHasSeenOnboarding={this.setUserHasSeenOnboarding}
                    userAccountInfo={this.state.userAccountInfo}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              path="/signal"
              element={
                <ProtectedRoute isLoggedIn={this.isLoggedIn()}>
                  <Signal signal={this.state.signal} activationCode={this.state.activationCode} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/team/:memberId?"
              element={
                <ProtectedRoute isLoggedIn={this.isLoggedIn()}>
                  <Team
                    createTeam={createTeam}
                    sendInvitation={sendInvitation}
                    leaveTeam={this.leaveTeam}
                    removeTeamMember={this.removeTeamMember}
                    team={this.state.team}
                    userID={this.state.userID}
                    isAdmin={this.state.isAdmin}
                    refreshUserAccountInfo={this.refreshUserAccountInfo}
                    userAccountInfo={this.state.userAccountInfo}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              path="/services"
              element={
                <ProtectedRoute isLoggedIn={this.isLoggedIn()}>
                  <Services
                    teamServices={this.state.teamServices}
                    refreshTeamServiceSelections={this.refreshTeamServiceSelections}
                    removeServiceFromTeam={this.removeServiceFromTeam}
                    refreshUserAccountInfo={this.refreshUserAccountInfo}
                    userAccountInfo={this.state.userAccountInfo}
                    isAdmin={this.state.isAdmin}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              path="/settings"
              element={
                <ProtectedRoute isLoggedIn={this.isLoggedIn()}>
                  <Settings
                    refreshUserStateFromStorage={this.refreshUserStateFromStorage}
                    refreshUserAccountInfo={this.refreshUserAccountInfo}
                    userAccountInfo={this.state.userAccountInfo}
                    isAdmin={this.state.isAdmin}
                    team={this.state.team}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              path="/plans"
              element={
                <ProtectedRoute isLoggedIn={this.isLoggedIn()}>
                  <Plans />
                </ProtectedRoute>
              }
            />
            <Route path="/downloads" element={<Downloads loggedIn={this.state.loggedIn} />} />
            <Route path="/support" element={<Support sendSupportEmail={sendSupportEmail} />} />
            <Route path="/reset-password" element={<ResetPassword />} />

            <Route path="/invite" 
            element={<Invite 
            refreshUserAccountInfo={this.refreshUserAccountInfo} 
            refreshTeam={this.refreshTeam}/>}
            />
            <Route
              path="/"
              element={
                this.state.loggedIn ? (
                  <Navigate to="/dashboard" replace />
                ) : (
                  <Home
                    showLoginForm={this.state.showLoginForm}
                    showLoginClick={() => this.showLoginClick()}
                    showUserCreationForm={this.state.showUserCreationForm}
                    showUserCreationClick={() => this.showUserCreationClick()}
                    showForgotPasswordForm={this.state.showForgotPasswordForm}
                    showForgotPasswordClick={() => this.showForgotPasswordClick()}
                    loggedIn={this.state.loggedIn}
                    loginClick={() => this.loginClick()}
                    logoutClick={() => this.logoutClick()}
                    addUserClick={() => this.addUserClick()}
                    requestPasswordReset={() => this.requestPasswordReset()}
                    hideHomePageFormsClick={() => this.hideHomePageFormsClick()}
                  />
                )
              }
            />
            <Route
              path="/home"
              element={
                <Home
                  showLoginForm={this.state.showLoginForm}
                  showLoginClick={() => this.showLoginClick()}
                  showUserCreationForm={this.state.showUserCreationForm}
                  showUserCreationClick={() => this.showUserCreationClick()}
                  showForgotPasswordForm={this.state.showForgotPasswordForm}
                  showForgotPasswordClick={() => this.showForgotPasswordClick()}
                  loggedIn={this.state.loggedIn}
                  loginClick={() => this.loginClick()}
                  logoutClick={() => this.logoutClick()}
                  addUserClick={() => this.addUserClick()}
                  requestPasswordReset={() => this.requestPasswordReset()}
                  hideHomePageFormsClick={() => this.hideHomePageFormsClick()}
                  isDemoMode={this.state.isDemoMode}
                />
              }
            />
            <Route path="*" element={<Navigate to="/home" />} />
          </Routes>
        </div>
      </div>
    );
  }
}

export default withNavigation(App);
