import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Home.css";
import {
  RemoteWorkerSvg,
  BackgroundCurvedShapeSvg,
  HomeSection3BackgroundShapeSvg,
  WhiteDotGridSvg,
  HomeSection4BackgroundShapeSvg,
  HomeSection5BackgroundShapeSvg,
  HomeBannerCurveShapeSVG,
} from "./images/HomeSVG";
import {
  HomeSection2Text,
  HomeSection2BulletList,
  HomeSection3Headline,
  HomeSection3Text,
  HomeSection4Headline,
  HomeSection4Text,
  HomeSection5Headline,
  HomeSection5Text,
  HomeSection6Headline,
  HomeSection6Text,
  HomeSection6BannerText,
} from "./Text";

const Home = ({
  loggedIn,
  loginClick,
  addUserClick,
  showLoginForm,
  showLoginClick,
  showUserCreationForm,
  showUserCreationClick,
  showForgotPasswordForm,
  showForgotPasswordClick,
  logoutClick,
  requestPasswordReset,
  hideHomePageFormsClick,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  let renderSection;

  const handleCreateAccountClick = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);

    const email = document.getElementById("email").value;
    const password = document.getElementById("password").value;
    const result = await addUserClick();

    if (result === "success") {
      // After successful account creation, automatically log the user in
      loginClick(email, password);
    } else {
      // Handle account creation failure
      alert("Account creation failed!");
    }

    setIsSubmitting(false);
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        const email = document.getElementById("loginEmail").value;
        const password = document.getElementById("loginPassword").value;
        if (email && password) {
          loginClick(); // Trigger the login function when Enter is pressed and both fields are filled
        }
      }
    };

    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress); // Clean up the event listener
    };
  }, [loginClick]);

  if (!loggedIn && showLoginForm) {
    renderSection = (
      <div className="login-section">
        <h3 className="login-header">Login</h3>
        <input className="login-section-email-input" id="loginEmail" placeholder="Email" autoFocus />
        <input className="login-section-password-input" id="loginPassword" type="password" placeholder="Password" />
        <span className="forgot-password-text" onClick={() => showForgotPasswordClick()}>
          Forgot Password?
        </span>
        <button className="login-click-button" onClick={() => loginClick()}>
          Login
        </button>
        <p className="no-account-text">Don't have an account?</p>
        <button className="show-create-account-button" onClick={() => showUserCreationClick()}>
          Create New Account
        </button>
        <div className="login-image-container">
          <img src="https://signaltuner.com/images/usercreate.png" alt="Connection World" />
        </div>
        <button className="hide-home-page-forms-button" onClick={() => hideHomePageFormsClick()}>
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
            <path
              d="M22.8748 7.1375C22.7591 7.02162 22.6218 6.92969 22.4706 6.86696C22.3194 6.80423 22.1572 6.77195 21.9935 6.77195C21.8298 6.77195 21.6677 6.80423 21.5165 6.86696C21.3653 6.92969 21.2279 7.02162 21.1123 7.1375L14.9998 13.2375L8.88729 7.125C8.77156 7.00927 8.63418 6.91747 8.48297 6.85484C8.33177 6.79221 8.1697 6.75998 8.00604 6.75998C7.84238 6.75998 7.68032 6.79221 7.52911 6.85484C7.37791 6.91747 7.24052 7.00927 7.12479 7.125C7.00906 7.24073 6.91726 7.37812 6.85463 7.52932C6.792 7.68053 6.75977 7.84259 6.75977 8.00625C6.75977 8.16991 6.792 8.33197 6.85463 8.48318C6.91726 8.63439 7.00906 8.77177 7.12479 8.8875L13.2373 15L7.12479 21.1125C7.00906 21.2282 6.91726 21.3656 6.85463 21.5168C6.792 21.668 6.75977 21.8301 6.75977 21.9937C6.75977 22.1574 6.792 22.3195 6.85463 22.4707C6.91726 22.6219 7.00906 22.7593 7.12479 22.875C7.24052 22.9907 7.37791 23.0825 7.52911 23.1452C7.68032 23.2078 7.84238 23.24 8.00604 23.24C8.1697 23.24 8.33177 23.2078 8.48297 23.1452C8.63418 23.0825 8.77156 22.9907 8.88729 22.875L14.9998 16.7625L21.1123 22.875C21.228 22.9907 21.3654 23.0825 21.5166 23.1452C21.6678 23.2078 21.8299 23.24 21.9935 23.24C22.1572 23.24 22.3193 23.2078 22.4705 23.1452C22.6217 23.0825 22.7591 22.9907 22.8748 22.875C22.9905 22.7593 23.0823 22.6219 23.1449 22.4707C23.2076 22.3195 23.2398 22.1574 23.2398 21.9937C23.2398 21.8301 23.2076 21.668 23.1449 21.5168C23.0823 21.3656 22.9905 21.2282 22.8748 21.1125L16.7623 15L22.8748 8.8875C23.3498 8.4125 23.3498 7.6125 22.8748 7.1375Z"
              fill="#B4B9BD"
            />
          </svg>
        </button>
      </div>
    );
  } else if (showForgotPasswordForm) {
    renderSection = (
      <div className="forgot-password-section">
        <h3 className="forgot-password-header">Reset Password</h3>
        <input className="forgot-password-email-input" id="resetEmail" placeholder="Email" />
        <p className="password-reset-instructions">If your email address is registered with us, we’ll send you an email to allow you to reset your password.</p>
        <button className="forgot-password-click-button" onClick={() => requestPasswordReset()}>
          Send Password Reset Email
        </button>
        <button className="hide-home-page-forms-button" onClick={() => hideHomePageFormsClick()}>
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
            <path
              d="M22.8748 7.1375C22.7591 7.02162 22.6218 6.92969 22.4706 6.86696C22.3194 6.80423 22.1572 6.77195 21.9935 6.77195C21.8298 6.77195 21.6677 6.80423 21.5165 6.86696C21.3653 6.92969 21.2279 7.02162 21.1123 7.1375L14.9998 13.2375L8.88729 7.125C8.77156 7.00927 8.63418 6.91747 8.48297 6.85484C8.33177 6.79221 8.1697 6.75998 8.00604 6.75998C7.84238 6.75998 7.68032 6.79221 7.52911 6.85484C7.37791 6.91747 7.24052 7.00927 7.12479 7.125C7.00906 7.24073 6.91726 7.37812 6.85463 7.52932C6.792 7.68053 6.75977 7.84259 6.75977 8.00625C6.75977 8.16991 6.792 8.33197 6.85463 8.48318C6.91726 8.63439 7.00906 8.77177 7.12479 8.8875L13.2373 15L7.12479 21.1125C7.00906 21.2282 6.91726 21.3656 6.85463 21.5168C6.792 21.668 6.75977 21.8301 6.75977 21.9937C6.75977 22.1574 6.792 22.3195 6.85463 22.4707C6.91726 22.6219 7.00906 22.7593 7.12479 22.875C7.24052 22.9907 7.37791 23.0825 7.52911 23.1452C7.68032 23.2078 7.84238 23.24 8.00604 23.24C8.1697 23.24 8.33177 23.2078 8.48297 23.1452C8.63418 23.0825 8.77156 22.9907 8.88729 22.875L14.9998 16.7625L21.1123 22.875C21.228 22.9907 21.3654 23.0825 21.5166 23.1452C21.6678 23.2078 21.8299 23.24 21.9935 23.24C22.1572 23.24 22.3193 23.2078 22.4705 23.1452C22.6217 23.0825 22.7591 22.9907 22.8748 22.875C22.9905 22.7593 23.0823 22.6219 23.1449 22.4707C23.2076 22.3195 23.2398 22.1574 23.2398 21.9937C23.2398 21.8301 23.2076 21.668 23.1449 21.5168C23.0823 21.3656 22.9905 21.2282 22.8748 21.1125L16.7623 15L22.8748 8.8875C23.3498 8.4125 23.3498 7.6125 22.8748 7.1375Z"
              fill="#B4B9BD"
            />
          </svg>
        </button>
        <div className="forgot-password-image-container">
          <img src="https://signaltuner.com/images/usercreate.png" alt="Connection World" />
        </div>
      </div>
    );
  } else if (showUserCreationForm) {
    renderSection = (
      <div className="user-creation-section">
        <div className="left-section">
          <h2 className="connection-world-header">Keep your team connected </h2>
          <p className="custom-paragraph">From anywhere at anytime</p>

          <div className="image-container">
            <svg className="background-svg" xmlns="http://www.w3.org/2000/svg" width="300" height="223" viewBox="0 0 488 223" fill="none">
              <path
                d="M150.5 2.50021C73.5 -10.9998 1.5 44.5002 1.5 44.5002L-77.5 194.5L71 275L543 263.5L483 88.5002C483 88.5002 356 134 300 116C244 98.0004 227.5 16.0002 150.5 2.50021Z"
                fill="#DDEFFD"
              />
            </svg>
            <img src="https://signaltuner.com/images/usercreate.png" alt="Connection World" />
          </div>
        </div>
        <div className="right-section">
          <h3 className="create-account-header">Create Account</h3>
          <input className="user-creation-first-name-input" id="firstName" placeholder="First name" autoFocus/>
          <input className="user-creation-last-name-input" id="lastName" placeholder="Last name" />
          <input className="user-creation-email-input" id="email" placeholder="Email" />
          <input className="user-creation-phone-input" id="phone" placeholder="Phone number (optional)" />
          <label className="user-creation-smsAlertsCheckbox-label">
            <input className="user-creation-smsAlertsCheckbox" type="checkbox" id="smsAlertsCheckbox" /> Opt-in to SMS alerts (optional)
          </label>
          <input className="user-creation-password-input" id="password" type="password" placeholder="Password" />
          <input className="user-creation-password-confirm-input" id="confirmPassword" type="password" placeholder="Confirm Password" />
          <button className="create-account-button" onClick={handleCreateAccountClick} disabled={isSubmitting}>
            {isSubmitting ? "Creating Account..." : "Create Account"}
          </button>
          <p className="account-existence-query">Already have an account?</p>
          <button className="show-login-button" onClick={() => showLoginClick()}>
            Login
          </button>
          <button className="hide-home-page-creation-form-button" onClick={() => hideHomePageFormsClick()}>
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
              <path
                d="M22.8748 7.1375C22.7591 7.02162 22.6218 6.92969 22.4706 6.86696C22.3194 6.80423 22.1572 6.77195 21.9935 6.77195C21.8298 6.77195 21.6677 6.80423 21.5165 6.86696C21.3653 6.92969 21.2279 7.02162 21.1123 7.1375L14.9998 13.2375L8.88729 7.125C8.77156 7.00927 8.63418 6.91747 8.48297 6.85484C8.33177 6.79221 8.1697 6.75998 8.00604 6.75998C7.84238 6.75998 7.68032 6.79221 7.52911 6.85484C7.37791 6.91747 7.24052 7.00927 7.12479 7.125C7.00906 7.24073 6.91726 7.37812 6.85463 7.52932C6.792 7.68053 6.75977 7.84259 6.75977 8.00625C6.75977 8.16991 6.792 8.33197 6.85463 8.48318C6.91726 8.63439 7.00906 8.77177 7.12479 8.8875L13.2373 15L7.12479 21.1125C7.00906 21.2282 6.91726 21.3656 6.85463 21.5168C6.792 21.668 6.75977 21.8301 6.75977 21.9937C6.75977 22.1574 6.792 22.3195 6.85463 22.4707C6.91726 22.6219 7.00906 22.7593 7.12479 22.875C7.24052 22.9907 7.37791 23.0825 7.52911 23.1452C7.68032 23.2078 7.84238 23.24 8.00604 23.24C8.1697 23.24 8.33177 23.2078 8.48297 23.1452C8.63418 23.0825 8.77156 22.9907 8.88729 22.875L14.9998 16.7625L21.1123 22.875C21.228 22.9907 21.3654 23.0825 21.5166 23.1452C21.6678 23.2078 21.8299 23.24 21.9935 23.24C22.1572 23.24 22.3193 23.2078 22.4705 23.1452C22.6217 23.0825 22.7591 22.9907 22.8748 22.875C22.9905 22.7593 23.0823 22.6219 23.1449 22.4707C23.2076 22.3195 23.2398 22.1574 23.2398 21.9937C23.2398 21.8301 23.2076 21.668 23.1449 21.5168C23.0823 21.3656 22.9905 21.2282 22.8748 21.1125L16.7623 15L22.8748 8.8875C23.3498 8.4125 23.3498 7.6125 22.8748 7.1375Z"
                fill="#B4B9BD"
              />
            </svg>
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="home-container">
      {renderSection}
      <div className="home-section-1">
        <BackgroundCurvedShapeSvg className="background-curved-shape-svg" />
        <RemoteWorkerSvg className="home-remote-worker-svg" />
        <div className="home-section-1-text-container">
          <p className="home-headline">
            <span>Remote device monitoring.</span>
            <span>SaaS monitoring.</span>
            <span>Let's stay connected.</span>
          </p>
          <p className="home-try-free">
            Sign up for a <span className="home-try-free-bold">FREE</span> SignalTuner account today!
          </p>
          <div className="home-section-1-button-container">
            {!loggedIn && (
              <>
                <button className="home-login-button" onClick={() => showLoginClick()}>
                  Login
                </button>
                <button className="home-signup-button" onClick={() => showUserCreationClick()}>
                  Sign up
                </button>
              </>
            )}
            {loggedIn && (
              <Link to="/dashboard" className="home-dashboard-button">
                Go to dashboard
              </Link>
            )}
          </div>
        </div>
      </div>
      <div className="home-section-2">
        <p className="home-section-2-headline"> SignalTuner monitors your team's devices and services.</p>
        <div className="home-section-2-content-container">
          <div className="home-section-2-text-container">
            <HomeSection2Text className="home-section-2-paragraph" />
            <HomeSection2BulletList className="home-section-2-bullet-list" />
          </div>
          <img className="home-section-2-image" src="https://www.signaltuner.com/images/dashboard.png" />
        </div>
      </div>
      <div className="home-section-3">
        <HomeSection3BackgroundShapeSvg className="home-section-3-background-shape-svg" />
        <WhiteDotGridSvg className="home-section-3-white-dot-grid-svg" />
        <img className="home-section-3-image" src="https://www.signaltuner.com/images/wifisignal.png" />
        <div className="home-section-3-text-container">
          <HomeSection3Headline className="home-section-3-headline" />
          <HomeSection3Text className="home-section-3-text" />
        </div>
      </div>
      <div className="home-section-4">
        <div className="home-section-4-text-container">
          <HomeSection4Headline className="home-section-4-headline" />
          <HomeSection4Text className="home-section-4-text" />
        </div>
        <img className="home-section-4-image" src="https://www.signaltuner.com/images/services.png" />
        <HomeSection4BackgroundShapeSvg className="home-section-4-background-shape-svg" />
        <WhiteDotGridSvg className="home-section-4-white-dot-grid-svg" />
      </div>
      <div className="home-section-5">
        <HomeSection5BackgroundShapeSvg className="home-section-5-background-shape-svg" />
        <WhiteDotGridSvg className="home-section-5-white-dot-grid-svg" />
        <img className="home-section-5-image" src="https://www.signaltuner.com/images/email.png" />
        <div className="home-section-5-text-container">
          <HomeSection5Headline className="home-section-5-headline" />
          <HomeSection5Text className="home-section-5-text" />
        </div>
      </div>
      <div className="home-section-6">
        <div className="home-section-6-text-container">
          <HomeSection6Headline className="home-section-6-headline" />
          <HomeSection6Text className="home-section-6-text" />
          <div className="home-section-6-banner">
            <HomeSection6BannerText className="home-section-6-banner-text" />
            <HomeBannerCurveShapeSVG className="home-section-6-banner-curve-svg" />
            <button className="home-section-6-banner-button" onClick={() => showUserCreationClick()}>
              Sign up
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
