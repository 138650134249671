import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import "./Services.css";
import config from "./config";
import {
  RemoveServiceButtonSvg,
  ServiceDetailsButtonIconSvg,
  ServiceDetailsButtonArrowSvg,
  MonitorButtonArrowSvg,
  NoIssueStatusIconSvg,
  IncidentStatusIconSvg,
  OutageStatusIconSvg,
  ServiceDetailsCloseButtonSvg,
} from "./images/ServicesSVG";

const Services = ({ refreshTeamServiceSelections, removeServiceFromTeam, teamServices, refreshUserAccountInfo, userAccountInfo, isAdmin }) => {
  const [services, setServices] = useState([]); // Local state to store services
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [isAdding, setIsAdding] = useState(false);

  // Retrieve query parameters from the URL using useLocation
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialServiceName = queryParams.get("serviceDisplayName");
  const initialDescription = queryParams.get("description");
  const initialServiceId = queryParams.get("serviceId");

  const [incidents, setIncidents] = useState([]);

  const [modalContent, setModalContent] = useState({
    name: initialServiceName || "",
    description: initialDescription || "",
  });

  const [isModalOpen, setIsModalOpen] = useState(Boolean(initialServiceName) || Boolean(initialDescription));

  //Pass serviceName directly
  useEffect(() => {
    if ((initialServiceId, initialServiceName)) {
      handleOpenModalExternal(initialServiceId, initialServiceName);
    }
  }, [initialServiceId, initialServiceName]);

  useEffect(() => {
    refreshUserAccountInfo();
  }, [refreshUserAccountInfo]);

  const getStatusStyle = (status) => {
    switch (status) {
      case "Operational":
        return { color: "#3BB537" };
      case "Active incident":
        return { color: "#F9AE00" };
      case "Outage":
        return { color: "#EC2F3E" };
      default:
        return {};
    }
  };

  const renderStatusIcon = (status) => {
    switch (status) {
      case "Operational":
        return (
          <div className="status-icon" style={{ background: "#3BB537", width: "18px", height: "18px" }}>
            <NoIssueStatusIconSvg />
          </div>
        );
      case "Active incident":
        return (
          <div className="status-icon" style={{ background: "#F9AE00", width: "18px", height: "18px" }}>
            <IncidentStatusIconSvg />
          </div>
        );
      case "Outage":
        return (
          <div className="status-icon" style={{ background: "#EC2F3E", width: "18px", height: "18px" }}>
            <OutageStatusIconSvg />
          </div>
        );
      default:
        return null;
    }
  };
  const navigate = useNavigate();

  const API_URL = config.API_URL;

  // Fetch team service selections
  useEffect(() => {
    const fetchTeamServiceSelections = async () => {
      try {
        setLoading(true);
        const userID = localStorage.getItem("userID");
        const token = localStorage.getItem("token");

        const response = await axios.get(`${API_URL}api/User/GetServicesAndSelections/${userID}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setServices(teamServices);
        setError("");
      } catch (err) {
        setError("Failed to fetch service selections");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchTeamServiceSelections();
  }, [teamServices]);

  const handleAddServiceToTeam = async (serviceId) => {
    if (isAdding) return; // Prevent the function from running if it's already active
  
    try {
      setIsAdding(true); // Set the flag to true at the start
  
      const userID = localStorage.getItem("userID");
      const token = localStorage.getItem("token");
      const teamId = localStorage.getItem("userTeamID");
      const isAdmin = localStorage.getItem("isAdmin");
      console.log("isAdmin: ", isAdmin);
  
      if (userAccountInfo.UserMonitorsTotal <= userAccountInfo.UserMonitorsUsed) {
        if (userAccountInfo.IsUserCustomer && userAccountInfo.UserStripePlan && userAccountInfo.UserStripePlan !== "cancelled") {
          window.open("https://billing.stripe.com/p/login/8wMdUQfLt1PjdpK5kk", "_blank");
        } else {
          navigate("/plans"); // Navigate to the "Plans" page if the user is not a customer
        }
      } else {
        if (isAdmin === 'false') {
          // Send service monitor request if the user is not an admin
          const response = await axios.post(
            `${API_URL}api/User/RequestServiceMonitor`,
            {
              userId: userID,
              teamId: teamId,
              serviceId: serviceId,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
  
          if (response.status === 200) {
            alert("A service monitor request has been sent to your team administrator.");
          }
        } else {
          // Add service to team if the user is an admin
          await axios.post(
            `${API_URL}api/User/AddTeamServiceSelection`,
            {
              UserId: userID,
              TeamId: teamId,
              ServiceId: serviceId,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
  
          refreshTeamServiceSelections();
          refreshUserAccountInfo();
        }
      }
    } catch (err) {
      console.error("Error adding service to team:", err);
      // Handle error
    } finally {
      setIsAdding(false); // Reset the flag to false when done
    }
  };
  // const handleAddServiceToTeam = async (serviceId) => {
  //   if (isAdding) return; // Prevent the function from running if it's already active

  //   try {
  //     setIsAdding(true); // Set the flag to true at the start

  //     const userID = localStorage.getItem("userID");
  //     const token = localStorage.getItem("token");
  //     const teamId = localStorage.getItem("userTeamID");
  //     const isAdmin = localStorage.getItem("isAdmin");

  //     if (userAccountInfo.UserMonitorsTotal <= userAccountInfo.UserMonitorsUsed) {
  //       if (userAccountInfo.IsUserCustomer && userAccountInfo.UserStripePlan && userAccountInfo.UserStripePlan !== "cancelled") {
  //         window.open("https://billing.stripe.com/p/login/8wMdUQfLt1PjdpK5kk", "_blank");
  //       } else {
  //         navigate("/plans"); // Navigate to the "Plans" page if the user is not a customer
  //       }
  //     } else {
  //       await axios.post(
  //         `${API_URL}api/User/AddTeamServiceSelection`,
  //         {
  //           UserId: userID,
  //           TeamId: teamId,
  //           ServiceId: serviceId,
  //         },
  //         {
  //           headers: { Authorization: `Bearer ${token}` },
  //         }
  //       );

  //       refreshTeamServiceSelections();
  //       refreshUserAccountInfo();
  //     }
  //   } catch (err) {
  //     console.error("Error adding service to team:", err);
  //     // Handle error
  //   } finally {
  //     setIsAdding(false); // Reset the flag to false when done
  //   }
  // };

  const handleRemoveServiceFromTeam = async (serviceId) => {
    try {
      console.log("ServiceId: ", serviceId);
      await removeServiceFromTeam(serviceId);
      await refreshTeamServiceSelections();
      await refreshUserAccountInfo();
    } catch (err) {
      console.error("Error removing  service from team:", err);
      // Handle error
    }
  };

  // Filter services based on selection status
  const selectedServices = services.filter((service) => service.IsSelected);
  const nonSelectedServices = services.filter((service) => !service.IsSelected);

  //Service details modal start

  const handleOpenModalFromPage = async (serviceId, serviceName) => {
    try {
      setLoading(true); // Assuming setLoading is already defined to manage loading state
      const token = localStorage.getItem("token");

      const response = await axios.get(`${API_URL}api/User/GetServiceIncidents/${serviceId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      // Assuming the API returns an array of incidents directly
      console.log(response.data);
      let incidentsData = response.data;

      // Sorting incidentsData by PublishDate and Status
      incidentsData.sort((a, b) => {
        // Sort by Status first: 'Active' incidents come before 'Resolved'
        if (a.Status === "Active" && b.Status !== "Active") {
          return -1;
        } else if (a.Status !== "Active" && b.Status === "Active") {
          return 1;
        }

        // If both have the same Status, sort by PublishDate in descending order
        const dateA = new Date(a.PublishDate);
        const dateB = new Date(b.PublishDate);
        return dateB - dateA; // Sorts from most recent to least recent
      });

      setIncidents(incidentsData);
      setModalContent({ name: serviceName, description: `Incidents for ${serviceName}` });
      setIsModalOpen(true);
    } catch (error) {
      console.error("Failed to fetch service incidents:", error);
      setError("Failed to fetch service incidents"); // Assuming setError is already defined to manage error state
    } finally {
      setLoading(false);
    }
  };

  const handleOpenModalExternal = async (serviceId, serviceName) => {
    try {
      setIsModalOpen(true);
      const token = localStorage.getItem("token");

      const response = await axios.get(`${API_URL}api/User/GetServiceIncidents/${serviceId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      let incidentsData = response.data;

      // Sorting incidentsData by PublishDate and Status
      incidentsData.sort((a, b) => {
        // Sort by Status first: 'Active' incidents come before 'Resolved'
        if (a.Status === "Active" && b.Status !== "Active") {
          return -1;
        } else if (a.Status !== "Active" && b.Status === "Active") {
          return 1;
        }

        // If both have the same Status, sort by PublishDate in descending order
        const dateA = new Date(a.PublishDate);
        const dateB = new Date(b.PublishDate);
        return dateB - dateA; // Sorts from most recent to least recent
      });

      setIncidents(incidentsData);
      setModalContent({ name: serviceName, description: `Incidents for ${serviceName}` });
    } catch (error) {
      console.error("Failed to fetch service incidents:", error);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    // Clear query parameters from the URL
    navigate("/services", { replace: true });
  };

  return (
    <div>
      {isModalOpen && (
        <div className="services-modal-overlay">
          <div className="services-modal-content">
            <div onClick={handleCloseModal} style={{ cursor: "pointer" }}>
              <ServiceDetailsCloseButtonSvg className="service-details-close-button-svg" />
            </div>

            <h2 className="services-modal-headline" style={{ fontWeight: "bold", textDecoration: "underline" }}>
              {modalContent.name}
            </h2>
            <p className="services-modal-subhead" style={{ fontWeight: "bold", textDecoration: "underline" }}>
              Incidents
            </p>
            <div className="incidents">
              {incidents.length ? (
                incidents.map((incident, index) => (
                  <div key={index} className="incident">
                    <h3 style={{ color: incident.Status === "Active" ? "#F9AE00" : "#3BB537" }}>{incident.Title}</h3>
                    <p className="services-modal-updated-label">Updated: {new Date(incident.PublishDate).toLocaleString()}</p>
                    <a className="services-modal-link" href={incident.Link} target="_blank" rel="noopener noreferrer">
                      {modalContent.name} incident report
                    </a>
                    <p className="services-modal-status-label">
                      Status: <span style={{ color: incident.Status === "Active" ? "#F9AE00" : "#3BB537" }}>{incident.Status}</span>
                    </p>
                    <div dangerouslySetInnerHTML={{ __html: incident.Description }} className="services-modal-incident-description" />
                  </div>
                ))
              ) : (
                <p>No incidents to display</p>
              )}
            </div>
            <button className="services-modal-close-button" onClick={handleCloseModal}>
              Close
            </button>
          </div>
        </div>
      )}

      <div className="services-container">
        <div className="services-info-container">
          {selectedServices ? (
            <h4 className="services-monitored-count">Monitored services: {selectedServices.length} </h4>
          ) : (
            <h4 className="services-monitored-count">Loading services data...</h4>
          )}
          {isAdmin &&
            (userAccountInfo ? (
              <h4 className="services-monitors-available-count">Available monitors: {userAccountInfo.UserMonitorsTotal - userAccountInfo.UserMonitorsUsed}</h4>
            ) : (
              <h4 className="services-monitors-available-count">Loading services data...</h4>
            ))}
        </div>
        {selectedServices.length > 0 && <h2 className="services-monitored-headline">Monitored</h2>}
        <div className="services-grid">
          {selectedServices.map((service, index) => (
            <div className="service-card" key={index} onClick={() => handleOpenModalFromPage(service.ServiceId, service.ServiceDisplayName)}>
              {isAdmin && (
                <button
                  className="remove-service-button"
                  onClick={(e) => {
                    e.stopPropagation(); // This stops the click event from bubbling up to the parent div
                    handleRemoveServiceFromTeam(service.ServiceId);
                  }}
                >
                  <RemoveServiceButtonSvg />
                </button>
              )}
              <div className="service-container">
                <img
                  className="service-icon"
                  src={`https://www.signaltuner.com/images/serviceicons/${service.ServiceName}.png`}
                  alt={`${service.ServiceDisplayName} Icon`}
                />
                <div className="service-name">{service.ServiceDisplayName}</div>
                <div className="status-details-container">
                  <div className="status-content">
                    {renderStatusIcon(service.ServiceCurrentStatus)}
                    <div className="service-status" style={getStatusStyle(service.ServiceCurrentStatus)}>
                      {service.ServiceCurrentStatus}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="services-container">
        <h2 className="services-available-headline">Available Services</h2>
        <div className="services-grid">
          {nonSelectedServices.map((service, index) => (
            <div className="available-service-card" key={index}>
              <div className="service-container">
                <img
                  className="service-icon"
                  src={`https://www.signaltuner.com/images/serviceicons/${service.ServiceName}.png`}
                  alt={`${service.ServiceName} Icon`}
                />
                <div className="service-name">{service.ServiceDisplayName}</div>
                {!service.IsSelected && (
                  <div className="monitor-button-div">
                    <button className="monitor-button" onClick={() => handleAddServiceToTeam(service.ServiceId)}>
                      <MonitorButtonArrowSvg />
                      {isAdmin ? "Monitor" : "Request"}
                    </button>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
