import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import "./Downloads.css"; // Import the CSS file

const Downloads = ({ loggedIn }) => {
  // Retrieve the activation code from local storage
  const navigate = useNavigate();
  const activationCode = localStorage.getItem("activationCode") || "Not available";
  const [displayText, setDisplayText] = useState(activationCode);

  const handleActivationCodeClick = () => {

    if (!loggedIn) {
      navigate('/home'); // Navigate to /home if not logged in
      return; // Exit the function early
    }

    navigator.clipboard
      .writeText(activationCode)
      .then(() => {
        setDisplayText("Copied");

        setTimeout(() => {
          setDisplayText(activationCode);
        }, 3000);
      })
      .catch((err) => {
        console.error("Failed to copy activation code: ", err);
        // Handle any errors (e.g., clipboard permissions denied)
      });
  };

  function handleDownload() {
    // Helper function to detect OS
    const getOS = () => {
      const userAgent = window.navigator.userAgent;
      const platform = window.navigator.platform;
      const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
      const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  
      if (macosPlatforms.indexOf(platform) !== -1) {
        return 'Mac';
      } else if (windowsPlatforms.indexOf(platform) !== -1) {
        return 'Windows';
      } else if (/Android/.test(userAgent)) {
        return 'Android';
      } else if (!os && /Linux/.test(platform)) {
        return 'Linux';
      }
  
      return 'Unknown';
    };
  
    const os = getOS();
  
    // Download URLs
    const windowsDownloadUrl = 'https://signaltuner.com/update/SignalTuner.exe';
    const macDownloadUrl = 'https://signaltuner.com/update/macos/SignalTuner.dmg';
  
    // Redirect to appropriate download based on OS
    if (os === 'Windows') {
      window.location.href = windowsDownloadUrl;
    } else if (os === 'Mac') {
      window.location.href = macDownloadUrl;
    } else {
      // Optionally, redirect to a generic download page or display an error for unsupported OSes
      alert('Your operating system is not supported for direct download. Please visit our website for more information.');
    }
  }

  return (
    <div className="downloads-container">
      {/* Left Column */}
      <div className="downloads-container-left-column">
        <p className="downloads-container-left-column-title">SignalTuner desktop client</p>
        <p className="downloads-container-left-column-CTA">Download, install, and enter your activation code to synch with your team</p>
          <button className="downloads-container-left-column-button" type="button" onClick={handleDownload}>
            Download
          </button>
        <p className="downloads-container-left-column-activation-label">Your activation code:</p>
        <p className="downloads-container-left-column-activation-code" onClick={handleActivationCodeClick}>
          {loggedIn ? <span className="activation-code-inner-text">{displayText}</span> : "Log in"}
        </p>
      </div>

      {/* Right Column */}
      <div className="downloads-container-right-column">
        <img src="https://www.signaltuner.com/images/wifisignal.png" alt="WiFi Signal" />
      </div>
    </div>
  );
};

export default Downloads;
