import React from "react";

export const Gauge100 = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="85" height="70" viewBox="0 0 110 90" fill="none">
      <path
        d="M18.8746 84.5C17.3302 82.6052 15.9338 80.5851 14.7029 78.4573C10.789 71.6913 8.54889 63.8359 8.54889 55.4573C8.54889 30.0522 29.1438 9.45728 54.5489 9.45728C79.954 9.45728 100.549 30.0522 100.549 55.4573C100.549 63.8359 98.3088 71.6913 94.3949 78.4573C93.238 80.4573 91.9348 82.3621 90.5 84.157"
        stroke="url(#paint0_linear_18_567)"
        strokeWidth="17"
      />
      <defs>
        <linearGradient id="paint0_linear_18_567" x1="55" y1="8.99999" x2="100.388" y2="15.1924" gradientUnits="userSpaceOnUse">
          <stop stopColor="#A6E0A4" />
          <stop offset="1" stopColor="#3BB537" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const Gauge095 = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="100" height="70" viewBox="0 0 110 90" fill="none">
      <path
        d="M18.8746 84.0427C17.3302 82.148 15.9338 80.1279 14.7029 78C10.789 71.234 8.54889 63.3786 8.54889 55C8.54889 29.5949 29.1438 9 54.5489 9C79.954 9 100.549 29.5949 100.549 55C100.549 62.3659 98.8176 69.3275 95.7397 75.5"
        stroke="url(#paint0_linear_18_572)"
        strokeWidth="17"
      />
      <defs>
        <linearGradient id="paint0_linear_18_572" x1="60" y1="9.00001" x2="103.662" y2="17.352" gradientUnits="userSpaceOnUse">
          <stop stopColor="#A6E0A4" />
          <stop offset="1" stopColor="#3BB537" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const Gauge090 = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="90" height="70" viewBox="0 0 110 90" fill="none">
      <path
        d="M18.8746 84.0427C17.3302 82.148 15.9338 80.1279 14.7029 78C10.789 71.234 8.54889 63.3786 8.54889 55C8.54889 29.5949 29.1438 9 54.5489 9C79.954 9 100.549 29.5949 100.549 55C100.549 58.6124 100.132 62.1275 99.3451 65.5"
        stroke="url(#paint0_linear_18_574)"
        strokeWidth="17"
      />
      <defs>
        <linearGradient id="paint0_linear_18_574" x1="48.5" y1="8.99999" x2="95.6483" y2="12.0661" gradientUnits="userSpaceOnUse">
          <stop stopColor="#A6E0A4" />
          <stop offset="1" stopColor="#3BB537" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const Gauge085 = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="110" height="70" viewBox="0 0 110 90" fill="none">
      <path
        d="M100.549 55C100.549 29.5949 79.954 9 54.5489 9C29.1438 9 8.54889 29.5949 8.54889 55C8.54889 63.3786 10.789 71.234 14.7029 78C15.9338 80.1279 17.3302 82.148 18.8746 84.0427"
        stroke="url(#paint0_linear_18_576)"
        strokeWidth="17"
      />
      <defs>
        <linearGradient id="paint0_linear_18_576" x1="39.5" y1="12" x2="87.9981" y2="10.0198" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FADD9A" />
          <stop offset="1" stopColor="#F2AA02" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const Gauge080 = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="90" height="70" viewBox="0 0 110 90" fill="none">
      <path
        d="M18.8746 84.0427C17.3302 82.148 15.9338 80.1279 14.7029 78C10.789 71.234 8.54889 63.3786 8.54889 55C8.54889 29.5949 29.1438 9 54.5489 9C76.5863 9 95.0043 24.4968 99.5 45.1874"
        stroke="url(#paint0_linear_18_578)"
        strokeWidth="17"
      />
      <defs>
        <linearGradient id="paint0_linear_18_578" x1="32" y1="16" x2="80.9335" y2="9.35981" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FADD9A" />
          <stop offset="1" stopColor="#F2AA02" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const Gauge075 = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="90" height="70" viewBox="0 0 110 90" fill="none">
      <path
        d="M18.8746 84.0427C17.3302 82.148 15.9338 80.1279 14.7029 78C10.789 71.234 8.54889 63.3786 8.54889 55C8.54889 29.5949 29.1438 9 54.5489 9C72.588 9 88.202 19.3837 95.7397 34.5"
        stroke="url(#paint0_linear_18_580)"
        strokeWidth="17"
      />
      <defs>
        <linearGradient id="paint0_linear_18_580" x1="28.5" y1="19" x2="77.1158" y2="9.84151" gradientUnits="userSpaceOnUse">
          <stop stopColor="#fccbb6" />
          <stop offset="1" stopColor="#F6976E" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const Gauge070 = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="90" height="70" viewBox="0 0 110 90" fill="none">
      <path
        d="M18.8746 84.0427C17.3302 82.148 15.9338 80.1279 14.7029 78C10.789 71.234 8.54889 63.3786 8.54889 55C8.54889 29.5949 29.1438 9 54.5489 9C68.9602 9 81.8236 15.6271 90.2579 26"
        stroke="url(#paint0_linear_18_582)"
        strokeWidth="17"
      />
      <defs>
        <linearGradient id="paint0_linear_18_582" x1="24.5" y1="20.5" x2="73.6391" y2="9.04815" gradientUnits="userSpaceOnUse">
          <stop stopColor="#fccbb6" />
          <stop offset="1" stopColor="#F6976E" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const Gauge065 = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="90" height="70" viewBox="0 0 110 90" fill="none">
      <path
        d="M18.8746 84.0427C17.3302 82.148 15.9338 80.1279 14.7029 78C10.789 71.234 8.54888 63.3786 8.54888 55C8.54888 29.5949 29.1438 9 54.5489 9C65.6613 9 75.8533 12.9403 83.8041 19.5"
        stroke="url(#paint0_linear_18_584)"
        strokeWidth="17"
      />
      <defs>
        <linearGradient id="paint0_linear_18_584" x1="18" y1="28" x2="65.6625" y2="11.2866" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFC9CE" />
          <stop offset="1" stopColor="#EC2F3E" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const Gauge060 = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="90" height="70" viewBox="0 0 110 90" fill="none">
      <path
        d="M18.8746 84.0427C17.3302 82.148 15.9338 80.1279 14.7029 78C10.789 71.234 8.54889 63.3786 8.54889 55C8.54889 29.5949 29.1438 9 54.5489 9C62.0948 9 69.2164 10.817 75.5 14.0373"
        stroke="url(#paint0_linear_18_586)"
        strokeWidth="17"
      />
      <defs>
        <linearGradient id="paint0_linear_18_586" x1="14" y1="31" x2="61.4964" y2="11.5384" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFC9CE" />
          <stop offset="1" stopColor="#EC2F3E" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const Gauge055 = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="90" height="70" viewBox="0 0 110 90" fill="none">
      <path
        d="M18.8746 84.0427C17.3302 82.148 15.9338 80.1279 14.7029 78C10.789 71.234 8.54889 63.3786 8.54889 55C8.54889 29.5949 29.1438 9 54.5489 9C58.3226 9 61.9903 9.45443 65.5 10.3115"
        stroke="url(#paint0_linear_18_588)"
        strokeWidth="17"
      />
      <defs>
        <linearGradient id="paint0_linear_18_588" x1="10" y1="43" x2="14.4855" y2="7.70005" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFC9CE" />
          <stop offset="1" stopColor="#EC2F3E" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const Gauge050 = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="90" height="70" viewBox="0 0 110 90" fill="none">
      <path
        d="M54.5489 9.45731C29.1438 9.45731 8.54889 30.0522 8.54889 55.4573C8.54889 63.8359 10.789 71.6913 14.7029 78.4573C15.9338 80.5852 17.3302 82.6053 18.8746 84.5"
        stroke="#F4F4F4"
        strokeWidth="17"
      />
      <path
        d="M54.5489 9.45731C29.1438 9.45731 8.54889 30.0522 8.54889 55.4573C8.54889 63.8359 10.789 71.6913 14.7029 78.4573C15.9338 80.5852 17.3302 82.6053 18.8746 84.5"
        stroke="url(#paint0_linear_18_565)"
        strokeWidth="17"
      />
      <defs>
        <linearGradient id="paint0_linear_18_565" x1="9.72096" y1="43.4573" x2="15.2277" y2="8.4537" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFC9CE" />
          <stop offset="1" stopColor="#EC2F3E" />
        </linearGradient>
      </defs>
    </svg>
  );
};

// export const Gauge045 = ({ className }) => {
//   return (
//     <svg className={className} xmlns="http://www.w3.org/2000/svg" width="90" height="70" viewBox="0 0 110 90" fill="none">
//       <path
//         d="M19.5 82.1571C18.0652 80.3621 16.762 78.4573 15.6051 76.4573C11.6912 69.6913 9.45111 61.8359 9.45111 53.4573C9.45111 31.6471 24.6299 13.3821 45 8.64972"
//         stroke="url(#paint0_linear_17_5)"
//         strokeWidth="17"
//       />
//       <defs>
//         <linearGradient id="paint0_linear_17_5" x1="20.5" y1="82" x2="54.5787" y2="13.7853" gradientUnits="userSpaceOnUse">
//           <stop stopColor="#FFC9CE" />
//           <stop offset="1" stopColor="#EC2F3E" />
//         </linearGradient>
//       </defs>
//     </svg>
//   );
// };

export const Gauge045 = ({ className }) => {
  return (
    <svg className={className} width="47" height="88" viewBox="30 0 50 88" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.5 82.1571C18.0652 80.3621 16.762 78.4573 15.6051 76.4573C11.6912 69.6913 9.45111 61.8359 9.45111 53.4573C9.45111 31.6471 24.6299 13.3821 45 8.64972"
        stroke="url(#paint0_linear_17_5)"
        stroke-width="17"
      />
      <defs>
        <linearGradient id="paint0_linear_17_5" x1="20.5" y1="82" x2="54.5787" y2="13.7853" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FFC9CE" />
          <stop offset="1" stop-color="#EC2F3E" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const Gauge040 = ({ className }) => {
  return (
    <svg className={className} width="40" height="84" viewBox="34 -4 40 88" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.5 78.1571C18.0652 76.3621 16.762 74.4573 15.6051 72.4573C11.6912 65.6913 9.45111 57.8359 9.45111 49.4573C9.45111 31.2028 20.0842 15.4318 35.4945 8"
        stroke="url(#paint0_linear_17_10)"
        stroke-width="17"
      />
      <defs>
        <linearGradient id="paint0_linear_17_10" x1="22" y1="78" x2="25.4145" y2="7.53552" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FFC9CE" />
          <stop offset="1" stop-color="#EC2F3E" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const Gauge035 = ({ className }) => {
  return (
    <svg className={className} width="33" height="78" viewBox="38 -10 33 88" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.5 72.157C18.0652 70.3621 16.762 68.4573 15.6051 66.4573C11.6912 59.6913 9.45111 51.8359 9.45111 43.4573C9.45111 28.9039 16.2095 15.929 26.7591 7.5"
        stroke="url(#paint0_linear_17_11)"
        stroke-width="17"
      />
      <defs>
        <linearGradient id="paint0_linear_17_11" x1="18" y1="72" x2="66.3491" y2="37.1285" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FFC9CE" />
          <stop offset="1" stop-color="#EC2F3E" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const Gauge030 = ({ className }) => {
  return (
    <svg className={className} width="27" height="69" viewBox="42 -21 27 90" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.5 63.157C18.0652 61.3621 16.762 59.4573 15.6051 57.4573C11.6912 50.6913 9.45111 42.8359 9.45111 34.4573C9.45111 23.602 13.2112 13.625 19.5 5.75751"
        stroke="url(#paint0_linear_17_15)"
        stroke-width="17"
      />
      <defs>
        <linearGradient id="paint0_linear_17_15" x1="20" y1="63" x2="18.3641" y2="-15.7284" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FFC9CE" />
          <stop offset="1" stop-color="#EC2F3E" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const Gauge025 = ({ className }) => {
  return (
    <svg className={className} width="27" height="59" viewBox="42 -31 27 90" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.5 53.157C18.0652 51.3621 16.762 49.4573 15.6051 47.4573C11.6912 40.6913 9.45111 32.8359 9.45111 24.4573C9.45111 17.3064 11.0828 10.5367 13.9941 4.5"
        stroke="url(#paint0_linear_17_17)"
        stroke-width="17"
      />
      <defs>
        <linearGradient id="paint0_linear_17_17" x1="106.029" y1="53.2947" x2="105.723" y2="-49.6763" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FFC9CE" />
          <stop offset="1" stop-color="#EC2F3E" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const Gauge020 = ({ className }) => {
  return (
    <svg className={className} width="27" height="47" viewBox="42 -43 27 90" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.5 41.157C18.0652 39.3621 16.762 37.4573 15.6051 35.4573C11.6912 28.6913 9.45111 20.8359 9.45111 12.4573C9.45111 9.03824 9.82413 5.70632 10.5317 2.5"
        stroke="url(#paint0_linear_17_19)"
        stroke-width="17"
      />
      <defs>
        <linearGradient id="paint0_linear_17_19" x1="20" y1="41" x2="9" y2="3" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FFC9CE" />
          <stop offset="1" stop-color="#EC2F3E" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const Gauge015 = ({ className }) => {
  return (
    <svg className={className} width="27" height="35" viewBox="42 -55 27 90" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.45111 0.457275C9.45111 8.83588 11.6912 16.6913 15.6051 23.4573C16.762 25.4573 18.0652 27.3621 19.5 29.157"
        stroke="url(#paint0_linear_17_21)"
        stroke-width="17"
      />
      <defs>
        <linearGradient id="paint0_linear_17_21" x1="20" y1="29" x2="29.761" y2="11.7426" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FFC9CE" />
          <stop offset="1" stop-color="#EC2F3E" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const Gauge010 = ({ className }) => {
  return (
    <svg className={className} width="25" height="27" viewBox="40 -63 27 90" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.5001 21.157C16.0652 19.3621 14.7621 17.4573 13.6051 15.4573C11.3026 11.4768 9.5793 7.11928 8.55066 2.5"
        stroke="url(#paint0_linear_17_23)"
        stroke-width="17"
      />
      <defs>
        <linearGradient id="paint0_linear_17_23" x1="18" y1="21" x2="27.761" y2="3.74264" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FFC9CE" />
          <stop offset="1" stop-color="#EC2F3E" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const Gauge005 = ({ className }) => {
  return (
    <svg className={className} width="21" height="18" viewBox="36 -72 27 90" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.5 12.657C12.0652 10.8621 10.7621 8.95726 9.60513 6.95728C9.14002 6.15325 8.69856 5.33384 8.28168 4.5"
        stroke="url(#paint0_linear_18_25)"
        stroke-width="17"
      />
      <defs>
        <linearGradient id="paint0_linear_18_25" x1="14" y1="12.5" x2="23.761" y2="-4.75736" gradientUnits="userSpaceOnUse">
          <stop stop-color="#EC2F3E" />
          <stop offset="1" stop-color="#FFC9CE" />
        </linearGradient>
      </defs>
    </svg>
  );
};
