import React, { useState, useEffect } from "react";
import Popup from "./Popup";
import "./Signal.css";
import {
  Gauge100,
  Gauge095,
  Gauge090,
  Gauge085,
  Gauge080,
  Gauge075,
  Gauge070,
  Gauge065,
  Gauge060,
  Gauge055,
  Gauge050,
  Gauge045,
  Gauge040,
  Gauge035,
  Gauge030,
  Gauge025,
  Gauge020,
  Gauge015,
  Gauge010,
  Gauge005,
} from "./images/Gauges";
import {
  OuterEllipseSvg,
  InnerEllipseSvg,
  RecommendationIconSvg,
  RecommendationBulletIconSvg,
  ServiceDetailsCloseButtonSvg,
  WorkspaceDivIconSvg,
  WorkspaceWifiStrengthIconSvg,
  WorkspaceNetworkFrequencyIconSvg,
  WorkspaceCurrentNetworkIconSvg,
  WorkspaceVPNIconSvg,
  NetworkDivIconSvg,
  NetworkDownloadIconSvg,
  NetworkUploadIconSvg,
  NetworkLatencyIconSvg,
  NetworkPacketLossIconSvg,
  DeviceDivIconSvg,
  DeviceCPUIconSvg,
  DeviceMemoryIconSvg,
  DeviceProcessorIconSvg,
  DeviceCoresIconSvg,
} from "./images/SignalSVG";

const Signal = ({ signal, activationCode }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [isRecommendationModalOpen, setIsRecommendationModalOpen] = useState(false);
  const [recommendationModalContent, setRecommendationModalContent] = useState({ message: "", link: "" });

  useEffect(() => {
    console.log("Signal: ", signal);
    if (signal && signal.SignalOverallStatus === "Offline") {
      setShowPopup(true);
    } else {
      setShowPopup(false);
    }
  }, [signal]);

  if (!signal) {
    return <div>Loading...</div>;
  }

  const gaugeComponents = {
    100: Gauge100,
    95: Gauge095,
    90: Gauge090,
    85: Gauge085,
    80: Gauge080,
    75: Gauge075,
    70: Gauge070,
    65: Gauge065,
    60: Gauge060,
    55: Gauge055,
    50: Gauge050,
    45: Gauge045,
    40: Gauge040,
    35: Gauge035,
    30: Gauge030,
    25: Gauge025,
    20: Gauge020,
    15: Gauge015,
    10: Gauge010,
    5: Gauge005,
  };

  const roundUpToNearestFive = (num) => Math.ceil(num / 5) * 5;

  const SignalScoreMeter = ({ signalScore }) => {
    const roundedScore = roundUpToNearestFive(signalScore);

    if (roundedScore < 5 && roundedScore > 0) {
      return <h1 className="signal-critical-label">Critical</h1>;
    }

    let GaugeComponent = gaugeComponents[roundedScore];

    if (!GaugeComponent) {
      return (
        <div className="signal-score-meter">
          <OuterEllipseSvg />
          <InnerEllipseSvg />
        </div>
      );
    }

    return (
      <div className="signal-score-meter">
        <OuterEllipseSvg />
        <InnerEllipseSvg />
        <GaugeComponent className="gauge-svg" />
      </div>
    );
  };

  const openRecommendationModal = (content) => {
    setRecommendationModalContent(content);
    setIsRecommendationModalOpen(true);
  };

  const closeRecommendationModal = () => {
    setIsRecommendationModalOpen(false);
  };

  const getRecommendationContent = (recommendation) => {
    switch (recommendation) {
      case "wifi":
        return {
          message: "Your Wi-Fi signal is weak. Click here for steps to resolve this issue.",
          link: "https://www.signaltuner.com/how-to-fix-wifi-signal.html",
        };
      case "network":
        return {
          message: "Your network connection is experiencing issues. Click here for steps to resolve this issue.",
          link: "https://www.signaltuner.com/how-to-fix-download-speed.html",
        };
      case "device":
        return {
          message: "Your device is low on available resources. Click here for steps to resolve this issue.",
          link: "https://www.signaltuner.com/how-to-fix-low-memory-high-CPU.html",
        };
      case "vpn":
        return {
          message:
            "You are connected to a VPN, which can impact your connection performance. Consider disconnecting if the VPN is not necessary for your current activities.",
          link: null,
        };
      default:
        return null;
    }
  };

  const renderRecommendation = (recommendation) => {
    const content = getRecommendationContent(recommendation);
    if (content) {
      return (
        <div className={`signal-recommendation-${recommendation}-container`} onClick={() => openRecommendationModal(content)}>
          <RecommendationBulletIconSvg className={`signal-recommendation-${recommendation}-bullet-svg`} />
          <p className={`signal-recommendation-${recommendation}-value`}>{content.message}</p>
        </div>
      );
    }
    return (
      <div className={`signal-recommendation-1-container`} onClick={() => openRecommendationModal({ message: recommendation, link: null })}>
        <RecommendationBulletIconSvg className={`signal-recommendation-1-bullet-svg`} />
        <p className={`signal-recommendation-1-value`}>{recommendation}</p>
      </div>
    );
  };

  const hasRecommendations = signal.SignalRecommendation1 || signal.SignalRecommendation2 || signal.SignalRecommendation3;

  const getColorByStatus = (status) => {
    switch (status) {
      case "Unknown":
        return "#6b96c1";
      case "Critical":
        return "#EC2F3E";
      case "Poor":
        return "#FC6F20";
      case "Fair":
        return "#F9AE00";
      case "Excellent":
        return "#3BB537";
      default:
        return "inherit";
    }
  };

  const getCpuColor = (cpuValue) => {
    if (cpuValue >= 95) {
      return "#EC2F3E";
    } else if (cpuValue >= 90) {
      return "#FC6F20";
    } else if (cpuValue >= 80) {
      return "#F9AE00";
    } else if (cpuValue > 0) {
      return "#3BB537";
    } else if (cpuValue === 0 || cpuValue === null) {
      return "#6b96c1";
    }
    return "#6b96c1";
  };

  const cpuColor = getCpuColor(signal.SignalCPU);

  const getMemoryColor = (memoryValue) => {
    if (memoryValue >= 98) {
      return "#EC2F3E";
    } else if (memoryValue >= 96) {
      return "#FC6F20";
    } else if (memoryValue >= 93) {
      return "#F9AE00";
    } else if (memoryValue > 0) {
      return "#3BB537";
    } else if (memoryValue === 0 || memoryValue === null) {
      return "#6b96c1";
    }
    return "#6b96c1";
  };

  const memoryColor = getMemoryColor(signal.SignalMemory);

  const getWifiStrengthColor = (wifiStrengthValue) => {
    if (wifiStrengthValue >= 90) {
      return "#3BB537";
    } else if (wifiStrengthValue >= 80) {
      return "#F9AE00";
    } else if (wifiStrengthValue >= 70) {
      return "#FC6F20";
    } else if (wifiStrengthValue > 0) {
      return "#EC2F3E";
    } else if (wifiStrengthValue === 0 || wifiStrengthValue === null) {
      return "#6b96c1";
    }
    return "#6b96c1";
  };

  const wifiStrengthColor = getWifiStrengthColor(signal.SignalWifiStrength);

  const getWifiBandColor = () => {
    if (signal.SignalWifiBand === "5 GHz") {
      if (signal.SignalWifiStrength >= 90) {
        return "#3BB537";
      } else {
        return "#F9AE00";
      }
    } else if (signal.SignalWifiBand === "2.4 GHz") {
      return "#3BB537";
    } else {
      return "#6b96c1";
    }
  };

  const wifiBandColor = getWifiBandColor();

  const getVpnColor = () => {
    switch (signal.SignalVpnDetected) {
      case "Not Connected":
        return "#3BB537";
      case "Connected":
        return "#F9AE00";
      default:
        return "#6b96c1";
    }
  };

  const vpnColor = getVpnColor();

  const getDownloadSpeedColor = (downloadSpeedValue) => {
    if (downloadSpeedValue >= 3) {
      return "#3BB537";
    } else if (downloadSpeedValue >= 1.8) {
      return "#F9AE00";
    } else if (downloadSpeedValue >= 0.8) {
      return "#FC6F20";
    } else if (downloadSpeedValue > 0) {
      return "#EC2F3E";
    } else if (downloadSpeedValue === 0 || downloadSpeedValue === null) {
      return "#6b96c1";
    }
    return "#6b96c1";
  };

  const downloadSpeedColor = getDownloadSpeedColor(signal.SignalDownloadSpeed);

  const getUploadSpeedColor = (uploadSpeedValue) => {
    if (uploadSpeedValue >= 3.8) {
      return "#3BB537";
    } else if (uploadSpeedValue >= 2.6) {
      return "#F9AE00";
    } else if (uploadSpeedValue >= 1.2) {
      return "#FC6F20";
    } else if (uploadSpeedValue > 0) {
      return "#EC2F3E";
    } else if (uploadSpeedValue === 0 || uploadSpeedValue === null) {
      return "#6b96c1";
    }
    return "#6b96c1";
  };

  const uploadSpeedColor = getUploadSpeedColor(signal.SignalUploadSpeed);

  const getLatencyColor = (latencyValue) => {
    if (latencyValue <= 20) {
      return "#3BB537";
    } else if (latencyValue <= 50) {
      return "#F9AE00";
    } else if (latencyValue <= 99) {
      return "#FC6F20";
    } else if (latencyValue > 99) {
      return "#EC2F3E";
    } else if (latencyValue === 0 || latencyValue === null) {
      return "#6b96c1";
    }
    return "#6b96c1";
  };

  const latencyColor = getLatencyColor(signal.SignalPing);

  const getPacketLossColor = (packetLossValue) => {
    if (packetLossValue <= 1.9) {
      return "#3BB537";
    } else if (packetLossValue <= 3.9) {
      return "#F9AE00";
    } else if (packetLossValue <= 5.9) {
      return "#FC6F20";
    } else if (packetLossValue > 6) {
      return "#EC2F3E";
    } else if (packetLossValue === 0 || packetLossValue === null) {
      return "#6b96c1";
    }
    return "#6b96c1";
  };

  const packetLossColor = getPacketLossColor(signal.SignalPacketLoss);

  return (
    <div>
      {isRecommendationModalOpen && (
        <div className="signal-recommendation-modal-overlay">
          <div className="signal-recommendation-modal">
            <div onClick={closeRecommendationModal} className="signal-recommendation-modal-close-button-svg-container" style={{ cursor: "pointer" }}>
              <ServiceDetailsCloseButtonSvg className="signal-recommendation-modal-close-button-svg" />
            </div>
            <h2 className="signal-recommendation-modal-headline">Insights</h2>
            <p className="signal-recommendation-modal-text" style={{ whiteSpace: "pre-line" }}>
              {recommendationModalContent.link ? (
                <a href={recommendationModalContent.link} target="_blank" rel="noopener noreferrer">
                  {recommendationModalContent.message}
                </a>
              ) : (
                recommendationModalContent.message
              )}
            </p>
            <button className="signal-recommendation-modal-close-button" onClick={closeRecommendationModal}>
              Close
            </button>
          </div>
        </div>
      )}
      {showPopup && <Popup activationCode={activationCode}></Popup>}
      <div className="signal">
        <div className="signal-score-div">
          <p className="signal-score-label">Signal Score</p>
          <div className="signal-score-meter">
            <SignalScoreMeter signalScore={signal.SignalScore} />
          </div>
          <div className="signal-score-value-container">
            <span className="score-value" style={{ color: getColorByStatus(signal.SignalOverallStatus) }}>
              {signal.SignalScore}
            </span>
            <span className="score-percentage" style={{ color: getColorByStatus(signal.SignalOverallStatus) }}>
              %
            </span>
          </div>
          <div className="signal-score-overall-status-container">
            <span className="score-value" style={{ color: getColorByStatus(signal.SignalOverallStatus) }}>
              {signal.SignalOverallStatus}
            </span>
          </div>
          <div className="signal-recommendations-container">
            <div className="signal-recommendations-label-container">
              <RecommendationIconSvg className="signal-reccommendation-icon-svg" />
              <p className="signal-recommendations-label">Insights</p>
            </div>
            {hasRecommendations ? (
              <>
                {signal.SignalRecommendation1 && renderRecommendation(signal.SignalRecommendation1)}
                {signal.SignalRecommendation2 && renderRecommendation(signal.SignalRecommendation2)}
                {signal.SignalRecommendation3 && renderRecommendation(signal.SignalRecommendation3)}
              </>
            ) : (
              <div className="signal-recommendation-none-container">
                <p className="signal-recommendation-none-text">No recommendations.</p>
                <p className="signal-recommendation-none-text">All systems optimal.</p>
              </div>
            )}
          </div>
        </div>
        <div className="device-div">
          <DeviceDivIconSvg />
          <p className="device-text">Device</p>
          <p className="device-status-text" style={{ color: getColorByStatus(signal.SignalSystemStatus) }}>
            {signal.SignalSystemStatus}
          </p>
          <div className="device-left-align-div">
            <div className="device-cpu-div">
              <DeviceCPUIconSvg />
              <p className="device-cpu-label">CPU</p>
            </div>
            <p className="device-cpu-value" style={{ color: cpuColor }}>
              {signal.SignalCPU}%
            </p>
            <div className="device-memory-div">
              <DeviceMemoryIconSvg />
              <p className="device-memory-label">Memory</p>
            </div>
            <p className="device-memory-value" style={{ color: memoryColor }}>
              {signal.SignalMemory}%
            </p>
            <div className="device-processor-div">
              <DeviceProcessorIconSvg />
              <p className="device-processor-label">Processor</p>
            </div>
            <p className="device-processor-value" style={{ color: "#3BB537" }} title={signal.SignalProcessor}>
              {signal.SignalProcessor}
            </p>
            <div className="device-cores-div">
              <DeviceCoresIconSvg />
              <p className="device-cores-label">Cores</p>
            </div>
            <p className="device-cores-value" style={{ color: "#3BB537" }}>
              {signal.SignalCores}
            </p>
          </div>
        </div>

        <div className="workspace-div">
          <WorkspaceDivIconSvg />
          <p className="workspace-text">Workspace</p>
          <p className="workspace-status-text" style={{ color: getColorByStatus(signal.SignalBandwidthStatus) }}>
            {signal.SignalBandwidthStatus}
          </p>
          <div className="workspace-left-align-div">
            <div className="workspace-wifi-strength-div">
              <WorkspaceWifiStrengthIconSvg />

              <p className="workspace-wifi-strength-label">Wi-Fi Strength</p>
            </div>
            <p className="workspace-wifi-strength-value" style={{ color: wifiStrengthColor }}>
              {signal.SignalWifiStrength}%
            </p>
            <div className="workspace-current-network-div">
              <WorkspaceCurrentNetworkIconSvg />
              <p className="workspace-current-network-label">Current Network</p>
            </div>
            <p className="workspace-current-network-value" style={{ color: "#3BB537" }}>
              {signal.SignalCurrentNetwork}
            </p>
            <div className="workspace-network-frequency-div">
              <WorkspaceNetworkFrequencyIconSvg />
              <p className="workspace-frequency-label">Frequency</p>
            </div>
            <p className="workspace-frequency-value" style={{ color: wifiBandColor }}>
              {signal.SignalWifiBand}
            </p>
            <div className="workspace-vpn-div">
              <WorkspaceVPNIconSvg />
              <p className="workspace-vpn-label">VPN</p>
            </div>
            <p className="workspace-vpn-value" style={{ color: vpnColor }}>
              {signal.SignalVpnDetected}
            </p>
          </div>
        </div>

        <div className="network-div">
          <NetworkDivIconSvg />
          <p className="network-text">Network</p>
          <p className="network-status-text" style={{ color: getColorByStatus(signal.SignalBandwidthStatus) }}>
            {signal.SignalBandwidthStatus}
          </p>
          <div className="network-left-align-div">
            <div className="network-download-div">
              <NetworkDownloadIconSvg />
              <p className="network-download-label">Download</p>
            </div>
            <p className="network-download-value" style={{ color: downloadSpeedColor }}>
              {signal.SignalDownloadSpeed} Mbps
            </p>
            <div className="network-upload-div">
              <NetworkUploadIconSvg />
              <p className="network-upload-label">Upload</p>
            </div>
            <p className="network-upload-value" style={{ color: uploadSpeedColor }}>
              {signal.SignalUploadSpeed} Mbps
            </p>
            <div className="network-latency-div">
              <NetworkLatencyIconSvg />
              <p className="network-latency-label">Latency</p>
            </div>
            <p className="network-latency-value" style={{ color: latencyColor }}>
              {signal.SignalPing} ms
            </p>
            <div className="network-packet-loss-div">
              <NetworkPacketLossIconSvg />
              <p className="network-packet-loss-label">Packet loss</p>
            </div>
            <p className="network-packet-loss-value" style={{ color: packetLossColor }}>
              {signal.SignalPacketLoss}%
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signal;
