import React from "react";
import { BulletListCheckmarkSvg, BulletListEllipse } from "./images/HomeSVG";

export const HomeSection2Text = ({ className }) => (
  <div className={className}>
    <p>Your team is powered by the devices, networks, and services they use to collaborate.</p>
    <p>When systems go down, work gets disrupted. </p>
    <p>SignalTuner catches connection issues with real-time monitoring of all potential points of failure between your team and value they generate.</p>
  </div>
);

export const HomeSection2BulletList = ({ className }) => (
  <div className={className}>
    <p>
      <BulletListCheckmarkSvg /> Device-to-router signal health
    </p>
    <p>
      <BulletListCheckmarkSvg /> Network health
    </p>
    <p>
      <BulletListCheckmarkSvg /> ISP status and performance
    </p>
    <p>
      <BulletListCheckmarkSvg /> System resource allocation
    </p>
    <p>
      <BulletListCheckmarkSvg /> Overlay network (VPN) connectivity
    </p>
    <p>
      <BulletListCheckmarkSvg /> Service statuses and downtime events
    </p>
  </div>
);

export const HomeSection3Headline = ({ className }) => (
  <div className={className}>
    <p>IT Support for the for the remote office. </p>
  </div>
);

export const HomeSection3Text = ({ className }) => (
  <div className={className}>
    <p><BulletListEllipse />SignalTuner’s desktop application monitors the device and network performance, of all team member endpoints, wherever they are.</p>
    <p><BulletListEllipse />Anonymized telemetry data is processed by to generate connectivity improvement programs specific for the team member.</p>
    <p><BulletListEllipse />Alerts are configured to inform team admins when a team member endpoint has degraded health during critical time frames.</p>
  </div>
);

export const HomeSection4Headline = ({ className }) => (
  <div className={className}>
    <p>Monitor the tools you use.</p>
    <p>Don’t let downtime leave you disconnected.</p>
  </div>
);

export const HomeSection4Text = ({ className }) => (
  <div className={className}>
    <p><BulletListEllipse />Plan around outages and incidents before they impact live events. </p>
    <p><BulletListEllipse />Monitor the services you use to keep ahead of disruptions.</p>
    <p><BulletListEllipse />A detailed incident report identifies impacted features of your monitored services. </p>
  </div>
);

export const HomeSection5Headline = ({ className }) => (
  <div className={className}>
    <p>Email and SMS alerts keep the whole team informed.</p>
    <p></p>
  </div>
);

export const HomeSection5Text = ({ className }) => (
  <div className={className}>
    <p>
      <BulletListEllipse />
      All team members are alerted when SignalTuner detects a performance impacting incident for a monitored service .{" "}
    </p>
    <p>
      <BulletListEllipse />
      Alerts are also sent when a service incident is resoved, so your team can pick up where they left off.{" "}
    </p>
    <p>
      <BulletListEllipse /> Team administrators get notifications of team member endpoint outages or critical performance events.
    </p>
  </div>
);

export const HomeSection6Headline = ({ className }) => (
  <div className={className}>
    <p>Keep your team on mission.</p>
    <p>Monitor the tools they depend on.</p>
  </div>
);

export const HomeSection6Text = ({ className }) => (
  <div className={className}>
    <p>SignalTuner will have you connected better than ever before.</p>
  </div>
);

export const HomeSection6BannerText = ({ className }) => <p className={className}>Start using SignalTuner FREE today!</p>;
