import React, { useState } from "react";
import "./Popup.css"; // Assuming you have a corresponding CSS file

const Popup = ({ activationCode, onClose }) => {
  const [displayText, setDisplayText] = useState(activationCode);

  const openLinkInNewTab = () => {
    window.open("https://signaltuner.com/update/counter.php", "_blank");
  };

  const openAppropriateLink = () => {
    const userAgent = window.navigator.userAgent;
    let link;

    if (userAgent.indexOf("Win") !== -1) {
      link = "https://signaltuner.com/Launch.html";
    } else if (userAgent.indexOf("Mac") !== -1) {
      link = "https://signaltuner.com/Launchmacos.html";
    } else {
      // Default link for other operating systems or in case detection fails
      link = "https://signaltuner.com/Launch.html";
    }

    window.open(link, "_blank");
  };

  const handleActivationCodeClick = () => {
    navigator.clipboard
      .writeText(activationCode)
      .then(() => {
        setDisplayText("Copied");

        setTimeout(() => {
          setDisplayText(activationCode);
        }, 3000);
      })
      .catch((err) => {
        console.error("Failed to copy activation code: ", err);
        // Handle any errors (e.g., clipboard permissions denied)
      });
  };

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <div className="popup-columns">
          <div className="popup-left-column">
            <h2 className="popup-text">SignalTuner could not connect with your desktop application.</h2>
            <p className="popup-text">- Download the SignalTuner desktop installer. </p>
            <button onClick={openLinkInNewTab} className="popup-button">
              Download
            </button>
            <p className="popup-text">- Launch the SignalTuner desktop app, once you have it installed. </p>
            <button onClick={openAppropriateLink} className="popup-button">
              Launch
            </button>
            <p className="popup-text">
              - Activate with this code on the app's 'Welcome' page or in the 'Settings' to synchronize your device data with signaltuner.com
            </p>
            <button onClick={handleActivationCodeClick} className="popup-button" >
              {displayText}
            </button>
          </div>
          <div className="popup-right-column">
            <img className="popup-image" src="https://www.signaltuner.com/images/onboarding-dashboard-desktop-client-image.png" alt="Desktop Client Setup" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popup;
